import accountStore from './AccountStore'
import alertStore from './AlertStore'
import generalStore from './GeneralStore'
import layoutStore from './LayoutStore'
import menuStore from "./MenuStore";
import userStore from './UserStore'
import catalogStore from "./CatalogStore";
import paginatorStore from "./PaginatorStore";
import deliveryStore from "./DeliveryStore";
import basketStore from "./BasketStore";
import paymentStore from "./PaymentStore";

export { accountStore }
export { alertStore }
export { generalStore }
export { layoutStore }
export { menuStore }
export { userStore }

export { catalogStore }
export { paginatorStore }
export { deliveryStore }
export { basketStore }
export { paymentStore }

export function getAccountStore() {
    return accountStore
}

export function getGeneralStore() {
    return generalStore
}

export function getAlertStore() {
    return alertStore
}

export function getLayoutStore() {
    return layoutStore
}

export function getMenuStore() {
    return menuStore
}

export function getUserStore() {
    return userStore
}

export function getCatalogStore() {
    return catalogStore
}

export function getPaginatorStore() {
    return paginatorStore
}

export function getDeliveryStore() {
    return deliveryStore
}

export function getCartStore() {
    return basketStore
}

export function getPaymentStore() {
    return paymentStore
}
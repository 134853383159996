import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";


@inject("basket")
@observer
class BasketItemDioptrPopup extends React.Component {
    render() {
        const { colr, product } = this.props;

        return (
                    <React.Fragment>
                        <div className="dioptr_line">
                            <div className="dioptr_line_name">Диоптрии</div>
                            <div className="dioptr_line_popup">
                                <table className="table_dioptres">
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>Sph (дптр.)</th>
                                            <th>Cyl (дптр.)</th>
                                            <th>Axis, ось (град.)</th>
                                        </tr>
                                        <tr>
                                            <td className="table_dioptres_title"><b>OS</b> (левый)</td>
                                            <td><input type="text" className="input" value={colr.dioptrOs} disabled/></td>

                                            {product.med &&  <td><input type="text" className="input" value={colr.dioptrOsCyl} disabled/></td>}
                                            {!product.med &&  <td><input type="text" className="input" disabled/></td>}

                                            {product.med &&  <td><input type="text" className="input" value={colr.dioptrOsAxis} disabled/></td>}
                                            {!product.med &&  <td><input type="text" className="input" disabled/></td>}
                                        </tr>
                                        <tr>
                                            <td className="table_dioptres_title"><b>OD</b> (правый)</td>
                                            <td><input type="text" className="input" value={colr.dioptrOd}  disabled/></td>

                                            {product.med &&  <td><input type="text" className="input" value={colr.dioptrOdCyl} disabled/></td>}
                                            {!product.med &&  <td><input type="text" className="input" disabled/></td>}

                                            {product.med &&  <td><input type="text" className="input" value={colr.dioptrOdAxis} disabled/></td>}
                                            {!product.med &&  <td><input type="text" className="input" disabled/></td>}
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="table_dioptres">
                                    <tbody>
                                        <tr>
                                            <th>DP (мм)<br/> Межцентровое расстояние</th>
                                        </tr>
                                        <tr>
                                            <td className="table_dioptres_single">
                                                <input type="text" className="input" value={colr.dioptrDp} disabled/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
    }
}

BasketItemDioptrPopup.propTypes = {
    basket: MPropTypes.observableObject,
};

export default BasketItemDioptrPopup;


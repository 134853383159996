import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * If "prefix" is used, then this is controlled component
 * and "value" also should be passed and it should be string.
 */
export default class Input extends Component {
  static get defaultProps() {
    return {
      className: 'input',
      defaultValue: '',
      hasWrapper: true,
      type: 'text',
      placeholder: '',
      disabled: false,
      onBlur: null,
      onChange: null,
      onKeyDown: null,
      onKeyPress: null,
      onKeyUp: null,
      prefix: null,
      cref: null,
      style: {},
      wrapperClassName: 'input-field'
    }
  }

  state = {
    value: this.props.prefix ? `${this.props.prefix}${this.props.value}` : this.props.value
  }

  componentWillReceiveProps(newProps) {
    let prefix = newProps.prefix
    let value = newProps.value

    if (prefix) {
      value = prefix + value
    }
    this.setState({ value: value })
  }

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  /**
   * If props have "prefix", cut "prefix" from value
   */
  onChange = (e) => {
    const { prefix } = this.props
    let value = e.target.value

    if (prefix) {
      if (prefix.length > value.length) {
        value = ''
      } else {
        value = value.replace(prefix, '')
      }
    }

    this.setState({ value: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  onKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  onKeyPress = (e) => {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(e)
    }
  }

  onKeyUp = (e) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e)
    }
  }

  get hasError() {
    const { errors, name } = this.props
    if (errors && errors.hasOwnProperty(name)) {
      return true
    }
    return false
  }

  renderErrors() {
    const { errors, name } = this.props
    if (errors && errors.hasOwnProperty(name)) {
      return (
        <React.Fragment>
          {errors[name].map((message, index) => {
            return (
              <div key={index} className="error_text">
                {message}
              </div>
            )
          })}
        </React.Fragment>
      )
    }
    return null
  }

  render() {
    const {
      className,
      disabled,
      name,
      style,
      type,
      placeholder,
      cref,
      tooltip,
      hasWrapper,
      wrapperClassName } = this.props
    const { value } = this.state

    let inputProps = {
      className,
      disabled,
      name,
      style,
      type,
      placeholder,
      onBlur: this.onBlur,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      onKeyPress: this.onKeyPress,
      onKeyUp: this.onKeyUp
    }

    // if controlled component
    if (this.props.value !== null && this.props.value !== undefined) {
      inputProps.value = value
    } else {
      inputProps.defaultValue = this.props.defaultValue
    }

    if (this.hasError) {
      inputProps.className += ' error'
    }

    if (hasWrapper) {
      return (
        <div className={wrapperClassName}>
          {cref ? <input ref={cref} {...inputProps} /> : <input {...inputProps} />}
          {this.renderErrors()}

          {tooltip &&
            <div className="quest">
              <div className="tooltip">
                {tooltip}
              </div>
            </div>
          }
        </div>
      )
    }

    return (
      <React.Fragment>
        {cref ? <input ref={cref} {...inputProps} /> : <input {...inputProps} />}
        {this.renderErrors()}
      </React.Fragment>
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  cref: PropTypes.object,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  hasWrapper: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  wrapperClassName: PropTypes.string
}
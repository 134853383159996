import { makeObservable, computed, observable, action } from "mobx";
import { GeneralModel, MenuModel, ProductModel } from "models";
import {
  apiCall,
  CATALOG_MENU_URL,
  SHOP_PRODUCTS_URL,
  SHOP_PRODUCTS_MED_URL,
  URL_LIST_TYPE_MED_LINZ,
  URL_LIST_PROPERTIES_MED_LINZ,
  URL_RECOMMENDED_MED_LINZ,
} from "api";
import { constructUrlWithParams } from "services/helpers";
import { getGeneralStore } from "stores";

class CatalogStore {
  constructor() {
    makeObservable(this);
  }

  @observable catalog_menu = null;
  @observable product = null;
  @observable products = null;
  @observable isSortAscending = null;
  @observable pages = null;
  @observable activeColr = null;
  @observable listMedTypeLinz = null;
  @observable listMedPropertiesLinz = null;
  @observable activeMedTypeLinz = null;
  @observable hasActiveMedTypeLinzBlueBlock = true;
  @observable hasActiveMedTypeLinzPhotoChrome = true;
  @observable activatedBlueBlock = true;
  @observable activatedPhotoChrome = true;
  @observable checkedActiveBlueBlock = false;
  @observable checkedActivePhotoChrome = false;
  @observable recommendedMedTypeLinz = 1;
  @observable selectedMedTypeLinz = 1;
  @observable selectedParamsMedGlasses = {
    blue_block: false,
    select_type_linz_id: this._cookies?.select_type_linz_id || 1,
    recomend_type_linz_id: this._cookies?.recomend_type_linz_id || 1,
    category: null,
    dioptr_os: this._cookies?.dioptr_os || "0.00",
    dioptr_od: this._cookies?.dioptr_od || "0.00",
    dioptr_dp: this._cookies?.dioptr_dp || "64",
    dioptr_os_cyl: this._cookies?.dioptr_os_cyl || "0.00",
    dioptr_os_axis: this._cookies?.dioptr_os_axis || "0.00",
    dioptr_od_cyl: this._cookies?.dioptr_od_cyl || "0.00",
    dioptr_od_axis: this._cookies?.dioptr_od_axis || "0.00",
    med: true,
    photochrome: false,
  };


  set_cookies(key, value, options) {
    options = {
      path: "/",
      secure: true,
      samesite: "None",
      // при необходимости добавьте другие значения по умолчанию
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = key + "=" + value;

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  @computed
  get maxDiopInEye(){
    const dioptrOs = Number(this.selectedParamsMedGlasses.dioptr_os)
    const dioptrOd = Number(this.selectedParamsMedGlasses.dioptr_od)
    let result;

    if (dioptrOs<0 && dioptrOd<0){
      result = (dioptrOs < dioptrOd)?dioptrOs : dioptrOd;
    } else{
      result = (dioptrOs > dioptrOd)?dioptrOs : dioptrOd;
    }
    if (dioptrOs === 0){
      result = dioptrOd;
    }
    if (dioptrOd === 0){
      result = dioptrOs;
    }
    return result
  }

  @computed
  get _cookies() {
    const cookies_set = document.cookie.split(";");
    let result = {};
    for (let item of cookies_set) {
      const tmp_str = item.split("=");
      if (tmp_str && tmp_str.length === 2) {
        result[tmp_str[0].trim()] = tmp_str[1].trim();
      }
    }
    return result;
  }

  @action
  getRecommendedMedTypeLinz = async (value) => {
    const url = URL_RECOMMENDED_MED_LINZ + "?diopInEye=" + value;
    let result = await apiCall(url, "GET", {}, false);
    if (!result.isError) {
      const data = new GeneralModel(result.data);
      if (this.listMedTypeLinz) {
        const filterlist = this.listMedTypeLinz.filter((obj) => obj.id === result.data.id);
        if (filterlist.length > 0) {
          this.recommendedMedTypeLinz = filterlist[0];
          this.selectedMedTypeLinz = filterlist[0];
        } else {
          this.recommendedMedTypeLinz = this.listMedTypeLinz[0];
          this.selectedMedTypeLinz = this.listMedTypeLinz[0];
        }
      }
      this.setSelectedParamsMedGlasses({
        "recomend_type_linz_id": this.recommendedMedTypeLinz.id,
        "select_type_linz_id": this.recommendedMedTypeLinz.id
      });
      console.log(data)
      this.hasActiveMedTypeLinzBlueBlock = data.hasBlueBlock;
      this.hasActiveMedTypeLinzPhotoChrome = data.hasPhotoChrome;
      // this.setActivePhotoChrome(data.hasPhotoChrome);
      // this.setActiveBlueBlock(data.hasBlueBlock);
      this.set_cookies("recomend_type_linz_id", this.recommendedMedTypeLinz.id);
      this.set_cookies("select_type_linz_id", this.recommendedMedTypeLinz.id);
      this.pages = null;
      this.products = null;
    }
  };

  @action
  loadMedPropertiesLinz = async () => {
    if (this.listMedPropertiesLinz) {
      return;
    }

    let result = await apiCall(URL_LIST_PROPERTIES_MED_LINZ, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.listMedPropertiesLinz = arr;
    }
  };

  @action
  loadMedTypeLinz = async () => {
    if (this.listMedTypeLinz) {
      return;
    }

    let result = await apiCall(URL_LIST_TYPE_MED_LINZ, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.listMedTypeLinz = arr;
      this.recommendedMedTypeLinz = arr[0];
      this.selectedMedTypeLinz = arr[0];
      let _arr = arr.filter((obj) => obj.id == this._cookies?.recomend_type_linz_id);
      if(_arr.length > 0){
        this.setSelectedParamsMedGlasses({
          "recomend_type_linz_id": this.recommendedMedTypeLinz.id,
          "select_type_linz_id": this.recommendedMedTypeLinz.id,
        });
        this.hasActiveMedTypeLinzBlueBlock = _arr[0].blueBlock;
        this.hasActiveMedTypeLinzPhotoChrome = _arr[0].photohrom;
        this.activatedBlueBlock = _arr[0].blueBlock;
        this.activatedPhotoChrome = _arr[0].photohrom;
      }

    }
  };

  @action
  loadCatalogMenu = async (url, dioptr = false) => {
    const arr_path = url.split("/");
    if (arr_path.length > 2 && arr_path[1] == "catalogue_med") {
      url += "dioptr/";
    }
    url = CATALOG_MENU_URL + url.slice(0, -1);
    let result = await apiCall(url, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data) {
        arr.push(new MenuModel(obj));
      }
      this.catalog_menu = arr;
      const general = getGeneralStore();
      general.setActiveDioptCatalog(dioptr);
    }
  };

  @action
  loadMedProducts = async () => {
    const general = getGeneralStore();
    if (!general.page_object) {
      return;
    }
    this.setSelectedParamsMedGlasses({ category: general.page_object.id });
    await this.loadProducts(
      this.selectedParamsMedGlasses,
      SHOP_PRODUCTS_MED_URL
    );
  };

  @action
  loadProducts = async (params = null, url = SHOP_PRODUCTS_URL) => {
    this.products = null;
    this.pages = null;
    if (params && params.category) {
      url = constructUrlWithParams(url, params);
    }
    let isSortAscending = this.isSortAscending;
    // если isSortAscending имеет значение(меняется пользователем)
    if (isSortAscending !== null) {
      if (isSortAscending) {
        url += "&ordering=priced";
      } else if (isSortAscending === false) {
        url += "&ordering=-priced";
      }
    }
    console.log(url)
    let result = await apiCall(url, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new ProductModel(obj));
      }
      this.pages = result.data.pages;
      this.products = arr;
    }
  };

  // сортировка продукции по-возрастанию
  @action
  sortingProducts = async (isSortAscending) => {
    this.isSortAscending = isSortAscending;
    const generalStore = getGeneralStore();
    let params = { category: generalStore.page_object?.id };

    await this.loadProducts(params, SHOP_PRODUCTS_URL);
  };

  @action
  loadProduct = async (url) => {
    const arr_url = url.split("/");
    let id = "";
    if (arr_url.length > 0) {
      id = arr_url[2];
    }
    let url_page = SHOP_PRODUCTS_URL + "/" + id;
    if (arr_url.length > 3 && arr_url[3] === "med") {
      url_page = constructUrlWithParams(url_page, this._cookies);
    }
    let result = await apiCall(url_page, "GET", {}, false);

    if (!result.isError) {
      this.product = result.data;
      if (this.product.colorsdic) {
        this.activeColr =
          this.product.colorsdic.length > 0 ? this.product.colorsdic[0] : null;
      } else {
        this.activeColr = null;
      }
      const general = getGeneralStore();
      general.setTitlePage(
        "Купить " +
          this.product.name +
          " " +
          this.product.artikul +
          " у производителя в магазине alis96.ru"
      );
    }
  };

  @action
  filterProduct(url) {
    let arr = this.products.filter((obj) => obj.artikul == url);
    if (arr.length > 0) {
      this.product = arr[0];
      this.activeColr =
        this.product.colorsdic.length > 0 ? this.product.colorsdic[0] : null;
    }
  }

  @action
  setActiveColr(value) {
    this.activeColr = value;
  }

  @action
  setSelectedMedTypeLinz(value) {
    let _arr = this?.listMedTypeLinz.filter((obj) => obj.id == value);
    if (_arr.length > 0){
      this.selectedMedTypeLinz = _arr[0];
      console.log(this.selectedMedTypeLinz)
      this.setSelectedParamsMedGlasses({ "select_type_linz_id": value });
      this.hasActiveMedTypeLinzBlueBlock = _arr[0].blueBlock;
      this.hasActiveMedTypeLinzPhotoChrome = _arr[0].photohrom;
      this.setActivePhotoChrome(_arr[0].photohrom);
      this.setActiveBlueBlock(_arr[0].blueBlock);
      this.setSelectedParamsMedGlasses({ blue_block: _arr[0].blueBlock, photochrome: _arr[0].photohrom});
      this.pages = null;
      this.products = null;

    }
    // this.loadMedProducts();
  }

  clearBlueBlockAndPhotochrome(){
    this.setSelectedParamsMedGlasses({ blue_block: false, photochrome: false});
    this.selectedParamsMedGlasses.blue_block = false
    this.selectedParamsMedGlasses.photochrome = false
  }

  clearAstigmatism(){
    this.setSelectedParamsMedGlasses({ dioptr_od_axis: 0, dioptr_os_axis: 0, dioptr_od_cyl: 0, dioptr_os_cyl: 0});
    this.selectedParamsMedGlasses.dioptr_od_axis = 0
    this.selectedParamsMedGlasses.dioptr_os_axis = 0
    this.selectedParamsMedGlasses.dioptr_od_cyl = "0.00"
    this.selectedParamsMedGlasses.dioptr_os_cyl = "0.00"
  }


  @action
  setCheckedActiveBlueBlock(value) {
    this.checkedActiveBlueBlock = value;
    if (value === true) {
      this.checkedActivePhotoChrome = false
    }
    this.setSelectedParamsMedGlasses({ blue_block: value, photochrome: false});
    this.activatedPhotoChrome = !value
    this.pages = null;
    this.products = null;
  }


  @action
  setCheckedActivePhotoChrome(value) {
    this.checkedActivePhotoChrome = value;
    if (value === true) {
      this.checkedActiveBlueBlock = false
    }
    this.activatedBlueBlock = !value
    this.setSelectedParamsMedGlasses({ photochrome: value, blue_block: false});
    this.pages = null;
    this.products = null;
  }


  @action
  setActiveBlueBlock(value) {
    this.activatedBlueBlock = value;
    this.pages = null;
    this.products = null;
  }

  @action
  setActivePhotoChrome(value) {
    this.activatedPhotoChrome = value;
    this.pages = null;
    this.products = null;
  }

  @action
  setSelectedParamsMedGlasses(value) {
    this.selectedParamsMedGlasses = {
      ...this.selectedParamsMedGlasses,
      ...value,
    };
    const keys = Object.keys(value);
    for (let key of keys) {
      this.set_cookies(key, value[key]);
    }
  }
  @action
  clearSelectedParamsMedGlasses() {
    this.selectedParamsMedGlasses = null;
  }
}

const catalogStore = new CatalogStore();

export default catalogStore;

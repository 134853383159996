import Model from './private/Model'

export default class RulesModel extends Model{
    get title() {
        return this.name
    }

    get content() {
        return this.column1 + this.column2
    }
}
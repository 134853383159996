import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

import { headerCatalogPage } from "services/helpers";
import { RightFilterBox, ProductsPreview, Breadcrumb } from "components";
import {URL_CATALOG_COMP} from "../../api";

@inject("general", "storeCatalog", "routing")
@observer
class CatalogPage extends Component {
  componentDidMount() {
    this.getItem();
  }

  componentDidUpdate(prevProps) {
    if (this.props.routing.location.pathname !== prevProps.location.pathname) {
      this.getItem();
    }
  }

  getItem() {
    const { general, storeCatalog } = this.props;
    const url = (this.props.routing.location.pathname === "/") ? URL_CATALOG_COMP : this.props.routing.location.pathname;
    storeCatalog.loadCatalogMenu(url, this.props.dioptr);
    general.loadContentItem(url, this.props.dioptr, this.props.med);
  }

  sortingProducts = (isSortAscending) => {
    const { storeCatalog } = this.props;
    if (
      (!storeCatalog.isSortAscending && !isSortAscending) ||
      (storeCatalog.isSortAscending && isSortAscending)
    ) {
      storeCatalog.sortingProducts(null);
    } else storeCatalog.sortingProducts(isSortAscending);
  };

  render() {
    const { general, storeCatalog } = this.props;
    const banerHeader = headerCatalogPage(this.props.routing.location.pathname);

    return (
      <div className="container">
        {banerHeader}
        <div className="breadcrumbs">
          <ol className="breadcrumbs_nav">
            <Breadcrumb breadcrumbs={general.page_object?.krohi} />
          </ol>
          <div className="sorting tb-hidden">
            <span className="sorting_title">Сортировка по цене:</span>{" "}
            <a
              className={storeCatalog.isSortAscending === false ? "active" : ""}
              href="#"
              onClick={this.sortingProducts.bind(null, false)}
            >
              по убыванию
            </a>{" "}
            {" | "}
            <a
              className={storeCatalog.isSortAscending ? "active" : ""}
              href="#"
              onClick={this.sortingProducts.bind(null, true)}
            >
              по возрастанию
            </a>
          </div>
        </div>
        <div className="row">
          <div className="grid-20 md3 tb12">
            <RightFilterBox />
          </div>
          {storeCatalog.products && <ProductsPreview med={this.props.med} dioptr={this.props.dioptr}/>}
        </div>
      </div>
    );
  }
}
CatalogPage.propTypes = {
  general: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject,
};

export default CatalogPage;

import React, { useEffect, useRef } from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import TopMenuItem from "./TopMenuItem";

const TopMenu = inject('storeMenu')(observer(({ storeMenu, isOpen, setOpen }) => {
  let menuRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current?.contains(event.target)) {
        setOpen(false)
      }
    });
  });
  return (
    <nav className={`navmenu ${isOpen ? "l-300" : ''}`}>
      <ul key='top_menu' className="navmenu_nav">
        {storeMenu.menuTop && storeMenu.menuTop.map((obj) => {
          return <TopMenuItem key={`ti${obj.id}`} item={obj} index={obj.id} />
        })}
      </ul>
      <div style={{ height: "100%" }}
        ref={menuRef}
      ></div>
    </nav>
  )
}
));

TopMenu.propTypes = {
  storeMenu: MPropTypes.observableObject,
}

export default TopMenu;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactDOM } from "react-dom";

import { TopMenu, BasketPreview } from "components";

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <TopMenuPart />
        <BasketPreview />
      </div>
    </header>
  );
};

export default Header;

const TopMenuPart = () => {
  const [isOpen, setOpen] = useState(false);

  const onClickMenu = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <>
      <a href="#" className="bars" onClick={(e) => onClickMenu(e)}></a>
      <TopMenu isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

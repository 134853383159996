import React from 'react'

import {
    ZAGLUSHKA_TABLET_SVG,
    ZAGLUSHKA_LAPTOP_SVG,
    ZAGLUSHKA_GAMEPAD_SVG, URL_ABOUT_COMP
} from "api";
import {Link} from "react-router-dom";
import { BaseHeadBanners } from "components"
import {inject} from "mobx-react";

@inject('general')
class HeadBannersKomp extends BaseHeadBanners {

    render() {
        const {general} = this.props;
        const style_bigbanner = { backgroundImage: 'url(/images/uploads/banner72.jpg)'}
        const url_banner = '/images/' + general.headBannersGames.href
        return (
            <React.Fragment>
                <div className="bigbanner" style={style_bigbanner}>
                    <div className="row">
                        <div className="grid-20 md3 sm12">
                            <Link to={URL_ABOUT_COMP}><img src={url_banner} alt=""/></Link>
                        </div>
                        <div className="grid-80 md9 sm12">
                            <div className="bigbanner_title">КОМПЬЮТЕРНЫЕ<br/>ОЧКИ SPG<br/>ДЛЯ ГЕЙМЕРОВ</div>
                            <p>Применение</p>
                            <p><img src={ZAGLUSHKA_TABLET_SVG} alt=""/>
                                <img src={ZAGLUSHKA_LAPTOP_SVG} alt=""/>
                                <img src={ZAGLUSHKA_GAMEPAD_SVG} alt=""/></p>
                            <Link to={URL_ABOUT_COMP} className="btn btn-grey">{this.text_button}</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}


export default HeadBannersKomp
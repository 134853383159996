import React from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<Routes />, document.getElementById('root'))

reportWebVitals();
registerServiceWorker()

import React, { useState } from 'react'
import { rHTML } from 'services/helpers'

const Accordion = ({ id, selectId, title, content, toggle }) => {
  return (
    <div className="spoilerbox">
      <div className="spoilerbox_name"
        onClick={() => toggle(id)}
      >
        <a href="#"
          className={`${id === selectId && 'active'}`}
        >{title}</a>
      </div>
      <div className="spoilerbox_content"
        style={{ display: id === selectId ? 'block' : 'none', transition: '' }}
      >
        {rHTML(content, 'textcol-2')}
      </div>
    </div >
  )
}

export default Accordion

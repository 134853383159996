import React, { Component } from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Link } from "react-router-dom";

import {
  URL_CATALOG_COMP,
  URL_CATALOG_DRIVER,
  URL_CATALOG_ACTIVE,
  URL_CATALOG_REHABIL,
  URL_CATALOG_DIOPTR, URL_CATALOG_MED

} from 'api'
import { SpgBannerItem } from 'components'
import { rHTML } from "services/helpers";

@inject('general')
@observer
class PrSpgPage extends Component {

  render() {
    const { general } = this.props
    general.setTitlePage("Очки SPG (федоровские очки) и их общие свойства")
    let array_datas_page = []
    array_datas_page.push({
      link: URL_CATALOG_COMP,
      urlSpgBannerIcon: "/images/uploads/comp.png",
      styleBackground: { backgroundImage: `url(/images/uploads/banner27.jpg)` },
      title: general.getLettering('194'),
      description: general.getLettering('198'),
      featuresTitle: general.getLettering('206'),
      featuresDescription: general.getLettering('210'),
      featuresUrlIcon: '/images/icons/white_UV400.svg',
    })
    array_datas_page.push({
      link: URL_CATALOG_DRIVER,
      urlSpgBannerIcon: "/images/uploads/drive.png",
      styleBackground: { backgroundImage: `url(/images/uploads/banner26.jpg)` },
      title: general.getLettering('195'),
      description: general.getLettering('199'),
      featuresTitle: general.getLettering('207'),
      featuresDescription: general.getLettering('211'),
      featuresUrlIcon: '/images/icons/white_med.svg',
    })
    array_datas_page.push({
      link: URL_CATALOG_ACTIVE,
      urlSpgBannerIcon: "/images/uploads/active.png",
      styleBackground: { backgroundImage: `url(/images/uploads/banner25.jpg)` },
      title: general.getLettering('196'),
      description: general.getLettering('200'),
      featuresTitle: general.getLettering('208'),
      featuresDescription: general.getLettering('212'),
      featuresUrlIcon: '/images/icons/white_lens.svg',
    })
    array_datas_page.push({
      link: URL_CATALOG_REHABIL,
      urlSpgBannerIcon: "/images/uploads/rehab.png",
      styleBackground: { backgroundImage: `url(/images/uploads/banner24.jpg)` },
      title: general.getLettering('197'),
      description: general.getLettering('201'),
      featuresTitle: general.getLettering('209'),
      featuresDescription: general.getLettering('213'),
      featuresUrlIcon: '/images/icons/white_contrast.svg',
    })

    const style_background5 = { backgroundImage: `url(/images/uploads/banner43.jpg)` }

    return (
      <div className="container">

        <div className="row spg_banner_row">
          {array_datas_page.map((obj, index) => {
            return (<SpgBannerItem key={index} obj={obj} />)
          })}
        </div>

        <div className="banner_home large" style={style_background5}>
          <span className="banner_home_plus"><b>+/-</b></span>
          <span className="banner_home_title">
            <span className="banner_home_bold">{rHTML(general.getLettering('202'))}</span>
            <span className="banner_home_small">{rHTML(general.getLettering('203'))}</span>
            <Link to={URL_CATALOG_MED} className="btn btn-grey">{general.getLettering('204')}</Link>
          </span>
        </div>

        <div className="features_box">
          <h2>{general.getLettering('205')}</h2>
          <div className="row">
            {array_datas_page.map((obj, index) => {
              return (
                <div className="grid-3 tb6 sm12" key={index}>
                  <div className="features_box_img"><img src={obj.featuresUrlIcon} alt="" />
                  </div>
                  <h3>{obj.featuresTitle}</h3>
                  {rHTML(obj.featuresDescription)}
                </div>
              )
            })}
          </div>
          <div className="check_text">{general.getLettering('214')}</div>
          {rHTML(general.getLettering('215'))}
        </div>

      </div>

    )
  }
}
PrSpgPage.propTypes = {
  general: MPropTypes.observableObject,
}

export default PrSpgPage

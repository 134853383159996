import React from 'react'
import {Link} from "react-router-dom";
import { Input } from "../../components";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import {
    ERR_EMAIL_NOT_VALID,
    ERR_EMPTY_EMAIL,
    ERR_EMPTY_FIRST_NAME,
    ERR_EMPTY_MESSAGE,
    ERR_EMPTY_TITLE,
    STAR
} from "../../api/constants";

let scrollAnimateToTopContacts = () => {
    window.$([document.documentElement, document.body]).animate(
        {
            scrollTop: window.$("#feedback_form").offset().top - 52,
        },
        1000
    );
    window.scrollTo(0, 0);
};

@inject('general')
@observer
class FeedbackPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            firstName: null,
            title: null,
            email: null,
            message: null,
            fileFieldName: null,
            errorMsgFirstName: STAR,
            errorMsgTitle: STAR,
            errorMsgEmail: STAR,
            errorMsgMessage: STAR,
            isErrFirstName: null,
            isErrTitle: null,
            isErrEmail: null,
            isErrMessage: null,
            emailIsValid: null,
        }
    }

    componentDidMount() {
        const { general } = this.props
        const $ = window.$
        general.loadCompanyDownloadCatalog();
        general.loadCompanyDownloadInstructions();
        general.setTitlePage('Служба поддержки частных клиентов Алис-96');

        $("input.number, .selectbox").styler();
        $("input.file").styler({
            multiple: true,
            filePlaceholder: "Файл не выбран",
            fileBrowse: "Прикрепить файл",
            fileNumber: "Выбрано файлов: %s",
            singleSelectzIndex: 999,
        }).trigger("refresh");
    }


    setErrorMsg() {
        const {
            firstName,
            title,
            email,
            message
        } = this.state;

        const tmp_emailIsValid = Boolean(email !== null && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
        const errorMsgFirstName =
            Boolean(firstName === null || firstName?.length === 0) ? ERR_EMPTY_FIRST_NAME : STAR;
        const errorMsgTitle = Boolean(title === null || title?.length === 0) ? ERR_EMPTY_TITLE : STAR;
        const errorMsgMessage = Boolean(message === null || message?.length === 0) ? ERR_EMPTY_MESSAGE : STAR;
        const errorMsgEmail =
            Boolean(email === null || email?.length === 0)
                ? ERR_EMPTY_EMAIL
                : tmp_emailIsValid
                ? STAR
                : ERR_EMAIL_NOT_VALID;

        this.setState({
            isErrFirstName: errorMsgFirstName !== STAR,
            isErrTitle: errorMsgTitle !== STAR,
            isErrEmail: errorMsgEmail !== STAR,
            emailIsValid: tmp_emailIsValid,
            isErrMessage: errorMsgMessage !== STAR,
            errorMsgFirstName: errorMsgFirstName,
            errorMsgTitle: errorMsgTitle,
            errorMsgEmail: errorMsgEmail,
            errorMsgMessage: errorMsgMessage
        });
    }

    onChangeFirstName = (value) => {
        const errorMsgFirstName =
            Boolean(value.length === 0) ? ERR_EMPTY_FIRST_NAME : STAR;
        this.setState({
            firstName: value,
            isErrFirstName: Boolean(value.length === 0),
            errorMsgFirstName: errorMsgFirstName
        });
    };

    onChangeEmail = (value) => {
        const emailIsValid = Boolean(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
        const errorMsgEmail =
            Boolean(value === null || value?.length === 0)
                ? ERR_EMPTY_EMAIL
                : emailIsValid
                ? STAR
                : ERR_EMAIL_NOT_VALID;
        this.setState({
            email: value,
            isErrEmail: Boolean(value.length === 0),
            emailIsValid: emailIsValid,
            errorMsgEmail: errorMsgEmail,
        });
    };

    onChangeTitle = (value) => {
        const errorMsgTitle = Boolean(value.length === 0) ? ERR_EMPTY_TITLE : STAR;
        this.setState({
            title: value,
            isErrTitle: Boolean(value.length === 0),
            errorMsgTitle: errorMsgTitle,
        });
    };

    onChangeMessage = (value) => {
        value = value.target.value
        const errorMsgMessage = Boolean(value.length === 0) ? ERR_EMPTY_MESSAGE : STAR;
        this.setState({
            message: value,
            isErrMessage: Boolean(value.length === 0),
            errorMsgMessage: errorMsgMessage
        });
    }
    onChangeFile = (value) => {
        this.setState({
            fileFieldName: value
        })
    }

    onClickSubmit = (e) => {
        const {
            isErrFirstName,
            isErrTitle,
            isErrEmail,
            isErrMessage,
            emailIsValid,
        } = this.state;

        const errorContacts =
            (isErrFirstName === null || isErrFirstName)||
            (isErrTitle === null || isErrTitle) ||
            (isErrEmail === null || isErrEmail) ||
            (emailIsValid === null || isErrMessage) ||
            !emailIsValid;

        e.preventDefault();
        this.setErrorMsg();
        if (errorContacts) {
            scrollAnimateToTopContacts();
            return false;
        }

        const files = window.$("input.file").styler()
        this.props.general.sendFeedbackMsg({
            name: this.state.firstName,
            title: this.state.title,
            message: this.state.message,
            email: this.state.email,
            file: files[0].files.length > 0 ? files[0].files[0]: null,
            fileFieldName: this.state.fileFieldName
        })
        return false
    };

    render(){
        return (
            <div className="container">

                <div className="breadcrumbs">
                    <ol className="breadcrumbs_nav">
                    <li className="breadcrumb-item">
                        <Link to="/">Главная</Link>
                    </li>
                        <li className="breadcrumb-item">
                        <a href="#">Служба поддержки</a>
                        </li>
                    </ol>
                </div>


                <h1>Служба поддержки +7 495 663 9605</h1>

                <div className="article">
                    <div className="feedback_form" id="feedback_form">
                        <form action="#" method="POST" encType="multipart/form-data">
                            <div className="input-field">
                                <label>
                                    Тема сообщения
                                    <span className="red"> {this.state.errorMsgTitle}</span>
                                </label>
                                <Input
                                    type="text"
                                    className={
                                        this.state.isErrTitle ? "input error" : "input"
                                    }
                                    name="title"
                                    onChange={this.onChangeTitle}
                                />
                            </div>
                            <div className="input-field">
                                <label>Сообщение
                                    <span className="red"> {this.state.errorMsgMessage}</span>
                                </label>
                                <textarea
                                    className={
                                        this.state.isErrMessage ? "input error" : "input"
                                    }
                                    name="message"
                                    onChange={this.onChangeMessage}
                                >
                                </textarea>
                            </div>
                            <div className="input-field">
                                <label>Ваше имя
                                    <span className="red"> {this.state.errorMsgFirstName}</span>
                                </label>

                                <Input
                                    type="text"
                                    className={
                                        this.state.isErrFirstName ? "input error" : "input"
                                    }
                                    name="name"
                                    onChange={this.onChangeFirstName}
                                />
                            </div>
                            <div className="input-field">
                                <label>E-mail
                                    <span className="red"> {this.state.errorMsgEmail}</span>
                                </label>
                                <Input
                                    type="email"
                                    className={
                                        this.state.isErrEmail || (this.state.emailIsValid !== null && !this.state.emailIsValid) ? "input error" : "input"
                                    }
                                    name="email"
                                    onChange={this.onChangeEmail}
                                />
                            </div>
                            <div className="input-field">
                                <Input id="uploadFile" type="file" className="file" multiple name="file" onChange={this.onChangeFile}/>
                            </div>
                            <Link
                                to={""}
                                className="btn btn-red upper"
                                onClick={this.onClickSubmit.bind(this)}
                            >
                                Отправить сообщение
                            </Link>
                        </form>
                    </div>

                    <p>Мы обрабатываем запросы в течение одного рабочего дня, однако в некоторых случаях может
                        потребоваться больше времени.</p>
                    <p>Нажимая на кнопку «ОТПРАВИТЬ СООБЩЕНИЕ», вы даете согласие на обработку своих персональных
                        данных.</p>
                    <p className="light"><span className="red">*</span> — поля, обязательные для заполнения</p>
                </div>

            </div>
        )
    }
}

FeedbackPage.propTypes = {
    general: MPropTypes.observableObject,
}

export default FeedbackPage

import { makeObservable, computed, observable, action } from "mobx";
import {
  GeneralModel,
  BoxberryModel,
  CdekCostModel,
  PostCostModel,
  BoxBerryCostModel,
  PostModel,
  CDEKModel,
  YouPositionModel,
} from "../models";
import {
  apiCall,
  URL_ADDRESS_LOCATION,
  URL_CDEK_DP_GEO,
  URL_BOXBERRY_DP_GEO,
  URL_POST_DP_GEO,
  URL_CDEK_DP_COST,
  URL_CDEK_POST_COST,
  URL_CDEK_BOXBERRY_COST,
  SHOP_PAYMENT_METHODS_URL,
} from "api";
import { getGeneralStore } from "./index";

class DeliveryStore {
  PREPAYMENT = 0;
  PAYMENT_UPON_RECEIPT = 1;

  CDEK = 0; // самовывоз CDEK
  CDEK_PRIM = 1; // самовывоз CDEK с примеркой (оплата при получении)
  CDEK_COURIER = 2; // CDEK доставка
  BOXBERRY = 3;
  BOXBERRY_COURIER = 4;
  POST = 5; // почта самовывоз
  POST_COURIER = 6; // почта доставка
  POST_FREE = 7; // бспл доставка (предоплата)
  DPD = 8;
  DPD_COURIER = 9;

  strMethods = {
    0: "Самовывоз CDEK",
    1: "Самовывоз CDEK с примеркой (до 3-х очков, действует до Урала)",
    2: "Курьерская доставка CDEK",
    3: "Самовывоз Boxberry",
    4: "Курьерская доставка Boxberry",
    5: "Посылка 1 класса Почта России",
    6: "Курьерская доставка Почта России",
    7: "Бесплатная доставка Почта России",
    8: "Мелкий пакет Russian Post (avia)",
  };

  codesInfoBlocksOrder = {
    0: ["258", "259"],
    1: ["304", "305"],
    2: ["300", "303"],
    3: ["306", "307"],
    4: ["308", "309"],
    5: ["058", "059"],
    6: ["312", "313"],
    7: ["312", "315"],
  };

  @observable addressLocation = null;
  @observable dadataAddress = undefined;
  @observable deliveryPointsCDEK = null;
  @observable deliveryPointsBoxberry = null;
  @observable deliveryPointsDPD = null;
  @observable deliveryPostOffices = null;

  @observable selectedDeliveryPoints = [];
  @observable activeDeliveryPoint = null;
  @observable activeDeliveryPointCost = null;
  @observable activeDeliveryMethod = null;
  @observable activePaymentMethod = null;
  @observable valueChoicePaymentMethod = this.PREPAYMENT;

  @observable deliveryPointCDEKCost = null;
  @observable deliveryPointPostCost = null;
  @observable deliveryPointBoxberryCost = null;
  @observable deliveryPointDPDCost = null;

  @observable activeLatitude = null;
  @observable activeLongitude = null;
  @observable activeZoom = 12;
  @observable activeCountryDelivery = null;

  @observable recipientFirstName = null;
  @observable recipientLastName = null;
  @observable recipientEmail = null;
  @observable recipientPhone = null;
  @observable recipientOrganizationName = null;
  @observable recipientAddressDelivery = null;
  @observable recipientTimeDelivery = null;
  @observable recipientComment = null;

  @observable tmpDeliveryPoints = null;
  @observable paymentMethods = null;
  @observable orderIsCreated = false

  renderMap = true;

  constructor() {
    makeObservable(this);
    this.addYouPositionToTmpDeliveryPoints();
  }

  getStrMethod(deliveryMethod) {
    if (deliveryMethod == this.POST_FREE) {
      const general = getGeneralStore();
      const freeShippingRestriction = general.getCompanySetting("120");
      return (
        "Бесплатная доставка Почта России (при сумме заказа " +
        freeShippingRestriction.value +
        " руб. и выше)"
      );
    }
    return this.strMethods[deliveryMethod];
  }

  getStrInfoBlocks(deliveryMethod) {
    if (deliveryMethod === null) {
      return;
    }
    const general = getGeneralStore();
    const isRussia = !(
      !this.activeCountryDelivery?.withDelivery &&
      this.activeCountryDelivery?.methodDelivery
    );
    const codeDeliveryMethod = isRussia ? deliveryMethod : 5; // если не Россия, то вывожу 59 код, ибо с сервера не приходит данные именно для иностранцев
    const codes = this.codesInfoBlocksOrder[codeDeliveryMethod];

    const rightCol = general.getLettering(codes[0]);
    const leftCol = general.getLettering(codes[1]);
    return {
      rightCol: rightCol,
      leftCol: leftCol,
    };
  }

  deliveryType() {
    switch (this.activeDeliveryMethod) {
      case this.CDEK:
      case this.CDEK_PRIM:
      case this.BOXBERRY:
      case this.POST: {
        return "carry";
        break;
      }
      case this.CDEK_COURIER:
      case this.POST_COURIER:
      case this.POST_FREE:
      case this.BOXBERRY_COURIER: {
        return "delivery";
        break;
      }
    }
  }

  addYouPositionToTmpDeliveryPoints() {
    let youPosition = new YouPositionModel({
      address: this.addressLocation
        ? this.addressLocation.source
        : "М.О., г. Королёв, ул. Полевая, 43/12. Вход с торца здания с ул. Урицкого\n" +
          "(отдельное крыльцо). Малиновая вывеска в окне: SPG / Интернет магазин оптики / Шоу-рум",
      latitude: this.addressLocation
        ? this.addressLocation.geoLat
        : 55.922081623973426,
      longitude: this.addressLocation
        ? this.addressLocation.geoLon
        : 37.87186717169545,
    });

    if (this.tmpDeliveryPoints === null) {
      this.tmpDeliveryPoints = [];
    }
    this.tmpDeliveryPoints.push(youPosition);
  }

  @action
  clear() {
    this.addressLocation = null;

    this.deliveryPointsCDEK = null;
    this.deliveryPointsBoxberry = null;
    this.deliveryPostOffices = null;
    this.deliveryPointsDPD = null;

    this.selectedDeliveryPoints = [];

    this.deliveryPointCDEKCost = null;
    this.deliveryPointPostCost = null;
    this.deliveryPointBoxberryCost = null;
    this.deliveryPointDPDCost = null;
    this.tmpDeliveryPoints = null;
  }

  @action
  setOrderIsCreated(value=false) {
    this.orderIsCreated = value
  }

  @action
  setActiveCountryDeliveryID(value) {
    const general = getGeneralStore();
    const tmp = general.countries.filter((obj) => obj.id == value);
    if (tmp.length > 0) {
      this.activeCountryDelivery = tmp[0];
    } else {
      this.activeCountryDelivery = null;
    }
  }

  @action
  setActiveCountryDelivery(value) {
    this.activeCountryDelivery = value;
  }

  @action
  setRecipientFirstName(value) {
    this.recipientFirstName = value;
  }

  @action
  setRecipientLastName(value) {
    this.recipientLastName = value;
  }

  @action
  setRecipientEmail(value) {
    this.recipientEmail = value;
  }

  @action
  setRecipientPhone(value) {
    this.recipientPhone = value;
  }

  @action
  setRecipientOrganizationName(value) {
    this.recipientOrganizationName = value;
  }

  @action
  setRecipientAddressDelivery(value) {
    this.recipientAddressDelivery = value;
  }

  @action
  setRecipientTimeDelivery(value) {
    this.recipientTimeDelivery = value;
  }

  @action
  setRecipientComment(value) {
    this.recipientComment = value;
  }

  @action
  setDadataAddress(value) {
    this.dadataAddress = value;
  }

  @action
  setCoordinates(geoLat, geoLon) {
    this.activeLatitude = geoLat;
    this.activeLongitude = geoLon;
  }

  @action
  setActiveDeliveryPoint(value, setCoordinate = true, isRussia = true) {
    if (isRussia) {
      this.activeDeliveryPoint = value;
      if (setCoordinate && value) {
        this.setCoordinates(value.latitude, value.longitude);
      } else {
        this.setCoordinates(
          this.addressLocation
            ? this.addressLocation.geoLat
            : 55.922081623973426,
          this.addressLocation ? this.addressLocation.geoLon : 37.87186717169545
        );
      }
    } else {
      this.activeDeliveryPoint.address = value;

    }
    this.setActiveZoom(12);
  }

  @action
  setActiveDeliveryMethod(value) {
    this.activeDeliveryMethod = value;
    this.activeDeliveryPoint = null;
    this.setCoordinates();
    this.setActiveZoom(12);
    this.setCoordinates(
      this.addressLocation ? this.addressLocation.geoLat : 55.922081623973426,
      this.addressLocation ? this.addressLocation.geoLon : 37.87186717169545
    );
    this.filteringDeliveryPoints(value);
  }

  @action
  filteringDeliveryPoints = async (delivery) => {
    switch (delivery) {
      case this.CDEK:{
        this.addressLocation && this.addressLocation.postalCode &&
          (await this.getCdekDeliveryPointsToAddress(
            this.addressLocation.postalCode
          ));
        break;
      }
      case this.CDEK_PRIM: {
        this.addressLocation && this.addressLocation.postalCode &&
        (await this.getCdekDeliveryPointsToAddress(
            this.addressLocation.postalCode,
            true
        ));
        break;
      }

      case this.BOXBERRY: {
        this.addressLocation && (this.addressLocation.city || this.addressLocation.region) &&
          (await this.getBoxberryDeliveryPointsToAddress(
              this.addressLocation.city || this.addressLocation.region
          ));
        break;
      }
      case this.POST:
      case this.POST_FREE: {
        this.addressLocation.geoLat &&
          this.addressLocation.geoLon &&
          (await this.getPostOfficesDeliveryPointsToAddress(
            this.addressLocation.geoLat,
            this.addressLocation.geoLon
          ));
        break;
      }
      case this.CDEK_COURIER:
      case this.POST_COURIER:
      case this.BOXBERRY_COURIER: {
        this.tmpDeliveryPoints = null;
        break;
      }
    }

    this.addYouPositionToTmpDeliveryPoints();
  };

  @action
  setActiveDeliveryPointCost(value) {
    this.activeDeliveryPointCost = value;
  }

  @action
  setActivePaymentMethod(value) {
    this.activePaymentMethod = value;
  }

  @action
  setValueChoicePaymentMethod(value) {
    this.valueChoicePaymentMethod = value;
  }

  @action
  setActiveZoom(val) {
    this.activeZoom = val;
  }


  @action
  getPostOfficesDeliveryPointsToAddress = async (
    lan,
    lon,
    addToTmpDeliveryPoints = null
  ) => {
    if (this.deliveryPostOffices) {
      this.tmpDeliveryPoints = this.deliveryPostOffices;
      return;
    }

    let result = await apiCall(
      URL_POST_DP_GEO + "?lan=" + lan + "&lon=" + lon,
      "GET",
      {},
      false
    );
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data) {
        arr.push(new PostModel(obj));
      }
      this.deliveryPostOffices = arr;
      if (addToTmpDeliveryPoints && this.tmpDeliveryPoints) {
        this.tmpDeliveryPoints = this.tmpDeliveryPoints.concat(arr);
      } else {
        this.tmpDeliveryPoints = arr;
      }
    }
  };

  @action
  getBoxberryDeliveryPointsToAddress = async (
    city,
    addToTmpDeliveryPoints = null
  ) => {
    console.log(city)
    if (this.deliveryPointsBoxberry) {
      this.tmpDeliveryPoints = this.deliveryPointsBoxberry;
      return;
    }

    let result = await apiCall(
      URL_BOXBERRY_DP_GEO + "?city=" + city,
      "GET",
      {},
      false
    );
    if (!result.isError) {
      let arr = [];

      for (let obj of result.data) {
        arr.push(new BoxberryModel(obj));
      }
      this.deliveryPointsBoxberry = arr;

      if (addToTmpDeliveryPoints && this.tmpDeliveryPoints) {
        this.tmpDeliveryPoints = this.tmpDeliveryPoints.concat(arr);
      } else {
        this.tmpDeliveryPoints = arr;
      }
    }
  };

  @action
  getCdekDeliveryPointsToAddress = async (
    postCode,
    hasDressingRoom = null,
    addToTmpDeliveryPoints = null,
  ) => {
    if (this.deliveryPointsCDEK) {
      this.tmpDeliveryPoints = this.deliveryPointsCDEK;
      if (hasDressingRoom) {
        this.tmpDeliveryPoints = this.tmpDeliveryPoints.filter(
            (obj) => obj.isDressingRoom === hasDressingRoom
        );
      }
      return;
    }

    let result = await apiCall(
      URL_CDEK_DP_GEO + "?postCode=" + postCode,
      "GET",
      {},
      false
    );
    if (!result.isError) {
      try {
        let arr = [];

        for (let obj of result.data) {
          arr.push(new CDEKModel(obj));
        }
        this.deliveryPointsCDEK = arr;
        if (addToTmpDeliveryPoints && this.tmpDeliveryPoints) {
          this.tmpDeliveryPoints = this.tmpDeliveryPoints.concat(arr);
        } else {
          this.tmpDeliveryPoints = arr;
        }

        if (hasDressingRoom) {
          this.tmpDeliveryPoints = this.tmpDeliveryPoints.filter(
            (obj) => obj.isDressingRoom === hasDressingRoom
          );
        }
      } catch (e) {}
    }
  };

  @action
  getLocationAddress = async (
    address,
    full = false,
    withDeliveryPoints = false,
    isPaymentDelivery = false
  ) => {
    //this.clear()
    let result = await apiCall(
      URL_ADDRESS_LOCATION,
      "POST",
      { address: address },
      false
    );
    if (!result.isError) {
      this.clear();
      this.addressLocation = new GeneralModel(result.data);

      if (
        !isPaymentDelivery &&
        full === true &&
        this.addressLocation.house === null
      ) {
        return;
      }

      this.addressLocation.postalCode &&
        (await this.getCostsDeliveryCdek(this.addressLocation.postalCode));
      this.addressLocation.postalCode &&
        (await this.getCostsDeliveryPost(this.addressLocation.postalCode));
      this.addressLocation.postalCode &&
        (await this.getCostsDeliveryBoxBerry(this.addressLocation.postalCode));
      this.setCoordinates(
        this.addressLocation.geoLat,
        this.addressLocation.geoLon
      );
      this.recipientAddressDelivery = this.addressLocation.result;

      if (withDeliveryPoints) {
        this.addressLocation.postalCode &&
          (await this.getCdekDeliveryPointsToAddress(
            this.addressLocation.postalCode,
            null,
            true
          ));
        console.log(this.addressLocation)
        this.addressLocation.city &&
          (await this.getBoxberryDeliveryPointsToAddress(
            this.addressLocation.city,
            true
          ));
        this.addressLocation.geoLat &&
          this.addressLocation.geoLon &&
          (await this.getPostOfficesDeliveryPointsToAddress(
            this.addressLocation.geoLat,
            this.addressLocation.geoLon,
            true
          ));
      }

      this.addYouPositionToTmpDeliveryPoints();
    }
  };

  @action
  getCostsDeliveryCdek = async (postCode) => {
    if (this.deliveryPointCDEKCost) {
      return;
    }

    let result = await apiCall(
      URL_CDEK_DP_COST + "?postCode=" + postCode + "&tariffCode=136",
      "GET",
      {},
      false
    );
    if (!result.isError) {
      this.deliveryPointCDEKCost = { pickup: new CdekCostModel(result.data) };
    }
    let c_result = await apiCall(
      URL_CDEK_DP_COST + "?postCode=" + postCode + "&tariffCode=137",
      "GET",
      {},
      false
    );
    if (!c_result.isError) {
      if (!this.deliveryPointCDEKCost) {
        this.deliveryPointCDEKCost = {
          courier: new CdekCostModel(c_result.data),
        };
      } else {
        this.deliveryPointCDEKCost.courier = new CdekCostModel(c_result.data);
      }
    }
  };

  @action
  getCostsDeliveryPost = async (postCode) => {
    if (this.deliveryPointPostCost) {
      return;
    }

    let result = await apiCall(
      URL_CDEK_POST_COST + "?postCode=" + postCode,
      "GET",
      {},
      false
    );
    if (!result.isError) {
      this.deliveryPointPostCost = { pickup: new PostCostModel(result.data) };
    }
    let c_result = await apiCall(
      URL_CDEK_POST_COST + "?postCode=" + postCode + "&courier=true",
      "GET",
      {},
      false
    );
    if (!c_result.isError) {
      if (!this.deliveryPointPostCost) {
        this.deliveryPointPostCost = {
          courier: new PostCostModel(c_result.data),
        };
      } else {
        this.deliveryPointPostCost.courier = new PostCostModel(c_result.data);
      }
    }
  };

  @action
  getCostsDeliveryBoxBerry = async (postCode) => {
    if (this.deliveryPointBoxberryCost) {
      return;
    }

    let result = await apiCall(
      URL_CDEK_BOXBERRY_COST + "?postCode=" + postCode,
      "GET",
      {},
      false
    );
    if (!result.isError) {
      this.deliveryPointBoxberryCost = {
        pickup: new BoxBerryCostModel(result.data),
      };
    }
  };

  @action
  loadPaymentMethods = async () => {
    if (this.paymentMethods) {
      return;
    }
    let result = await apiCall(SHOP_PAYMENT_METHODS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data) {
        arr.push(new GeneralModel(obj));
      }
      this.paymentMethods = arr;
    }
  };
}

const deliveryStore = new DeliveryStore();

export default deliveryStore;

import React from "react";

import {
  ZAGLUSHKA_BANNER_20_PIC,
  ZAGLUSHKA_SUN_SVG,
  ZAGLUSHKA_UV400_SVG,
  URL_ABOUT_DRIVER_SUN,
  URL_CATALOG_DRIVER_SUN,
  URL_ABOUT_DRIVER_NIGHT,
  URL_CATALOG_DRIVER_NIGHT,
} from "api";
import { BaseHeadBanners } from "components";
import { Link } from "react-router-dom";

class HeadBannersDriversSun extends BaseHeadBanners {
  constructor(props) {
    super(props);
    this.state = {
      linkToSunDriverPage: this.props.buy
        ? URL_ABOUT_DRIVER_SUN
        : URL_CATALOG_DRIVER_SUN,
      linkToNightDriverPage: this.props.buy
        ? URL_ABOUT_DRIVER_NIGHT
        : URL_CATALOG_DRIVER_NIGHT,
    };
  }
  render() {
    const style_bigbanner = {
      backgroundImage: "url(/images/uploads/banner49.jpg)",
    };
    const url_to = this.props.buy
      ? URL_CATALOG_DRIVER_NIGHT
      : URL_ABOUT_DRIVER_NIGHT;

    return (
      <div className="bigbanner" style={style_bigbanner}>
        <div className="row">
          <div className="grid-20 md3 sm12">
            <Link to={URL_CATALOG_DRIVER_NIGHT}>
              <img src={ZAGLUSHKA_BANNER_20_PIC} alt="" />
            </Link>
          </div>
          <div className="grid-80 md9 sm12">
            <div className="bigbanner_title">ВОДИТЕЛЬСКИЕ ОЧКИ SPG</div>
            <ul className="bigbanner_tabs">
              <li>
                <Link to={this.state.linkToSunDriverPage}>ЯСНАЯ ПОГОДА</Link>
              </li>
              <li className="active">
                <Link to={this.state.linkToNightDriverPage}>
                  НЕПОГОДА | НОЧЬ
                </Link>
              </li>
            </ul>
            <p>Применение</p>
            <p>
              <img src="/images/svg/icon4.svg" alt="" />
              <img src="/images/svg/icon3.svg" alt="" />
              <img src="/images/svg/icon2.svg" alt="" />
            </p>
            <Link to={url_to} className="btn btn-grey">
              {this.text_button}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HeadBannersDriversSun;

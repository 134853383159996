import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { instanceOf } from "prop-types";

@inject("basket")
@observer
class BasketItemPreview extends React.Component {

  constructor(props) {
        super(props);
        this.state = {
            countBasketItem: props.qnt,
            isChoiseEquipment: this.props.colr.ktype === 2, //
        };
  }

  onDeleteItemInCart = (idProduct, key, e) => {
    e.preventDefault();
    this.props.basket.deleteItemInCart(idProduct, key);
  };
  renderColrs() {
    const style1 = { backgroundColor: this.props.colr.css1 };
    const style2 = { backgroundColor: this.props.colr.css2 };
    return (
      <div className="colorbox">
        <span className="colorbox_col2" style={style1}></span>
        <span className="colorbox_col2" style={style2}></span>
      </div>
    );
  }
  render() {
    const { product } = this.props;
    const price_equipment_full = (this.state.isChoiseEquipment)
        ? Number(product?.colr?.kfull?.Price): 0
    const sumAtProduct = (+product.discountedPrice + price_equipment_full);
    return (
      <div className={`cart_widjet_row ${this.props.product.catclass}`}>
        <div className="cart_widjet_img">
          <img
            src={
              "/media/prodpics/" +
              this.props.colr.photos[0][0] +
              "_m." +
              this.props.colr.photos[0][1]
            }
            alt=""
          />
        </div>
        <div className="cart_widjet_title">
          <Link to={this.props.product.url}>
            Артикул: <b>{this.props.product.artikul}</b>
            <br /> Категория: {this.props.product.catdisplay}
          </Link>
        </div>
        <div className="cart_widjet_meta">
          <div className="cart_widjet_count">{this.props.qnt}</div>
          {this.renderColrs()}
          <div className="cart_widjet_price">{sumAtProduct * this.props.qnt} р.</div>
          <button
            className="delete_icon"
            onClick={this.onDeleteItemInCart.bind(
              this,
              this.props.product.id,
              this.props.colr.key
            )}
          />
        </div>
      </div>
    );
  }
}

BasketItemPreview.propTypes = {
  basket: MPropTypes.observableObject,
};

export default BasketItemPreview;

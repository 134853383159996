import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import {rpHTML} from "../../services/helpers";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

@inject('general', 'routing')
@observer
class NewsPage extends Component {
    componentDidMount() {
        const {general, routing} = this.props
        general.loadPageContent(routing.location.pathname);
        this.props.general.loadNews()
    }

    clickPageLink = (link, e) => {
        e.preventDefault()
        this.props.general.loadNews(link)
    }

    render() {
    const { general } = this.props
    return (
        <div className="container">

          <div className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Новости</a>
              </li>
            </ol>
          </div>

          <h1>Новости</h1>
          {general.news && general.news.map((item, index) => {
              return(
                  <div className="newsbox" key={index}>
                    <div className="newsbox_date">{item.showdate}</div>
                    <div className="newsbox_content">
                      <div className="newsbox_img"><img src={"/media"+item.showpic} alt="news" /></div>
                      <div className="newsbox_entry">
                        <div className="newsbox_title">{item.title}</div>
                        {rpHTML(item.body)}
                      </div>
                    </div>
                  </div>
              )
            })
          }

            <div className="pagination">
                {general.news_pages && general.news_pages.map((obj, index) => {
                    if (!obj.current)
                        return (<Link to={obj.link} key={index} onClick={this.clickPageLink.bind(this, obj.link)}>{obj.key}</Link>)
                    else
                        return (<span className="pagination_current" key={index}>{obj.key}</span>)
                })}
            </div>

        </div>
    )
    }
}

NewsPage.propTypes = {
    general: MPropTypes.observableObject,
    routing: MPropTypes.observableObject,
}

export default NewsPage

import Model from './Model'
import { getGeneralStore } from "stores";


export default class CostModel extends Model {

  strDaysInField(field) {
    let result = ''
    if (field > 1 && field < 5) {
      result = ' дня'
    } else if (field == 1) {
      result = ' день'
    } else if (field >= 5) {
      result = ' дней'
    }
    return result
  }

  getCost(field, add_allowance = false) {
    const cost = this.getNumberCost(field, add_allowance)
    return cost ? cost + ' руб.' : ''

  }

  getNumberCost(field, add_allowance = false) {
    const general = getGeneralStore()
    const _filed = this[field]
    let allowance = 0
    if (add_allowance) {
      allowance = general.getCompanySetting("130").value
    }
    return (_filed) ? Math.ceil(Number(_filed) + Number(allowance)): 0
  }

  getPeriod(field) {
    return (field) ? field + ' ' + this.strDaysInField(field) : ''
  }

}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

import moment from "moment";
import "moment/locale/ru";
import { Loading } from "../../index";
import Alerts from "../layout/Alerts";

@inject("routing", "storeMenu", "general", "basket")
@observer
class Base extends Component {
  componentDidMount() {
    const { storeMenu, general, basket } = this.props;
    storeMenu.loadTopMenu();
    storeMenu.loadFooterMenu();
    storeMenu.loadFooter2Menu();
    general.loadCompanySettings();
    general.loadPdfFiles();
    general.loadBanners();
    general.loadCompanyEvents();
    general.loadLetterings();
    basket.load();
    general.getMasterIsWorked()
    general.loadNews()
  }

  render() {
    const { general, storeMenu } = this.props;
    if (!general.letterings || !general.companyEvents || !storeMenu.menuTop) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        {general.enableLoading && <Loading />}
        <Alerts />
        {this.props.children}
      </React.Fragment>
    );
  }
}

Base.propTypes = {
  basket: PropTypes.node,
  children: PropTypes.node,
  location: PropTypes.object,
  storeMenu: MPropTypes.observableObject,
  modal: MPropTypes.observableObject,
  general: MPropTypes.observableObject,
};

export default Base;

import React, {Component} from "react";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import {rHTML} from "../../../../services/helpers";


@inject('general', 'storeCatalog')
@observer
class ChoiceOptionsMedLinz extends Component {
    onChangeBlueBlock = (e)=>{
        this.props.storeCatalog.setCheckedActiveBlueBlock(e.target.checked)
        const $ = window.$
        if(e.target.checked){
            $('.choose_section_photo').slideUp(200);
        }

    }

    onChangePhotoChrome = (e)=>{
        this.props.storeCatalog.setCheckedActivePhotoChrome(e.target.checked)
        const $ = window.$
        if(e.target.checked){
            $('.choose_section_photo').slideDown(200);
        } else{
            $('.choose_section_photo').slideUp(200);
        }
    }

    render(){
        const { storeCatalog } = this.props
        const page = this.props.general.page_object
        const opacityStyleBlueBlock = (storeCatalog.activatedBlueBlock)?{opacity: 1}:{opacity: 0.4}
        const opacityStylePhotoChrome= (storeCatalog.activatedPhotoChrome)?{opacity: 1}:{opacity: 0.4}
        return(
            <React.Fragment>
                <div className="choose_section_row">
                    {storeCatalog.hasActiveMedTypeLinzBlueBlock &&
                        <div className="choose_section_box" style={opacityStyleBlueBlock}>
                            <label className="choose_section">
                                <input
                                    type="checkbox"
                                    className="choose_section_radio"
                                    name="choose_dioptres"
                                    defaultValue="blue_block"
                                    onChange={this.onChangeBlueBlock}
                                    checked={storeCatalog.checkedActiveBlueBlock }
                                    disabled={!storeCatalog.activatedBlueBlock}
                                />
                                <span className="choose_section_entry">
                                <span className="choose_section_title">Линзы Blue Block <b>+{page && page.bonusBlueBlock} р.</b></span>
                                    <img src="/images/blue_block.svg" alt=""/>
                                </span>
                                <span className="choose_section_text">{rHTML(this.props.general.getLettering("311"))}</span>
                            </label>
                        </div>
                    }
                    {storeCatalog.hasActiveMedTypeLinzPhotoChrome &&
                        <div className="choose_section_box" style={opacityStylePhotoChrome}>
                        <label className="choose_section">
                            <input
                                type="checkbox"
                                className="choose_section_radio"
                                name="choose_dioptres"
                                defaultValue="photochrome"
                                onChange={this.onChangePhotoChrome}
                                checked={storeCatalog.checkedActivePhotoChrome}
                                disabled={!storeCatalog.activatedPhotoChrome}
                            />
                            <span className="choose_section_entry">
                                <span className="choose_section_title">Фотохромные линзы <b>+{page && page.bonusPhotohrom} р.</b></span>
                                    <img src="/images/photochrome.png" alt=""/>
                                </span>
                            <span className="choose_section_text">{rHTML(this.props.general.getLettering("310"))}</span>
                        </label>
                    </div>
                    }

                </div>

                {storeCatalog.hasActiveMedTypeLinzPhotoChrome && <div className="choose_section_photo">
                    <div className="choose_section_box">
                        <label className="choose_section active">
                            <input type="radio" className="choose_section_radio"
                                   name="choose_dioptres_photo"
                                   defaultChecked={true}
                            />
                            <span className="choose_section_entry">
                                <img src="/images/svg/glass-brown.svg" alt=""/>
                                <span className="choose_section_title">Коричневая</span>
                            </span>
                        </label>
                    </div>

                    <div className="choose_section_box">
                        <label className="choose_section">
                            <input type="radio" className="choose_section_radio"
                                   name="choose_dioptres_photo"/>
                            <span className="choose_section_entry">
                                <img src="/images/svg/glass-grey.svg" alt=""/>
                                <span className="choose_section_title">Серая</span>
                            </span>
                        </label>
                    </div>
                </div>}
            </React.Fragment>
        )
    }
}

ChoiceOptionsMedLinz.propTypes = {
    general: MPropTypes.observableObject,
    storeCatalog: MPropTypes.observableObject,

}

export default ChoiceOptionsMedLinz
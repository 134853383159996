import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Loading } from 'components'

/**
 * For regular site urls.
 * We should know is user auth or not.
 */
@inject('user')
@observer
class AuthCheck extends Component {
    componentDidMount() {
        this.props.user.checkToken()
    }

    render() {
        const { user } = this.props

        // Wait checking token
        if (user.isAuthenticated === null) {
            return <Loading />
        }

        // --- Temporary - remove as soon as content is ready
        if (!user.isAuthenticated){
           return <div className="center-me"><h1>{'Сайт в разработке'}</h1></div>
        }
        // ---

        return { ...this.props.children }
    }
}

AuthCheck.propTypes = {
    children: PropTypes.node,
    user: MPropTypes.observableObject
}

export default AuthCheck
import React from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import { Link } from "react-router-dom";
import { Input } from "../../components";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const STAR = '*'
const ERR_EMPTY_FIRST_NAME = 'Не указано имя получателя';
const ERR_EMPTY_LAST_NAME = 'Не указано фамилия получателя';
const ERR_EMPTY_EMAIL = 'Не указан email получателя';
const ERR_EMAIL_NOT_VALID = 'Не верно заполнено email получателя';
const ERR_EMPTY_PHONE = 'Не указан телефон получателя';
const ERR_EMPTY_NAME_ORGANIZATION = 'Не указано наименование организации или ИП';

let scrollAnimateToTopContacts = () => {
  window.$([document.documentElement, document.body]).animate(
    {
      scrollTop: window.$('#contactsWrapper').offset().top - 52
    },
    1000
  )
  window.scrollTo(0, 0)
};


let scrollAnimateToTopPaymentMethod = () => {
  window.$([document.documentElement, document.body]).animate(
    {
      scrollTop: window.$('#payentMethodsWrapper').offset().top - 52
    },
    1000
  )
  window.scrollTo(0, 0)
};


@inject('storeDelivery', 'basket', "general")
@observer
class WholeSalePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emptyFirstName: null,
      emptyLastName: null,
      emptyEmail: null,
      emptyPhone: null,
      emptyOrganizationName: null,
      emailIsValid: null,
      errorMsgFirstName: STAR,
      errorMsgLastName: STAR,
      errorMsgEmail: STAR,
      errorMsgPhone: STAR,
      errorMsgOrganizationName: STAR,
      //errorMsgPayment: STAR,
    }
  }
  componentDidMount() {
    this.props.general.setTitlePage("Оформление оптового заказа");
  }

  checkedErrorInContacts() {
    const { storeDelivery } = this.props
    return {
      emptyFirstName: storeDelivery.recipientFirstName ? storeDelivery.recipientFirstName.length === 0 : true,
      emptyLastName: storeDelivery.recipientLastName ? storeDelivery.recipientLastName.length === 0 : true,
      emptyEmail: storeDelivery.recipientEmail ? storeDelivery.recipientEmail.length === 0 : true,
      emptyPhone: storeDelivery.recipientPhone ? storeDelivery.recipientPhone.length < 11 : true,
      emailIsValid: storeDelivery.recipientEmail ? Boolean(storeDelivery.recipientEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) : false,
      emptyOrganizationName: storeDelivery.recipientOrganizationName ? storeDelivery.recipientOrganizationName.length === 0 : true,
    }
  }

  setErrorMsg() {
    const { emptyFirstName, emptyLastName, emptyEmail, emailIsValid, emptyPhone, emptyOrganizationName } = this.checkedErrorInContacts()
    const errorMsgFirstName = emptyFirstName == true ? ERR_EMPTY_FIRST_NAME : STAR;
    const errorMsgLastName = emptyLastName == true ? ERR_EMPTY_LAST_NAME : STAR;
    const errorMsgEmail = emptyEmail === true ? ERR_EMPTY_EMAIL : emailIsValid === false ? ERR_EMAIL_NOT_VALID : STAR;
    const errorMsgPhone = emptyPhone === true ? ERR_EMPTY_PHONE : STAR;
    const errorMsgOrganizationName = emptyOrganizationName === true ? ERR_EMPTY_NAME_ORGANIZATION : STAR;

    this.setState({
      emptyFirstName: emptyFirstName,
      emptyLastName: emptyLastName,
      emptyEmail: emptyEmail,
      emptyPhone: emptyPhone,
      emptyOrganizationName: emptyOrganizationName,
      emailIsValid: emailIsValid,
      errorMsgFirstName: errorMsgFirstName,
      errorMsgLastName: errorMsgLastName,
      errorMsgEmail: errorMsgEmail,
      errorMsgPhone: errorMsgPhone,
      errorMsgOrganizationName: errorMsgOrganizationName,
    })
  }

  onChangeFirstName = (value) => {
    this.setState({ emptyFirstName: value.length === 0 })
    this.props.storeDelivery.setRecipientFirstName(value)
    this.setErrorMsg()
  }

  onChangeLastName = (value) => {
    this.setState({ emptyLastName: value.length === 0 })
    this.props.storeDelivery.setRecipientLastName(value)
    this.setErrorMsg()

  }

  onChangeEmail = (value) => {
    this.props.storeDelivery.setRecipientEmail(value)
    this.setState({
      emptyEmail: value.length === 0,
      emailIsValid: Boolean(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    }
    )
    this.setErrorMsg()

  }

  onChangePhone = (value) => {
    this.props.storeDelivery.setRecipientPhone(value)
    this.setState({ emptyPhone: value.length < 11 })
    this.setErrorMsg()

  }
  onChangeOrganizationName = (value) => {
    this.props.storeDelivery.setRecipientOrganizationName(value)

    this.setState({ emptyOrganizationName: value === 0 })
    this.setErrorMsg()

  }

  onChangeComment = (e) => {
    this.props.storeDelivery.setRecipientComment(e.target.value)
  }

  onClickSubmit = (e) => {
    const { basket } = this.props
    const { emptyFirstName, emptyLastName, emptyEmail, emailIsValid, emptyPhone, emptyOrganizationName } = this.checkedErrorInContacts()
    const errorContacts = emptyFirstName || emptyLastName || emptyEmail || !emailIsValid || emptyPhone || emptyOrganizationName;

    this.setErrorMsg()
    if (errorContacts) {
      e.preventDefault();
      scrollAnimateToTopContacts()
      return false;
    }
    basket.createWholeSaleOrder()
  }

  render() {
    const { storeDelivery, basket } = this.props
    return (
      <div className="container">
        <div className="breadcrumbs">
          <ol className="breadcrumbs_nav">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Оформление оптового заказа</a>
            </li>
          </ol>
        </div>

        <div className="page_header">
          <h1>Оформление оптового заказа</h1>
          <Link to="/cart" className="btn btn-grey upper">Вернуться в корзину</Link>
        </div>

        <div className="forms_section">
          <div className="forms_section_entry">
            <h3 className="uppercase">КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>
            <div className="feedback_form margin0">

              <div className="input-field">
                <label>Наименование организации, ИП <span className="red">{this.state.errorMsgOrganizationName}</span></label>
                <Input
                    name="recipientOrganizationName"
                    type="text"
                    className={this.state.emptyOrganizationName ? "input error" : "input"}
                    value={storeDelivery.recipientOrganizationName}
                    onChange={this.onChangeOrganizationName}
                />
              </div>

              <div className="input-field">
                <label>Имя <span className="red">{this.state.errorMsgFirstName}</span></label>
                <Input
                    name="recipientFirstName"
                    type="text"
                    className={this.state.emptyFirstName ? "input error" : "input"}
                    value={storeDelivery.recipientFirstName}
                    onChange={this.onChangeFirstName}
                />
              </div>

              <div className="input-field">
                <label>Фамилия<span className="red">{this.state.errorMsgLastName}</span></label>
                <Input
                    name="recipientLastName"
                    type="text"
                    className={this.state.emptyLastName ? "input error" : "input"}
                    value={storeDelivery.recipientLastName}
                    onChange={this.onChangeLastName}
                />
              </div>

              <div className="input-field">
                <label>E-mail <span className="red">{this.state.errorMsgEmail}</span></label>
                <Input
                    name="email"
                    type="email"
                    className={this.state.emailIsValid === false ? "input error" : "input"}
                    value={storeDelivery.recipientEmail}
                    onChange={this.onChangeEmail}
                />
              </div>

              <div className="input-field last2">
                <label>Телефон <span className="red">{this.state.errorMsgPhone}</span></label>
                <PhoneInput
                  country={'ru'}
                  name="recipientPhone"
                  value={storeDelivery.recipientPhone}
                  onlyCountries={['ru', 'by', 'ua']}
                  onChange={this.onChangePhone}
                />
              </div>

            </div>
          </div>
        </div>

        <div className="forms_section">
          <div className="forms_section_entry">
            <h3 className="uppercase">Комментарий к заказу</h3>
            <div className="input-field">
              <textarea
                className="input"
                onKeyUp={this.onChangeComment.bind(this)}
                defaultValue={storeDelivery.recipientComment}
              />
            </div>
          </div>
        </div>
        {this.state.errorMsg &&
          <div className="forms_section">
            <div className="forms_section_entry">
              <div className="red text-right">{this.state.errorMsg}</div>
            </div>
          </div>
        }

        <div className="forms_section">
          <div className="forms_section_entry text-right">
            <input type="submit" className="btn btn-red upper" value="Отправить на обработку"
              onClick={this.onClickSubmit.bind(this)}
            />
          </div>
        </div>
      </div>

    );
  }
}
WholeSalePage.propTypes = {
  basket: MPropTypes.observableObject,
  storeDelivery: MPropTypes.observableObject,
  cookies: instanceOf(Cookies).isRequired
}

export default withCookies(WholeSalePage);

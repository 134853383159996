import React from "react";
import {inject, PropTypes as MPropTypes} from "mobx-react";
import { Link } from "react-router-dom";
import { FooterMenu, FooterSocial } from "components";
import {
  URL_DEALERS,
  URL_DOWNLOADS,
  URL_FEEDBACK,
  URL_FAQ,
  URL_NEWS,
  URL_RULES,
  URL_PARTNERS,
} from "api";
import {rpHTML} from "../../../services/helpers";
import TopMenuItem from "./TopMenuItem";


@inject("general", "storeMenu")
class Footer extends React.Component {
  render() {
    const { general, storeMenu } = this.props;
    return (
      <footer className="footer">
        <FooterMenu />
        <div className="footer_middle">
          <div className="container">
            <div className="row">
              <div className="grid-20 md3 sm12 sm-hidden">
                <p>
                  <a href="#">
                    <img
                        src="/images/svg/logo_alis96_white.svg"
                        alt="logo"
                        style={{maxWidth:"61px"}}
                    />
                  </a>
                </p>
                {rpHTML(general.getLettering("014"))}
                <p>
                  <a href="https://desit.ru/">разработка сайта</a>
                </p>
                <p>
                  <a href="https://toomanof.ru/">сопровождение сайта</a>
                </p>
              </div>
              <div className="grid-80 md9 sm12">
                <div className="row">
                  <div className="grid-3 sm12">
                    <b>{rpHTML(general.getLettering("010"))}</b>
                      <br />
                    {rpHTML(general.getLettering("011"))}
                  </div>
                  <div className="grid-3 sm6 footnav">
                    <ul>
                      {storeMenu.menuFooter &&
                      storeMenu.menuFooter.map((obj, index) => {
                        return (
                            <li key={index}>
                              <Link to={obj.href}>{obj.name}</Link>
                            </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="grid-3 sm12 footnav">
                    <p className="sm-hidden">
                      <b>Интернет-магазин</b>
                    </p>
                    <ul>
                      {storeMenu.menuFooter2 &&
                      storeMenu.menuFooter2.map((obj, index) => {
                        return (
                            <li key={index}>
                              <Link to={obj.href}>{obj.name}</Link>
                            </li>
                        );
                      })}
                    </ul>
                    <FooterSocial />
                  </div>
                  <div className="grid-3 sm12 sm-hidden">
                    <ul>
                      <li>
                        <Link to={"/info_payments_and_delivery/"}>Оплата и доставка</Link>
                      </li>
                    </ul>
                    <div className="footer_pay">
                      <img src="/images/svg/sbp.svg" alt="" />
                      <img src="/images/svg/yoo.svg" alt="" />
                      <img src="/images/svg/visa.svg" alt="" />
                      <img src="/images/svg/mastercard.svg" alt="" />
                      <img src="/images/svg/paypal.svg" alt="" />
                      <img src="/images/svg/world.svg" alt="" />
                      <img src="/images/svg/yookassa.svg" alt="" />
                      <img src="/images/svg/sdek.svg" alt="" />
                      <img src="/images/svg/pochta.svg" alt="" />
                      <img src="/images/svg/boxberry.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  general: MPropTypes.observableObject,
  storeMenu: MPropTypes.observableObject
};

export default Footer;

import React from 'react';
import { rpHTML } from "../../services/helpers";

const InnerLayout = ({ index, onBalloonClick, object, showButtonInBalloon = false }) => {
  const styleActive = {
    background: '#f4a1b5',
    color: 'white',
    borderRadius: '4px',
    border: '1px solid #f4a1b5',
    padding: '5px'
  }
  const styleNotActive = {
    background: '#e01a4a',
    color: 'white',
    borderRadius: '4px',
    border: '1px solid #f4a1b5',
    padding: '5px'
  }
  const styleButton = object.active ? styleActive : styleNotActive
  const captionButton = object.active ? "Выбран" : "Доставить в этот ПВЗ"

  const outputIndexWithAddress = (object.postalCode || object.location?.postalCode ||  "")  + " ";
  return <div>
    <h4>
      {
        outputIndexWithAddress
      }
      {object.namePoint}
      {((object.postalCode && object.postalCode[0] == 9) ||
        (object.location?.postalCode && object.location.postalCode[0] == 9) ||
        (object.address.slice(0, 6)[0] == 9)
      )
        && ` (постамат)`}
    </h4>
    <br />
    {showButtonInBalloon &&
    <React.Fragment>
      <input
          disabled={captionButton ? false : true}
          style={styleButton}
          type="button"
          onClick={(e) => {
            onBalloonClick(object, e);
          }}
          value={captionButton}
      />
    </React.Fragment>
    }
    <br/>
    <br/>
    {object.address || object.location?.addressFull}
    <br />
    {object.comment}
    {object.workHours &&
      <div>
        <br />
        Режим работы:
        <br />
        {rpHTML(object.workHours, '')}
        <p>
          {object.deliveryPointInfo}
        </p>
      </div>
    }

  </div>;
};

export default InnerLayout;
import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import { BasketItemPreview } from "components";

@inject("basket", "routing")
@observer
class BasketPreview extends React.Component {

  onClickGotoBasket = (e) => {
    const cartWidjet = document.getElementById("cart_widjet_popup");
    cartWidjet.style.visibility = "hidden";
    cartWidjet.style.opacity = 0;
  };

  onMouseOverCartWidjet = (e) => {
    const path_split = this.props.routing.location.pathname.split("/");
    if (path_split[1] === "cart") {
      return;
    }
    const cartWidjet = document.getElementById("cart_widjet_popup");
    if (!cartWidjet ) return;
    cartWidjet.style.visibility = "visible";
    cartWidjet.style.opacity = 1;
  };

  onMouseOutCartWidjet = (e) => {
    const { basket } = this.props;
    const cartWidjet = document.getElementById("cart_widjet_popup");

    if (basket.clickDeleteItem || !cartWidjet){
      basket.setStateClickDeleteItem(false);
      return;
    }
    cartWidjet.style.visibility = "hidden";
    cartWidjet.style.opacity = 0;
  };

  render() {
    const { basket } = this.props;
    console.log(basket.products)
    return (
      <React.Fragment>
        <div
          className="cart_widjet"
          onMouseOver={this.onMouseOverCartWidjet}
          onMouseOut={this.onMouseOutCartWidjet}
        >
          {basket.products && basket.products.length > 0 && (
            <Link
              to="/cart"
              className="cart_widjet_btn"
              onClick={this.onClickGotoBasket}
            >
              <span>
                <b>КОРЗИНА: </b>
                <b>{basket.quantity} </b>шт. на сумму{" "}
                <b>{basket.amount} руб.</b>
              </span>
            </Link>
          )}

          {basket.products && basket.products.length === 0 && (
            <span to="/cart" className="cart_widjet_btn">
              <span>
                <b>КОРЗИНА: </b>
                <b>{basket.quantity} </b>шт. на сумму{" "}
                <b>{basket.amount} руб.</b>
              </span>
            </span>
          )}
          {basket.products && basket.products.length > 0 && (
            <div id="cart_widjet_popup" className="cart_widjet_popup">
              <div className="cart_widjet_header">
                Недавно добавленные позиции:
              </div>
              {basket.products.length > 4 && (
                <div className="cart_widjet_dots"></div>
              )}

              {basket.products.slice(0, 4).map((item, index_pr) => {
                return (
                  <BasketItemPreview
                    key={index_pr}
                    colr={item.colr}
                    product={item.product}
                    qnt={item.qnt}
                  />
                );
              })}
              <div className="cart_widjet_footer">
                <Link
                  to="/cart"
                  className="btn btn-red"
                  onClick={this.onClickGotoBasket}
                >
                  Перейти в корзину
                </Link>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
BasketPreview.propTypes = {
  basket: MPropTypes.observableObject,
};

export default BasketPreview;

import CostModel from "./private/CostModel";

export default class BoxBerryCostModel extends CostModel{
    get cost(){
        return this.getCost('price')
    }
    get costCourier(){
        return this.getCost('price', true)
    }

    get intPeriod(){
        return 0
    }

    get period(){
        return this.getPeriod(this.deliveryPeriod)
    }
}
import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

@inject("storeDelivery")
@observer
class SelectDeliveryPointItem extends React.Component {
  state = {
    isDisabled: this.props.isDisabled,
    style: {
      color: "#c2c2c2",
    },
  };

  onChangeDelivery = (method, pointCost) => {

    if (!this.state.isDisabled) {
      this.props.storeDelivery.setActiveDeliveryMethod(method);

      this.props.storeDelivery.setActiveDeliveryPointCost(pointCost);
    }
  };

  render() {
    const {
      storeDelivery,
      showSelect,
      deliveryPointCost,
      deliveryMethod,
      cost,
      isDisabled,
    } = this.props;
    const styleLabelSelect = showSelect
      ? "check-field"
      : "check-field textonly";
    return (
      <React.Fragment>
        {deliveryPointCost && deliveryPointCost.cost != "" && (
          <div className="input-field">
            <label
              className={styleLabelSelect}
              style={isDisabled ? this.state.style : null}
            >
              {showSelect && (
                <input
                  type="radio"
                  name="delivery_method"
                  disabled={isDisabled}
                  onChange={this.onChangeDelivery.bind(
                    null,
                    deliveryMethod,
                    deliveryPointCost
                  )}
                  checked={
                    storeDelivery.activeDeliveryMethod === deliveryMethod
                      ? "checked"
                      : ""
                  }
                />
              )}
              {storeDelivery.getStrMethod(deliveryMethod) +
                " - " +
                cost?.toString()?.slice(0, -1) +
                ", " +
                deliveryPointCost.period}
            </label>
          </div>
        )}
      </React.Fragment>
    );
  }
}
SelectDeliveryPointItem.propTypes = {
  storeDelivery: MPropTypes.observableObject,
};

export default SelectDeliveryPointItem;

import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import {
  ZAGLUSHKA_LOGO_PIC
} from 'api'
import { Link } from "react-router-dom";
import {rHTML} from "../../services/helpers";

@inject('general')
@observer
class HomePage extends Component {
  componentDidMount() {
    const {general} = this.props;
    general.loadPageContent('/main/', false);
  }
  render() {
    const { general } = this.props
    let style_left_cel_banner = { backgroundImage: 'url(/images/' + general.leftMainBanner.href + ')' }
    let style_right_cel_banner = { backgroundImage: 'url(/images/' + general.rightMainBanner.href + ')' }
    return (
      <React.Fragment>
        <div className="container">
          <div className="container">

            <div className="sm-visible mobile_home_logo">
              <img src="images/uploads/logo_spg_hor_cmyk.png" alt="" />
            </div>
            <div className="homeslider">
              <div className="homeslider_logo">
                <img src={ZAGLUSHKA_LOGO_PIC} alt="" />
              </div>
              <div className="homeslider_bottom">Professional eyewear</div>
              <OwlCarousel
                className={"swiper-container"}
                navClass={["swiper-button-prev", "swiper-button-next"]}
                dotsClass={"swiper-pagination swiper-pagination-bullets"}
                dotClass={"swiper-pagination-bullet"}
                navText={['', '']}
                items={1}
                dots={true}
                nav={true}
                autoplay={true}
                loop={true}
                autoplayTimeout={7000}
                smartSpeed={1500}
              >
                {general.mainBanners.map((obj, index) => {
                  let style_background = { backgroundImage: 'url(images/' + obj.href + ')' }
                  let classTitle = 'homeslider_title ' + 'homeslider_title--' + obj.id;
                  return (
                    <Link to={'/spg/'} className='no_underline' key={index}>
                      <div
                        className="swiper-slide"
                        style={style_background}
                      >
                        <div className={classTitle} dangerouslySetInnerHTML={{ __html: obj.lettering.value }}></div>
                      </div>
                    </Link>
                  )
                })}
              </OwlCarousel>
            </div>
            <div className="row">
              {[general.rightMainBanner, general.leftMainBanner].map((obj, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="grid-6 sm12">
                      <Link to={obj.lettering.href} className="banner_home pad"
                        style={{ backgroundImage: 'url(/images/' + obj.href + ')' }}>
                        {
                          index === 0 &&
                          <span className="banner_home_plus"><b>+/-</b></span>
                        }
                        <span className="banner_home_title">
                          <span className="banner_home_bold" dangerouslySetInnerHTML={{ __html: obj.lettering.title }}></span>
                          <span className="banner_home_small" dangerouslySetInnerHTML={{ __html: obj.lettering.value }}></span>
                        </span>
                        {index == 0 && <span className="banner_home_price">{rHTML(general.getLettering("333"))}</span>}
                        {index == 1 && <span className="banner_home_new">NEW</span>}
                      </Link>
                    </div>
                  </React.Fragment>
                )
              })

              }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

HomePage.propTypes = {
  general: MPropTypes.observableObject,
}
export default HomePage
import React from 'react'
import {Link} from "react-router-dom";
import { BaseHeadBanners } from "components"

import {
    URL_CATALOG_POLAR_BROWN,
    URL_ABOUT_POLAR,
} from "api";


class HeadBannersPolar extends BaseHeadBanners {

    render() {
        const style_bigbanner = { backgroundImage: 'url(/images/uploads/banner73.jpg)'}
        const url_to = (this.props.buy)? URL_CATALOG_POLAR_BROWN: URL_ABOUT_POLAR

        return (
            <React.Fragment>
                <div className="bigbanner color-black" style={style_bigbanner}>
                    <div className="row">
                        <div className="grid-12">
                            <div className="bigbanner_title">ПОЛЯРИЗАЦИОННЫЕ ОЧКИ <i>POLARMASTER</i></div>
                            <p>Применение</p>
                            <p><img src="/images/svg/icons5.svg" alt=""/></p>
                            <Link to={url_to} className="btn btn-grey">{this.text_button}</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default HeadBannersPolar
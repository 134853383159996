import React from 'react'

import {
    ZAGLUSHKA_SUN_SVG,
    ZAGLUSHKA_UV400_SVG,
    URL_CATALOG_REHABIL_SUN,
    URL_ABOUT_REHABIL
} from "api";
import { BaseHeadBanners } from "components"
import {Link} from "react-router-dom";

class HeadBannersDriversSun extends BaseHeadBanners {

    render() {
        const style_bigbanner = { backgroundImage: 'url(/images/uploads/banner48.jpg)'}
        const url_to = (this.props.buy)? URL_CATALOG_REHABIL_SUN: URL_ABOUT_REHABIL

        return (
            <React.Fragment>
                <div className="bigbanner" style={style_bigbanner}>
                    <div className="row">
                        <div className="grid-20 md3 sm12">
                            <Link to={URL_CATALOG_REHABIL_SUN}><img src="/images/uploads/banner19.jpg" alt=""/></Link>
                        </div>
                        <div className="grid-80 md9 sm12">
                            <div className="bigbanner_title">СОЛНЦЕЗАЩИТНЫЕ (РЕАБИЛИТАЦИОННЫЕ) ОЧКИ SPG</div>
                            <p>Применение</p>
                            <p>
                                <img src={ZAGLUSHKA_SUN_SVG} alt=""/>
                                <img src={ZAGLUSHKA_UV400_SVG} alt=""/>
                            </p>
                            <Link to={url_to} className="btn btn-grey">{this.text_button}</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default HeadBannersDriversSun
import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

@inject("basket", "storeCatalog")
@observer
class ProductPreview extends React.Component {
  constructor(props) {
    super(props);
    this.product = props.product;
    this.state = {
      activeColr:
        this.product.colorsdic.length > 0 ? this.product.colorsdic[0] : null,
      activePhoto: 0,
    };
  }

  renderProductPhotos() {
    if (!this.state.activeColr) {
      return;
    }
    return (
      <div className="productbox_image">
        {this.state.activeColr.photos.map((obj, index) => {
          const style = {
            display: index == this.state.activePhoto ? "block" : "none",
          };
          return (
            <Link key={index} to={this.product.med ? this.product.url+ "med/": this.product.url} style={style} target="_blank">
              <img src={"/media/prodpics/" + obj[0] + "_m." + obj[1]} alt="" />
            </Link>
          );
        })}
      </div>
    );
  }

  renderProductSmallPhotos() {
    if (!this.state.activeColr) {
      return;
    }
    return (
      <ul className="thumbs">
        {this.state.activeColr.photos.map((obj, index) => {
          return (
            <li key={index}>
              <img
                src={"/media/prodpics/" + obj[0] + "_s." + obj[1]}
                rel={index}
                alt=""
                onMouseOver={this.onMouseOverSmallPhoto.bind(null, index)}
                onMouseOut={this.onMouseOutSmallPhoto}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  onAddToCart = (idProduct, e) => {
    e.preventDefault();
    this.props.basket.addToCart(idProduct, this.state.activeColr.key);
  };

  onMouseOverSmallPhoto = (index, e) => {
    this.setState({ activePhoto: index });
  };

  onMouseOutSmallPhoto = (e) => {
    this.setState({ activePhoto: 0 });
  };

  onClickColr = (index, e) => {
    this.setState({ activeColr: this.product.colorsdic[index] });
  };

  renderColrs() {
    return this.product.colorsdic.map((colr, index) => {
      const style1 = { backgroundColor: colr.css1 };
      const style2 = { backgroundColor: colr.css2 };

      return (
        <label className="choose_color" key={index}>
          <input
            type="radio"
            name={this.product.artikul}
            defaultValue={index}
            defaultChecked={this.state.activeColr === colr}
            onClick={this.onClickColr.bind(null, index)}
          />
          <span className="choose_color_col" style={style1}></span>
          <span className="choose_color_col" style={style2}></span>
          <span className="tooltip">{colr.name}</span>
        </label>
      );
    });
  }

  renderMarkers() {
    return (
      <div className="productbox_markers">
        {this.product.novinka && <div className="productbox_new">new</div>}
        {this.product.hit && <div className="productbox_hit">Хит</div>}
      </div>
    );
  }

  render() {
    const { product } = this.props;
    let productbox_className = product.discount > 0 ? "productbox_discount_price": "productbox_price"
    return (
      <React.Fragment>
        <div className="grid-4 md6 sm12">
          <div className="productbox">
            {(product.diop || product.med) && <div className="gallery"><span className="banner_home_plus"><b>+/-</b></span></div>}
            <div className="productbox_top">{this.renderProductPhotos()}</div>
            <div className="productbox_details">
              {this.renderProductSmallPhotos()}
              <div className="productbox_desc">
                <div className="productbox_art">
                  Артикул: <b>{product.artikul}</b> | {product.catdisplay}
                </div>
                {this.renderColrs()}
              </div>
              <div className="productbox_meta">
                <div className={productbox_className}>{product.discountedPrice} р.</div>
                <div className="productbox_more">
                  <Link to={product.med ? product.url+ "med/": product.url} target="_blank">Подробнее</Link>
                </div>
                <a
                  href="#"
                  className="btn btn-grey"
                  onClick={this.onAddToCart.bind(null, product.id)}
                >
                  В корзину
                </a>
              </div>
            </div>
            {this.renderMarkers()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProductPreview.propTypes = {
  basket: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject,
  cookies: instanceOf(Cookies).isRequired,
};
export default withCookies(ProductPreview);

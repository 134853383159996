import React from 'react'
import { BaseHeadBanners } from "components"

class HeadBannersClips extends BaseHeadBanners {

    render() {
        const style_bigbanner = { backgroundImage: 'url(/images/uploads/banner42.jpg)'}

        return (
            <React.Fragment>
                <div className="bigbanner color-black" style={style_bigbanner}>
                    <div className="row">
                        <div className="grid-20 md3 sm12">
                            <a href="#"><img src="/images/uploads/banner17.jpg" alt=""/></a>
                        </div>
                        <div className="grid-80 md9 sm12">
                            <div className="bigbanner_title">Клипсы SPG<br/> для очков</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default HeadBannersClips
import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

import { rHTML } from "services/helpers";
import { Breadcrumb } from "components";

@inject("general", "routing")
@observer
class CompanyPage extends Component {
  componentDidMount() {
    this.props.general.loadContentItem(this.props.routing.location.pathname);
  }
  render() {
    const { general } = this.props;

    return (
      <div className="container">
        <div className="breadcrumbs">
          <ol className="breadcrumbs_nav">
            <Breadcrumb breadcrumbs={general.page_object?.krohi} />
          </ol>
        </div>

        <h1>{general.page_object?.h1}</h1>

        <div className="article">
          <div className="row">
            <div className="grid-20 sm12 about_logo">
              <p>
                <a href="#">
                  <img
                      src="/images/svg/logo_alis96_blue.svg"
                      alt="logo"
                      style={{maxWidth:"151px"}}
                  />
                </a>
              </p>
            </div>
            <div className="grid-80 sm12">
              {general.page_object && rHTML(general.page_object.content)}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="grid-80 offset-20 offset-sm-0 sm12">
              <h2>ОСНОВНЫЕ СОБЫТИЯ:</h2>
              {general.companyEvents &&
                general.companyEvents.map((obj, index) => {
                  return (
                    <p key={index}>
                      <b>{obj.year}</b> {obj.info}
                    </p>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CompanyPage.propTypes = {
  general: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
};

export default CompanyPage;

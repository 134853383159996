import React from 'react'
import { Alert } from 'components'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import DealersPage from "../../../pages/site/DealersPage";


@inject('alert')
@observer
class Alerts extends React.Component{
    onClickDeleteAlert = (guid) => {
        this.props.alert.deleteAlert(guid)
    }

    render(){
        const { alert } = this.props
        return (
            <div className="alerts">
                {Array.from(alert.alerts).map(obj => {
                    const guid = obj[0]
                    const item = obj[1]
                    return (
                        <Alert
                            key={guid}
                            guid={guid}
                            onClickDeleteAlert={this.onClickDeleteAlert.bind(null, guid)}
                            type={item.type}
                            text={item.text}
                            autoClose={item.autoClose} />
                    )
                })}
            </div>
        )
    }
}


Alerts.propTypes = {
    alert: MPropTypes.observableObject
}

export default Alerts;

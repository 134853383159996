import { makeObservable, observable, action, computed } from 'mobx'
import md5 from 'md5'
import { logGroupMessage } from 'services/helpers'
import {
    MenuModel,
    GeneralModel
} from 'models'


import {
    apiCall,
    MENU_FOOTER_URL,
    MENU_FOOTER2_URL,
    MENU_TOP_URL,
} from 'api'


class MenuStore{
    constructor(){
        makeObservable(this)
    }

    @observable menuTop = null
    @observable menuFooter = null
    @observable menuFooter2 = null

    @action
    loadTopMenu = async () => {
        if (this.menuTop){
            return
        }

        let result = await apiCall(MENU_TOP_URL, 'GET', {}, false)
        if(!result.isError){
            let arr = []
            for(let obj of result.data){
                arr.push(new MenuModel(obj))
            }
            this.menuTop = arr

        }
    }

    @action
    loadFooterMenu = async () => {
        if (this.menuFooter){
            return
        }

        let result = await apiCall(MENU_FOOTER_URL, 'GET', {}, false)
        if(!result.isError){
            let arr = []
            for(let obj of result.data){
                arr.push(new MenuModel(obj))
            }
            this.menuFooter = arr

        }
    }

    @action
    loadFooter2Menu = async () => {
        if (this.menuFooter){
            return
        }

        let result = await apiCall(MENU_FOOTER2_URL, 'GET', {}, false)
        if(!result.isError){
            let arr = []
            for(let obj of result.data){
                arr.push(new MenuModel(obj))
            }
            this.menuFooter2 = arr

        }
    }
}

const menuStore = new MenuStore()
export default menuStore
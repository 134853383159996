import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Loading } from 'components'

@inject('modal', 'routing', 'user')
@observer
class BaseAuthLayout extends Component {
    render() {
        const { user } = this.props

        if (!user.profile) {
            return <Loading />
        }

        return (
            <div className="adminpage">
                {this.props.children}
            </div>
        )
    }
}

BaseAuthLayout.propTypes = {
    children: PropTypes.node,
    routing: MPropTypes.observableObject,
    user: MPropTypes.observableObject
}

export default BaseAuthLayout

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Loading } from 'components'


@inject('user')
@observer
class AuthRoute extends React.Component{
    componentDidMount() {
        this.props.user.checkToken()
    }

    render(){
        const {component: Component, ...rest} = this.props
        const { user } = this.props
        if ((user.isAuthenticated === null)){
            return <Loading />
        }

        if(user.me === null){
            return <Redirect to="/" />
        }

        return (
                <Route {...rest} render={props => (
                    user.isAuthenticated === false ? (
                      <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                    ) : (
                      <Component {...props}/>
                    )
                )}/>
            )
    }
}


AuthRoute.propTypes = {
    component: PropTypes.func,
    user: MPropTypes.observableObject
}
export default AuthRoute
import React, { Component } from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { SelectDeliveryPoints, YandexMap, Select } from "components";
import { Link } from "react-router-dom";
import { rpHTML } from "../../services/helpers";


@inject('storeDelivery', 'general')
@observer
class PaymentDeliveryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: this.props.storeDelivery.activeCountryDelivery?.id || 1,
    }
  }

  componentDidMount() {
    const $ = window.$
    $('select[name="chooseCountry"]').styler(
      { onSelectClosed: this.onChangeCountry.bind() }
    );
    $('select[name="selectDP"]').styler(
      { onSelectClosed: this.onChangeDeliveryPoint.bind() }
    ).trigger('refresh');
    const { general, storeDelivery } = this.props
    general.loadCountries()

    if (storeDelivery.activeDeliveryPoint)
      $('#input-delivery-point .jq-selectbox__select').css({ border: '1px solid #01826a' })
    else
      $('#input-delivery-point .jq-selectbox__select').css({ border: '1px solid #ff0000' })
  }

  componentDidUpdate() {
    const $ = window.$
    // $('select[name="chooseCountry"]').styler(
    //   { onSelectClosed: this.onChangeCountry.bind() }
    // );
    // $('select[name="selectDP"]').styler(
    //   { onSelectClosed: this.onChangeDeliveryPoint.bind() }
    // ).trigger('refresh');
    const { storeDelivery } = this.props
    if (storeDelivery.activeDeliveryPoint)
      $('#input-delivery-point .jq-selectbox__select').css({ border: '1px solid #01826a' })
    else
      $('#input-delivery-point .jq-selectbox__select').css({ border: '1px solid #ff0000' })
  }


  onChangeCountry = (val) => {
    const { storeDelivery } = this.props
    const $ = window.$
    let numberCountry = val
    storeDelivery.setActiveCountryDeliveryID(numberCountry)
    this.setState({ country: numberCountry });

    let activeDeliveryMethod = numberCountry > 1 ? storeDelivery.DPD : null
    let activeDeliveryPoint = numberCountry > 1 ? { cost: '396 руб.', period: '10-28 дней' } : null

    storeDelivery.setActiveDeliveryMethod(activeDeliveryMethod);
    storeDelivery.setActiveDeliveryPoint(activeDeliveryPoint, false)
    storeDelivery.setActiveDeliveryPointCost(activeDeliveryPoint)
  }

  onChangeAddress = (data) => {
    this.props.storeDelivery.setActiveDeliveryPoint(null, false)
    this.props.storeDelivery.setDadataAddress(data)
    this.props.storeDelivery.getLocationAddress(data.value, true, true, true)
  }

  onChangeChoicePaymentMethod = (value) => {
    if (this.props.storeDelivery.activeCountryDelivery?.withDelivery) {
      this.props.storeDelivery.setActiveDeliveryPoint(null, false);
      this.props.storeDelivery.setValueChoicePaymentMethod(value)
    }
  }

  onChangeDeliveryPoint = (e) => {
    const { storeDelivery } = this.props;
    const $ = window.$;
    const self = $('select[name="selectDP"]');
    storeDelivery.setActiveDeliveryPoint(storeDelivery.tmpDeliveryPoints.filter(obj => obj._code == self.val())[0])
  }

  render() {
    const { storeDelivery, general } = this.props

    const showTextAfterMap = true
    const isRussia = !(!storeDelivery.activeCountryDelivery?.withDelivery && storeDelivery.activeCountryDelivery?.methodDelivery)

    return (
      <React.Fragment>
        <div className="container container_middle">

          <div className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Оплата и доставка</a>
              </li>
            </ol>
          </div>

          <div className="page_header">
            <h1>Оплата и доставка</h1>
          </div>

          <h3 className="uppercase">Доставка</h3>

          <div className="row">
            <div className="grid-4 tb6 sm12">
              <div className="input-field">
                <label>Страна</label>
                {general.countries &&
                  <Select
                    id="choose_country"
                    name="chooseCountry"
                    className="selectbox"
                    options={general.countries}
                    value={this.state.country}
                    isValueFromParent={true}
                    onChange={this.onChangeCountry.bind()}
                  />
                }
              </div>
            </div>
          </div>
          {storeDelivery.activeCountryDelivery && storeDelivery.activeCountryDelivery?.withDelivery &&
            <div className="row row_fields our_country_box">
              <div className="input-field grid-10 sm8">
                <label>Населённый пункт:</label>
                <AddressSuggestions
                  inputProps={{ placeholder: "Введите населённый пункт" }}
                  token="a101cdd086f3070d1e38bff67fe794abf81ea22a"
                  filterFromBound='region'
                  filterToBound='settlement'
                  onChange={this.onChangeAddress}
                  value={storeDelivery.dadataAddress}
                />
                {/*<div className="input-text">по данному адресу индекс не определен</div> */}
              </div>
            </div>
          }
          {
            (storeDelivery.addressLocation || !storeDelivery.activeCountryDelivery?.withDelivery) &&
            <div className="form_after_complete">
              <div className="forms_box">
                <h3>Варианты оплаты:</h3>
                <div className="choose_row">
                  <label className="choose_btn">
                    <input
                      type="radio"
                      name="pay_method"
                      checked={(storeDelivery.valueChoicePaymentMethod == storeDelivery.PREPAYMENT || !storeDelivery.activeCountryDelivery?.withDelivery) ? "checked" : ""}
                      onChange={this.onChangeChoicePaymentMethod.bind(null, storeDelivery.PREPAYMENT)}
                    />
                    <span>Предоплата</span>
                  </label>
                  {storeDelivery.activeCountryDelivery &&
                    <label className="choose_btn"
                      style={{ opacity: !storeDelivery.activeCountryDelivery?.withDelivery ? ".5" : '1' }}
                    >
                      {storeDelivery.activeCountryDelivery?.withDelivery &&
                        <input
                          type="radio"
                          name="pay_method"
                          checked={storeDelivery.valueChoicePaymentMethod == storeDelivery.PAYMENT_UPON_RECEIPT ? "checked" : ""}
                          onChange={this.onChangeChoicePaymentMethod.bind(null, storeDelivery.PAYMENT_UPON_RECEIPT)}
                        />}
                      <span>Оплата при получении</span>
                    </label>
                  }
                </div>
              </div>
              {(storeDelivery.deliveryPointCDEKCost || storeDelivery.deliveryPointPostCost || storeDelivery.deliveryPointBoxberryCost || !isRussia) &&
                <div className="forms_box">
                  <h3>Возможные варианты доставки:</h3>
                  <SelectDeliveryPoints
                    showSelect={false}
                    isRussia={!(!storeDelivery.activeCountryDelivery?.withDelivery && storeDelivery.activeCountryDelivery?.methodDelivery)}
                  />
                </div>
              }
              {isRussia && <p className="color-black"><b>
                Во время оформления заказа вы сможете выбрать ближайший к вам пункт выдачи или вариант доставки курьером.
              </b></p>}
              {storeDelivery.activeCountryDelivery?.withDelivery &&
                <div className="our_country_box">
                  <div className="forms_section_map">
                    <YandexMap />
                  </div>
                </div>
              }
              {showTextAfterMap && rpHTML(general.getLettering("357"), "article mb-20")}
            </div>
          }
        </div>

        {storeDelivery.renderMap = true}
      </React.Fragment>

    )
  }
}

PaymentDeliveryPage.propTypes = {
  general: MPropTypes.observableObject,
  storeDelivery: MPropTypes.observableObject,
}

export default PaymentDeliveryPage

import Model from './private/Model'

export default class ProductModel extends Model{
    get pricePart(){
        return this.price.split('.')[0]
    }

    get priceCentsPart(){
        return this.price.split('.')[1]
    }
}
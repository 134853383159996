import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import BasketItemDioptrPopup from "./BasketItemDioptrPopup";


@inject("basket", "storeCatalog")
@observer
class BasketItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countBasketItem: props.qnt,
      isChoiceEquipment: props.type_equipment === 2
    };
  }
  componentDidMount() {
    const $ = window.$;
    $('input[type="number"]').styler();

    $(`#basket_${this.props.id} .jq-number__spin.minus`).click((e) => {
      e.preventDefault();
      if (this.state.countBasketItem > 1) {
        this.setState({
          countBasketItem: +this.state.countBasketItem - 1,
        });
        this.setCountProduct();
      }
    });
    $(`#basket_${this.props.id} .jq-number__spin.plus`).click((e) => {
      e.preventDefault();
      this.setState({
        countBasketItem: +this.state.countBasketItem + 1});
      this.setCountProduct();
    });
  }

  onChoiceEquipmentItemInCart = (id1, id2, e) => {
    e.preventDefault();
    this.props.basket.choiceEquipmentItemInCart(this.props.product.id, id1, id2);
    if (id2 == 1)
      this.setState({isChoiceEquipment: false });
    else
      if (id2 == 2) this.setState({isChoiceEquipment: true });
  };

  onDeleteItemInCart = (idProduct, key, e) => {
    e.preventDefault();
    this.setState({show: false})
    this.props.basket.deleteItemInCart(idProduct, key);
  };

  onChangeCount = (e) => {
    e.preventDefault();
    let countBasketItem = +e.target.value;
    countBasketItem =
      countBasketItem === 0
        ? ""
        : countBasketItem > 999
        ? 999
        : countBasketItem;
    this.setState({ countBasketItem });
  };

  onBlurCount() {
    if (
      this.state.countBasketItem === 0 || isNaN(+this.state.countBasketItem)
    ) {
      this.setState({ countBasketItem: 1 });
    }
    this.setCountProduct();
  }

  setCountProduct = () => {
    const idProduct = this.props.product.id;
    const colrKey = this.props.colr.key;
    const qnt = +this.state.countBasketItem || 1;
    this.props.basket.editCountProduct(idProduct, colrKey, qnt);

  };

  renderColrs() {
    const style1 = { backgroundColor: this.props.color_css1 };
    const style2 = { backgroundColor: this.props.color_css2};
    return (
      <div className="choose_color">
        <span className="choose_color_col" style={style1}></span>
        <span className="choose_color_col" style={style2}></span>
        <span className="tooltip">{this.props.colr.name}</span>
      </div>
    );
  }
  render() {

    const { product, colr } = this.props;
    const price_equipment_full =(this.state.isChoiceEquipment && product.colr && product.colr.kfull)
        ? Number(product.colr.kfull.discountedPrice): 0
    const sumAtProduct = (+product.discountedPrice + price_equipment_full) * Number(this.state.countBasketItem);
    let blueBlockOrPhotoChrome = (product && product.blueBlock) || (product && product.photochrome +" " + product.photochromeColor)

    return (
      <React.Fragment>
        <div className="basket_row">

          <div className="basket_cell basket_entry">
            <div className="basket_subcell basket_img">
              <Link to={product.url}>
                <img
                  src={
                    "/media/prodpics/" +
                    product.colr.photos[0][0] +
                    "_m." +
                    product.colr.photos[0][1]
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="basket_subcell">
              <div className="basket_title">
                <Link to={product.url}>{product.name}</Link>
              </div>
              {product.diop &&
                <BasketItemDioptrPopup
                    colr={colr}
                    product={product}
                />
              }

                {product && product.med && product.medtypelinz &&
                <p>
                  <b>Тип линз:</b><br/> {product.medtypelinzTitle} <br/>{blueBlockOrPhotoChrome}
                </p>
                }
              <p>
                Артикул: <b>{product.artikul}</b>
                <br />
                Категория: {product.catdisplay}
                <br />
                Цвет: {colr.name}
              </p>
              {this.renderColrs()}
            </div>
          </div>
          <div className="basket_cell">
            {this.props.product.ptype !== 3 && (
              <div className="complectation_row">
                <label
                  className={
                    this.props.type_equipment === 1
                      ? "complectation complectation_active"
                      : "complectation"
                  }

                  onClick={this.props.type_equipment !== 1 ?
                      this.onChoiceEquipmentItemInCart.bind(
                          this,
                          this.props.id_color1 + ":" + this.props.id_color2,
                          1
                      ) : undefined
                  }
                >
                  <input type="radio" name="compl-1"
                         defaultChecked={"checked"}
                  />
                  <span className="complectation_img">
                    {product.colr.kst && product.colr.kst.smallpic &&
                      <img src={"/media/prodpics/" + product.colr.kst.smallpic} alt=""/>
                    }
                  </span>
                  <span className="complectation_entry">
                    Стандартная: {this.props.colr.kst ? this.props.colr.kst.name: product.colr.kst.name}
                  </span>
                </label>
                {product.stkompl === false &&
                  <label
                      className={
                        this.props.type_equipment === 2
                            ? "complectation complectation_active"
                            : "complectation"
                      }
                      onClick={(this.props.type_equipment !== 2 )?
                          this.onChoiceEquipmentItemInCart.bind(
                              this,
                              this.props.id_color1 + ":" + this.props.id_color2,
                              2
                      ): undefined}
                  >


                    <input type="radio" name="compl-1"/>
                    <span className="complectation_img">
                      {this.props.product.colr.kfull && this.props.product.colr.kfull.smallpic &&
                       <img src={"/media/prodpics/" + this.props.product.colr.kfull.smallpic} alt=""/>
                      }
                    </span>
                    <span className="complectation_entry">
                        Полная: {this.props.colr.kfull ? this.props.colr.kfull.name: product.colr.kfull.name}
                      <br/>
                      <b>+{product.colr.kfull.discountedPrice} р.</b>
                    </span>
                  </label>
                }
              </div>
            )}
          </div>
          <div className="basket_cell basket_cell_meta">
            <div
              className="basket_subcell basket_count"
              id={`basket_${this.props.id}`}
            >
              <input
                type="number"
                className="number"
                min="1"
                value={this.state.countBasketItem}
                onChange={this.onChangeCount.bind(this)}
                onBlur={this.onBlurCount.bind(this)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="basket_subcell basket_price">{sumAtProduct} р.</div>
          </div>
          <button
            className="basket_delete"
            onClick={this.onDeleteItemInCart.bind(null, product.id, colr.key)}
          />
        </div>
      </React.Fragment>
    );
  }
}

BasketItem.propTypes = {
  basket: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject
};

export default BasketItem;

import Model from './private/Model'
import {getDeliveryStore} from "../stores";

export default class YouPositionModel extends Model{
    get namePoint(){
        return "Вы здесь"
    }

    get latitude(){
        return this._latitude
    }

    get longitude(){
        return this._longitude
    }

    set latitude(val){
        this._latitude = val
    }

    set longitude(val){
        this._longitude = val
    }


    get _code(){
        return this.code
    }

    get address(){
        return this._address
    }

    set address(val){
        this._address =val
    }

    get workHours(){
        return ''
    }

    get deliveryPeriod(){
        //this.DeliveryPeriod
    }

    get company(){
        return ""
    }

    get comment(){
        return this._comment
    }

    set comment(val){
        this._comment = val
    }

    get iconImageHref(){

        return '/images/you_position.svg'
    }

    get iconImageSize(){
        return [100, 40]
    }

    get active(){
        const deliveryStore = getDeliveryStore()
        return deliveryStore.activeDeliveryPoint == null
    }
}

import React from 'react'
import { Link } from 'react-router-dom'


class TopMenuItem extends React.Component {

  renderSubItems(item, index) {
    let subitems = item.subitems.map(obj => {
      const key_tm = 'ti' + obj.id.toString()
      return (<TopMenuItem key={key_tm} item={obj} index={obj.id} />)
    })

    if (subitems.length > 0) {
      const key_ul = 'ul_' + index.toString()
      subitems = <ul key={key_ul}>{subitems}</ul>
    }
    return subitems
  }

  render() {
    const { item, index } = this.props
    return (
      <li key={index}>
        <Link to={item.href == '/spg_power/' ? '/optika/' : item.href}>{item.name}</Link>
        {this.renderSubItems(item, item.id)}
      </li>
    )
  }
}

export default TopMenuItem
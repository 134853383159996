import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Loading } from 'components'


/**
 * Require Auth
 */
@inject('user')
@observer
class Auth extends Component{

    render(){

        return {...this.props.children}
    }
}


Auth.propTypes = {
    children: PropTypes.node,
}

export default Auth
import React from "react";
import {
  URL_ABOUT_ACTIVE_SUN,
  URL_CATALOG_ACTIVE_SUN,
  URL_ABOUT_ACTIVE_SPG,
  URL_CATALOG_ACTIVE,
  ZAGLUSHKA_SUN_SVG,
  ZAGLUSHKA_UV400_SVG,
} from "api";
import { BaseHeadBanners } from "components";
import { Link } from "react-router-dom";

class HeadBannersActiveSun extends BaseHeadBanners {
  state = {
    linkToSunRelaxPage: this.props.buy
      ? URL_ABOUT_ACTIVE_SUN
      : URL_CATALOG_ACTIVE_SUN,
    linkToNightRelaxPage: this.props.buy
      ? URL_ABOUT_ACTIVE_SPG
      : URL_CATALOG_ACTIVE,
  };
  render() {
    const style_bigbanner = {
      backgroundImage: "url(/images/uploads/banner44.jpg)",
    };
    const url_to = this.props.buy
      ? URL_CATALOG_ACTIVE_SUN
      : URL_ABOUT_ACTIVE_SUN;

    return (
      <React.Fragment>
        <div className="bigbanner" style={style_bigbanner}>
          <div className="row">
            <div className="grid-20 md3 sm12">
              <a href="#">
                <img src="/images/uploads/banner21.jpg" alt="" />
              </a>
            </div>
            <div className="grid-80 md9 sm12">
              <div className="bigbanner_title">
                ОЧКИ ДЛЯ АКТИВНОГО ОТДЫХА SPG
              </div>
              <ul className="bigbanner_tabs bigbanner_tabs-brown">
                <li className="active">
                  <Link to={this.state.linkToSunRelaxPage}>ЯСНАЯ ПОГОДА</Link>
                </li>
                <li>
                  <Link to={this.state.linkToNightRelaxPage}>
                    НЕПОГОДА | НОЧЬ
                  </Link>
                </li>
              </ul>
              <p>Применение</p>
              <p>
                <img src={ZAGLUSHKA_SUN_SVG} alt="" />
                <img src={ZAGLUSHKA_UV400_SVG} alt="" />
              </p>
              <Link to={url_to} className="btn btn-grey">
                {this.text_button}
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeadBannersActiveSun;

import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { CookiesProvider } from "react-cookie";
import { Provider } from "mobx-react";

import { createBrowserHistory } from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";

import {
  URL_CATALOG,
  URL_CATALOG_COMP,
  URL_CATALOG_DRIVER,
  URL_CATALOG_DRIVER_SUN,
  URL_CATALOG_ACTIVE_SPG,
  URL_CATALOG_ACTIVE,
  URL_CATALOG_REHABIL,
  URL_CATALOG_REHABIL_SUN,
  URL_CATALOG_PINHOLE,
  URL_CATALOG_POLAR,
  URL_CATALOG_POLAR_BROWN,
  URL_CATALOG_MED,
  URL_CATALOG_OPTIKA,
  URL_DOWNLOADS,
  URL_FEEDBACK,
  URL_RULES,
  URL_ORDERING_STEP_FIRST,
  URL_ORDERING_STEP_SECOND,
  URL_ORDERING_WHOLESALE,
  URL_DEALERS,
  URL_FAQ,
  URL_NEWS,
  URL_PARTNERS,
  URL_ORDER_IS_PROCESSED,
} from "api";

import { Base, BaseLayout } from "components";

import {
  HomePage,
  NotFoundPage,
  MenuItemPage,
  CompanyPage,
  PrSpgPage,
  CatalogPage,
  CatalogMedDioptrPage,
  DealersPage,
  DownloadsPage,
  FeedbackPage,
  RulesPage,
  ProductPage,
  BasketPage,
  OrderingStepFirstPage,
  OrderingStepSecondPage,
  WholeSalePage,
  NewsPage,
  FAQPage,
  PartnersPage,
  OrderWholesaleIsProcessedPage
} from "pages";

import {
  accountStore,
  alertStore,
  layoutStore,
  menuStore,
  userStore,
  generalStore,
  catalogStore,
  paginatorStore,
  deliveryStore,
  basketStore,
  paymentStore,
} from "stores";
import OrderIsProcessedPage from "./pages/site/OrderIsProcessedPage";
import {URL_ORDERING_WHOLESALE_IS_PROCESSED} from "./api/constants";
import prettier from "prettier";
import {writeFileSync} from "fs";
import CatalogKompDioptrPage from "./pages/site/CatalogKompDioptrPage";
import CatalogDriverDioptrPage from "./pages/site/CatalogDriverDioptrPage";
import CatalogDriverSunDioptrPage from "./pages/site/CatalogDriverSunDioptrPage";
import CatalogSunDioptrPage from "./pages/site/CatalogSunDioptrPage";


const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = {
  account: accountStore,
  alert: alertStore,
  layout: layoutStore,
  storeMenu: menuStore,
  routing: routingStore,
  user: userStore,
  general: generalStore,
  storeCatalog: catalogStore,
  paginator: paginatorStore,
  storeDelivery: deliveryStore,
  basket: basketStore,
  payment: paymentStore,
};

const history = syncHistoryWithStore(browserHistory, routingStore);

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, []);
  return null;
};

const Routes = () => {
  return (
    <CookiesProvider>
      <Provider {...stores}>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            <Route
              path="/"
              render={(props) => {
                return (
                  <Base {...props}>
                    <BaseLayout {...props}>
                      <Switch>
                        <Route exact path="/404" component={NotFoundPage} />
                        <Route exact path="/" component={CatalogPage} />
                        <Route exact path="/cart" component={BasketPage} />
                        <Route exact path="/company" component={CompanyPage} />
                        <Route exact path="/spg" component={PrSpgPage} />
                        <Route
                          exact
                          path={URL_DEALERS}
                          component={DealersPage}
                        />
                        <Route exact path={URL_FAQ} component={FAQPage} />

                        <Route exact path={URL_NEWS} component={NewsPage} />
                        <Route
                          exact
                          path={URL_DOWNLOADS}
                          component={DownloadsPage}
                        />
                        <Route
                          exact
                          path={URL_FEEDBACK}
                          component={FeedbackPage}
                        />
                        <Route exact path={URL_RULES} component={RulesPage} />
                        <Route exact path="/clips/" component={CatalogPage} />
                        <Route
                          exact
                          path="/antifari_economy/"
                          component={CatalogPage}
                        />
                        <Route
                          exact
                          path="/accessories/"
                          component={CatalogPage}
                        />
                        <Route exact path={URL_CATALOG}>
                          <Redirect to={URL_CATALOG_COMP} />
                        </Route>
                        <Route exact path={URL_CATALOG_DRIVER}>
                          <Redirect to={URL_CATALOG_DRIVER_SUN} />
                        </Route>
                        <Route exact path={URL_CATALOG_ACTIVE_SPG}>
                          <Redirect to={URL_CATALOG_ACTIVE} />
                        </Route>
                        <Route exact path={URL_CATALOG_REHABIL}>
                          <Redirect to={URL_CATALOG_REHABIL_SUN} />
                        </Route>
                        <Route exact path={URL_CATALOG_POLAR}>
                          <Redirect to={URL_CATALOG_POLAR_BROWN} />
                        </Route>

                        <Route exact path={URL_CATALOG_OPTIKA}>
                          <Redirect to={URL_CATALOG_MED} />
                        </Route>
                        <Route
                            exact
                            path="/catalogue_computer_SPG/dioptr"
                            render={(props) => (
                                <CatalogKompDioptrPage
                                    {...props}
                                    dioptr={true}
                                    med={false}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/catalogue_rehabilitation_SPG_sun/dioptr"
                            render={(props) => (
                                <CatalogSunDioptrPage
                                    {...props}
                                    dioptr={true}
                                    med={false}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/catalogue_driving_SPG_sun/dioptr"
                            render={(props) => (
                                <CatalogDriverSunDioptrPage
                                    {...props}
                                    dioptr={true}
                                    med={false}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/catalogue_driving_SPG_night_bad_weather/dioptr"
                            render={(props) => (
                                <CatalogDriverDioptrPage
                                    {...props}
                                    dioptr={true}
                                    med={false}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/catalogue*/dioptr"
                            render={(props) => (
                                <CatalogMedDioptrPage
                                    {...props}
                                    dioptr={true}
                                    med={true}
                                />
                            )}
                        />
                        <Route
                          exact
                          path={URL_CATALOG_MED}
                          render={(props) => (
                            <CatalogMedDioptrPage
                              {...props}
                              dioptr={false}
                              med={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/catalogue*/"
                          component={CatalogPage}
                        />
                        <Route
                          exact
                          path="/product/*/"
                          component={ProductPage}
                        />
                        <Route
                          exact
                          path={URL_ORDERING_STEP_FIRST}
                          component={OrderingStepFirstPage}
                        />
                        <Route
                          exact
                          path={URL_ORDERING_STEP_SECOND}
                          component={OrderingStepSecondPage}
                        />
                        <Route
                          exact
                          path={URL_ORDERING_WHOLESALE}
                          component={WholeSalePage}
                        />
                        <Route
                            exact
                            path={URL_ORDER_IS_PROCESSED}
                            component={OrderIsProcessedPage}
                        />
                        <Route
                            exact
                            path={URL_ORDERING_WHOLESALE_IS_PROCESSED}
                            component={OrderWholesaleIsProcessedPage}
                        />
                        <Route exact path="*" component={MenuItemPage} />
                      </Switch>
                    </BaseLayout>
                  </Base>
                );
              }}
            />
          </Switch>
        </Router>
      </Provider>
    </CookiesProvider>
  );
};

// async function generate_sitemap(pages) {
//   const prettierConfig = await prettier.resolveConfig('./.prettierrc.js');
//
//
//   console.log(pages)
//   const sitemap = `
//     <?xml version="1.0" encoding="UTF-8"?>
//     <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
//         ${pages
//       .map((page) => {
//         const path = page
//             .replace('pages', '')
//             .replace('data', '')
//             .replace('.js', '')
//             .replace('.mdx', '');
//         const route = path === '/index' ? '' : path;
//
//         return `
//               <url>
//                   <loc>${`https://alis96.ru${route}`}</loc>
//               </url>
//             `;
//       })
//       .join('')}
//     </urlset>
//     `;
//
//   const formatted = prettier.format(sitemap, {
//     ...prettierConfig,
//     parser: 'html',
//   });
//
//   // eslint-disable-next-line no-sync
//   writeFileSync('public/sitemap.xml', formatted);
// }
//

export default Routes;

import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { rHTML } from 'services/helpers'

@inject('general')
@observer
class RegionShopsMenu extends React.Component {

  render() {
    const { general } = this.props
    const shops = general.getRegionShop(this.props.region);
    return (
      <React.Fragment>
        {shops &&
          shops.map((obj, index) => {

            return (
              <React.Fragment key={index}>
                <div className="metro_line">
                  <h2>{obj.city}</h2>
                  <p>{rHTML(obj.company)}
                    {obj.address}<br />{rHTML(obj.phone)}</p>
                </div>
              </React.Fragment>
            )
          })
        }
      </React.Fragment>
    )
  }
}

RegionShopsMenu.propTypes = {
  general: MPropTypes.observableObject,
}
export default RegionShopsMenu
import React from 'react'

import {
  URL_ABOUT_COMP,
  ZAGLUSHKA_BANNER_17_PIC,
  ZAGLUSHKA_BANNER_43_PIC,
} from "api";
import { Link } from "react-router-dom";
import { BaseHeadBanners } from "components"


class HeadBannersMedGlasses extends BaseHeadBanners {

  render() {
    const style_bigbanner = { backgroundImage: 'url(' + ZAGLUSHKA_BANNER_43_PIC + ')' }

    return (
      <React.Fragment>
        <div className="bigbanner color-black" style={style_bigbanner}>
          <div className="row">
            <div className="grid-20 md3 sm12">
              <Link to={URL_ABOUT_COMP}><img src={ZAGLUSHKA_BANNER_17_PIC} alt="" /></Link>
            </div>
            <div className="grid-80 md9 sm12">
              <div className="bigbanner_title black">ОЧКИ С ДИОПТРИЯМИ</div>
            </div>
          </div>
        </div>
      </React.Fragment>

    )
  }
}

export default HeadBannersMedGlasses
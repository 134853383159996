import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import InnerLayout from './InnerLayout';

const YandexMap = inject("storeDelivery")(
  observer(({ storeDelivery, showButtonInBalloon }) => {
    const [ymaps, setYmaps] = useState(null);
    const coordinates = (storeDelivery.activeLatitude && storeDelivery.activeLongitude) ? [Number(storeDelivery.activeLatitude), Number(storeDelivery.activeLongitude)] : [55.922081623973426, 37.87186717169545];

    const getLayout = (Component, props) => {
      if (ymaps) {
        const html = ReactDOMServer.renderToString(<Component {...props} />);
        const Layout = ymaps.templateLayoutFactory.createClass(
          `<div id="balloon" className="balloon-yandex-map">${html}</div>`,
          {
            build: function () {
              Layout.superclass.build.call(this);
              ReactDOM.hydrate(
                <Component {...props} />,
                document.getElementById('balloon'),
              );
            },
          },
        );
        return Layout;
      }
      return null;
    };

    const onBalloonClick = (pvz, event) => {
      event.preventDefault();
      storeDelivery.setActiveDeliveryPoint(pvz, false)
    }

    const mapData = {
      center: coordinates,
      zoom: storeDelivery.activeZoom,
    }

    return (
      <YMaps>
        <Map
          style={{ height: '400px', width: '100%' }}
          onLoad={ymaps => setYmaps(ymaps)}
          state={mapData}
          modules={['templateLayoutFactory', "layout.ImageWithContent", 'geoObject.addon.balloon', 'clusterer.addon.balloon']}
        >
          <ZoomControl />
          {storeDelivery.tmpDeliveryPoints &&
            storeDelivery.tmpDeliveryPoints.map((object, index) => {
              return (
                <Placemark
                  key={index}
                  modules={['geoObject.addon.balloon']}
                  geometry={[object.latitude, object.longitude]}
                  properties={{
                    iconCaption: object.namePoint,
                    item: object,
                    index
                  }}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: object.iconImageHref,
                    iconImageSize: object.iconImageSize,
                    balloonContentLayout: getLayout(InnerLayout, {
                      object,
                      index,
                      onBalloonClick,
                      showButtonInBalloon,
                    }),
                    // Не скрываем иконку при открытом балуне.
                    hideIconOnBalloonOpen: false,
                    // И дополнительно смещаем балун, для открытия над иконкой.
                    balloonOffset: [3, -40],
                    minHeight: 60,
                    minWidth: 400,
                  }}
                  instanceRef={ref => {
                    if (ref && object.active && (storeDelivery.activeDeliveryPoint?.addressSource ||
                        storeDelivery.activeDeliveryPoint?.Address ||
                        storeDelivery.activeDeliveryPoint?.location?.address)) {
                      ref.balloon.open();
                    }
                  }}
                />
              )
            }
            )
          }
        </Map>
      </YMaps>
    );
  })
);

export default YandexMap;

import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import { ShopItemMenu } from 'components'

@inject('general')
@observer
class MoscowShopsMenu extends React.Component {
    componentDidMount(){
        const {general} = this.props
        general.loadCompanyMoscowShops();
    }

    render() {
        const {general} = this.props
        return (
            <React.Fragment>
            { general.companyMoscowShops &&
                    general.companyMoscowShops.map((obj, index) =>{

                        return (
                            <React.Fragment key={index}>
                            <h3>{obj.key}</h3>
                                {obj.data && obj.data.map((item, index_shop) =>{
                                    return(<ShopItemMenu obj={item} key={index_shop}/>)
                                    }
                                )}
                            </React.Fragment>
                        )
                    })
            }
            </React.Fragment>
        )
    }
}

MoscowShopsMenu.propTypes = {
    general: MPropTypes.observableObject,
}
export default MoscowShopsMenu
import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Breadcrumb } from "components";
import "owl.carousel/dist/assets/owl.carousel.css";

import { headerCatalogPage, rHTML, rpHTML, renderHTML } from "services/helpers";

import { URL_CATALOG_COMP, ZAGLUSHKA_SLIDER_IN_COMP_PAGE } from "api";
import PdfLink from "../../components/site/PdfLink";

@inject("general")
@observer
class ComputerClassPage extends Component {
  render() {
    const { general } = this.props;
    let article_text = "";
    let banerHeader = headerCatalogPage(
      this.props.routing.location.pathname,
      true
    );

    if (general) {
      article_text = general.getLettering("126") + general.getLettering("127");
    }
    return (
      <React.Fragment>
        {general.page_object && (
          <div className="container">
            {banerHeader}
            <div className="breadcrumbs">
              <ol className="breadcrumbs_nav">
                <Breadcrumb breadcrumbs={general.page_object?.krohi} />
              </ol>
            </div>
            <div className="article last">
              <div className="article_head">
                <h1>{general.page_object?.h1}</h1>
              </div>
              {rHTML(article_text, "textcol-2 sm-textcol-1")}
            </div>

            <div className="landing_cover">
              {rHTML(general.getLettering("128"), "landing_cover_top")}
              <img src="/images/uploads/banner34.jpg" alt="" />
              {rHTML(general.getLettering("129"), "landing_cover_bottom")}
            </div>

            <div className="landing_icons">
              {rHTML(general.getLettering("123"), "landing_icons_title")}
              <div className="landing_icons_list">
                <div className="row">
                  <div className="grid-4 sm12 landing_icons_item">
                    <img src="/images/icons/comp_tablet.svg" alt="" />
                    {general.getLettering("130")}
                  </div>
                  <div className="grid-4 sm12 landing_icons_item">
                    <img src="/images/icons/comp_laptop.svg" alt="" />
                    {general.getLettering("131")}
                  </div>
                  <div className="grid-4 sm12 landing_icons_item">
                    <img src="/images/icons/comp_game_console.svg" alt="" />
                    {general.getLettering("132")}
                  </div>
                </div>
              </div>
              <div className="hr" />
              <div className="landing_icons_text">
                <div className="row">
                  <div className="grid-4 sm12 text-center sm-hidden">
                    <img
                      src="/images/icons/comp_med.svg"
                      alt=""
                      className="max_img"
                    />
                  </div>
                  <div className="grid-8 sm12">
                    {rpHTML(general.getLettering("133"))}
                    {rpHTML(general.getLettering("134"))}
                    {rpHTML(general.getLettering("135"))}
                    {rpHTML(general.getLettering("136"))}
                    {rpHTML(general.getLettering("137"))}
                    <Link to={URL_CATALOG_COMP} className="btn btn-blue">
                      {general.getLettering("138")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="landing_grey">
              {rHTML(general.getLettering("139"), "landing_grey_title")}
              {rpHTML(general.getLettering("140"))}
            </div>
            <div className="landing_slider">
              <OwlCarousel
                className={"swiper-container"}
                navClass={["swiper-button-prev", "swiper-button-next"]}
                dotsClass={"swiper-pagination swiper-pagination-bullets"}
                dotClass={"swiper-pagination-bullet"}
                navText={["", ""]}
                items={1}
                dots={true}
                nav={true}
                autoplay={true}
                loop={true}
              >
                {ZAGLUSHKA_SLIDER_IN_COMP_PAGE.map((obj, index) => {
                  let style_background = {
                    backgroundImage: "url(" + obj.picUrl + ")",
                  };
                  return (
                    <div
                      key={index}
                      className="swiper-slide"
                      style={style_background}
                    >
                      <img src={obj.picUrl} alt="" />
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>

            <div className="article">
              {rpHTML(general.getLettering("141"))}
              {rHTML(general.getLettering("142"), "check_text check_text-grey")}
              <PdfLink pdf_file={general?.getPdfFile(4)}/>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
ComputerClassPage.propTypes = {
  general: MPropTypes.observableObject,
};

export default ComputerClassPage;

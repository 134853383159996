import React, { Component } from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import {
  RightFilterBox,
  TypeMedLinz,
  ProductsPreview,
  Breadcrumb
} from 'components'
import {headerCatalogPage, rpHTML} from "../../services/helpers";

@inject('general', 'storeCatalog', 'routing')
@observer
class CatalogMedDioptrPage extends Component {

  componentDidMount() {
    const { general, storeCatalog, routing } = this.props
    const arg_dioptr = this.props.dioptr ? "dioptr=true&": ""
    const arg_med = this.props.med ? "med=true": ""
    const arg = this.props.dioptr || this.props.med? "?" : ""
    const url = routing.location.pathname.slice(0, -1) + arg + arg_dioptr + arg_med
    storeCatalog.pages = null;
    storeCatalog.products = null;
    general.loadPageContent(url+"/");
    storeCatalog.loadCatalogMenu(this.props.routing.location.pathname, this.props.dioptr || this.props.med)
    storeCatalog.clearBlueBlockAndPhotochrome()
  }

  componentDidUpdate(prevProps) {
    const {storeCatalog} = this.props
    storeCatalog.setSelectedParamsMedGlasses({ category: this.props.general.page_object?.id })
    const $ = window.$
    $('select[name="od"]').trigger("refresh");
    $('select[name="os"]').trigger("refresh");
    $('select[name="os_cyl"]').trigger("refresh");
    $('select[name="od_cyl"]').trigger("refresh");
    $('select[name="os_axis"]').trigger("refresh");
    $('select[name="od_axis"]').trigger("refresh");
    $('select[name="dp"]').trigger("refresh");
  }

  getItem() {
    const { general, storeCatalog } = this.props;
    storeCatalog.loadCatalogMenu(
        this.props.routing.location.pathname,
        this.props.dioptr || this.props.med
    );
    general.loadContentItem(this.props.routing.location.pathname, this.props.dioptr, this.props.med);
  }

  onClickSelectProduct = (event) => {
    if(this.props.med){
      this.props.storeCatalog.loadMedProducts()
    }
    if(this.props.dioptr){
      const { general } = this.props;
      general.loadContentItem(this.props.routing.location.pathname, this.props.dioptr, this.props.med);
    }
    event.preventDefault();
  }

  render() {
    const { storeCatalog, general } = this.props
    const banerHeader = headerCatalogPage(this.props.routing.location.pathname, "", this.props.dioptr || this.props.med)
    const show_blue_linz = this.props.routing.location.pathname === "/catalogue_computer_SPG/dioptr/"
    return (
      <div className="container">
        {banerHeader}
        <div className="breadcrumbs">
          <ol className="breadcrumbs_nav">
            <Breadcrumb breadcrumbs={general.page_object?.krohi} />
          </ol>
        </div>


        <div className="row">
          <div className="grid-20 md3 tb12">
            <RightFilterBox show_filter={false} />
          </div>

          {general.masterIsWorked?.isWorked &&
            <div className="grid-80 md9 tb12">

            <div className="pagetitle_header">
              <div className="pagetitle pagetitle-blue"><span>Очки с диоптриями</span></div>
              <div className="warning color-black">Очки с диоптриями изготавливаются по предоплате 100%.
              </div>
            </div>
            <div className="article">
              <TypeMedLinz
                  dioptr={this.props.dioptr}
                  med={this.props.med}
                  show_blue_linz={show_blue_linz}
                  location={this.props.routing.location}
              />
              <hr/>
              <div className="flexed_box">
                {rpHTML(this.props.general.getLettering("228"))}
                {<p><a className="btn btn-grey upper" onClick={this.onClickSelectProduct.bind()}>Подобрать очки</a></p>}
              </div>
            </div>

            {storeCatalog.products && <ProductsPreview med={true}/>}
          </div>
          }
          {!general.masterIsWorked?.isWorked &&
            <div className="grid-80 md9 tb12">
              <div className="pagetitle_header">
                <div className="pagetitle pagetitle-blue"><span>Очки с диоптриями</span></div>
                <div className="warning color-black">{general.masterIsWorked.message}</div>
              </div>
            </div>
          }
        </div>
      </div>

    )
  }
}
CatalogMedDioptrPage.propTypes = {
  general: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject,
}

export default CatalogMedDioptrPage

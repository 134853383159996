import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

import {
  URL_CATALOG,
  URL_ORDERING_STEP_FIRST,
  URL_ORDERING_WHOLESALE,
} from "api";
import { BasketItem } from "../../components";
import { Link } from "react-router-dom";
import {MIN_QNT_IN_BASKET_FOR_WHOLESALE} from "../../api/constants";

@inject("basket", "routing")
@observer
class BasketPage extends Component {
  componentDidMount() {
    document.title = "Корзина";
  }


  onClickBackToPurchases = (e) => {
    this.props.routing.history.goBack();
  };


  render() {
    const {basket} = this.props;
    if (basket.quantity === 0) {
      return (
          <div className="container">
            <div className="breadcrumbs">
              <ol className="breadcrumbs_nav">
                <li className="breadcrumb-item">
                  <Link to="/">Главная</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/cart">Корзина товаров</Link>
                </li>
              </ol>
            </div>

            <div className="page_header">
              <h1>В корзине нет товаров</h1>
              <Link to={URL_CATALOG} className="btn btn-grey upper">
                Вернуться в каталог
              </Link>
            </div>
          </div>
      );
    }
    return (
        <div className="container">
          <div className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/cart">Корзина товаров</Link>
              </li>
            </ol>
          </div>

          <div className="page_header">
            <h1>Корзина товаров</h1>
            <Link to={URL_CATALOG} className="btn btn-grey upper">
              Вернуться в каталог
            </Link>
          </div>

          <div className="basket_row basket_head">
            <div className="basket_cell">Наименование товара</div>
            <div className="basket_cell">Комплектация (выберите нужную)</div>
            <div className="basket_cell">
              <div className="basket_subcell basket_count">Количество</div>
              <div className="basket_subcell basket_price tb-hidden">Сумма</div>
            </div>
          </div>
          {console.log(basket.products)}
          {basket.products &&
          basket.products.map((item, index_pr) => {
            if(item.product && item.product.colr && item.qnt) {
              let key_item = String(item.product.id) + String(item.colr.id1) + String(item.colr.id2) + String(index_pr)
              return (
                  <BasketItem
                      key={key_item}
                      id={index_pr}
                      colr={item.colr}
                      type_equipment={item.colr.ktype}
                      id_color1={item.colr.id1}
                      id_color2={item.colr.id2}
                      color_css1={item.colr.css1}
                      color_css2={item.colr.css2}
                      product={item.product}
                      qnt={item.qnt}
                  />
              );
            }
          })}

          <div className="basket_total">
            <div className="basket_total_box basket_total_sum">
              Итого (без учета доставки): <b>{basket.amount} р.</b>
            </div>
            <div className="basket_total_box flexed">
              <Link to={URL_CATALOG} className="btn btn-grey">
                ПРОДОЛЖИТЬ ПОКУПКИ
              </Link>
              {basket.quantity > 0 && (
                  <Link
                      to={
                        basket.quantity < MIN_QNT_IN_BASKET_FOR_WHOLESALE
                            ? URL_ORDERING_STEP_FIRST
                            : URL_ORDERING_WHOLESALE
                      }
                      className="btn btn-red"
                  >
                    ОФОРМИТЬ ЗАКАЗ
                  </Link>
              )}
            </div>
          </div>
        </div>
    );
  }
}


BasketPage.propTypes = {
  basket: MPropTypes.observableObject,
};

export default BasketPage;

import React from "react";
import { Link } from "react-router-dom";

import {
  URL_ABOUT_ACTIVE_SUN,
  URL_CATALOG_ACTIVE_SUN,
  URL_ABOUT_ACTIVE_SPG,
  URL_CATALOG_ACTIVE,
} from "api";
import { BaseHeadBanners } from "components";

class HeadBannersDriversSun extends BaseHeadBanners {
  state = {
    linkToSunRelaxPage: this.props.buy
      ? URL_ABOUT_ACTIVE_SUN
      : URL_CATALOG_ACTIVE_SUN,
    linkToNightRelaxPage: this.props.buy
      ? URL_ABOUT_ACTIVE_SPG
      : URL_CATALOG_ACTIVE,
  };
  render() {
    const style_bigbanner = {
      backgroundImage: "url(/images/uploads/banner51.jpg)",
    };
    const url_to = this.props.buy ? URL_CATALOG_ACTIVE : URL_ABOUT_ACTIVE_SPG;

    return (
      <React.Fragment>
        <div className="bigbanner" style={style_bigbanner}>
          <div className="row">
            <div className="grid-20 md3 sm12">
              <Link to={URL_CATALOG_ACTIVE}>
                <img src="/images/uploads/banner21.jpg" alt="" />
              </Link>
            </div>
            <div className="grid-80 md9 sm12">
              <div className="bigbanner_title">
               ОЧКИ ДЛЯ АКТИВНОГО ОТДЫХА SPG
              </div>
              <ul className="bigbanner_tabs bigbanner_tabs-yellow">
                <li>
                    <Link to={this.state.linkToSunRelaxPage}>ЯСНАЯ ПОГОДА</Link>
                </li>
                <li className="active">
                  <Link to={this.state.linkToNightRelaxPage}>
                    НЕПОГОДА | НОЧЬ
                  </Link>
                </li>
              </ul>
              <p>Применение</p>
              <p>
                <img src="/images/svg/icon4.svg" alt="" />
                <img src="/images/svg/icon3.svg" alt="" />
                <img src="/images/svg/icon2.svg" alt="" />
              </p>
              <Link to={url_to} className="btn btn-grey">
                {this.text_button}
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeadBannersDriversSun;

import { makeObservable, computed, observable, action } from "mobx";
import {
  GeneralModel,
  LetteringsModel,
  PageModel,
  CountryModel,
} from "../models";
import { getAlertStore, getDeliveryStore } from "stores";
import {
  apiCall,
  FAQ_URL,
  COMPANY_EVENTS_URL,
  COMPANY_REGIONS_URL,
  COMPANY_SOCIALS_URL,
  COMPANY_REGION_SHOPS_URL,
  COMPANY_ONLINE_SHOPS_URL,
  NADPIS_URL,
  NEWS_URL,
  COMPANY_MOSCOW_URL,
  COMPANY_PITER_URL,
  COMPANY_DOWNLOAD_INSTRUCTION_URL,
  BANNERS_URL,
  URL_COUNTRIES,
  COMPANY_SETTINGS_URL,
  URL_SEND_DEALER_MSG,
  SHOP_MASTER_IS_WORKED,
  COMPANY_PARTNERS_URL,
  COMPANY_PDF_FILES_URL,
  URL_SEND_FEEDBACK_MSG,
  COMPANY_RULES_URL,
} from "api";

import { getTplNamePage } from "services/helpers";
import { getCatalogStore } from "stores";
import RulesModel from "../models/RulesModel";


class GeneralStore {
  constructor() {
    makeObservable(this);
  }

  @observable enableLoading = false;
  @observable page_object = null;
  @observable pageComponent = null;
  @observable companyEvents = null;
  @observable companyPartners = null;
  @observable companyRegions = null;
  @observable companyRegionShops = null;
  @observable companyMoscowShops = null;
  @observable companyPiterShops = null;
  @observable companyOnlineShops = null;
  @observable companyDownloadCatalog = null;
  @observable companyDownloadInstruction = null;
  @observable companySettings = null;
  @observable activeRegion = null;
  @observable activeDioptCatalog = false;
  @observable onlineShops = null;
  @observable letterings = null;
  @observable banners = null;
  @observable countries = null;
  @observable socials = [];
  @observable news = null;
  @observable news_pages = null;
  @observable showProducts = false;
  @observable faq = null;
  @observable pdf_files = null;
  @observable companyRules = null;

  @observable masterIsWorked = {
    isWorked: false,
    message:""
  };

  getBanners(banner_ids) {
    let result = this.banners?.filter((obj) => banner_ids.includes(obj.id));
    let arr = [];

    for (let obj of result) {
      let new_obj = new Object();
      Object.assign(new_obj, obj)
      new_obj.href = "/images/" + new_obj.href
      arr.push(new GeneralModel(new_obj));
    }
    return arr;
  }

  getBannerWithLettering(banner_id, lettering_code) {
    let result = this.banners?.filter((obj) => obj.id === banner_id);
    if (result.length > 0) {
      result = result[0];
      result.lettering = this.getLetteringObject(lettering_code);
    } else {
      result = { name: "", href: "" };
    }
    return result;
  }

  getCompanySetting(code) {
    if (this.companySettings === null) {
      return 0;
    }
    let result = this.companySettings.filter((obj) => obj.code == code);
    if (result.length > 0) {
      return result[0];
    } else {
      return 0;
    }
  }


  @action
  getMasterIsWorked = async () => {
    let result = await apiCall(SHOP_MASTER_IS_WORKED, "GET", {}, false);
    if (!result.isError) {
      this.masterIsWorked =new GeneralModel(result.data);
    }
  };


  @action
  loadPdfFiles = async () => {
    if (this.pdf_files) {
      return;
    }
    let result = await apiCall(COMPANY_PDF_FILES_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.pdf_files = arr;
    }
  };

  @action
  loadCompanyRules = async () => {
    if (this.companyRules) {
      return;
    }
    let result = await apiCall(COMPANY_RULES_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new RulesModel(obj));
      }
      this.companyRules = arr;
    }
  };

  @action
  loadCompanySettings = async () => {
    if (this.companySettings) {
      return;
    }
    let result = await apiCall(COMPANY_SETTINGS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companySettings = arr;
    }
  };

  @action
  loadCountries = async () => {
    if (this.countries) {
      return;
    }
    let result = await apiCall(URL_COUNTRIES, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new CountryModel(obj));
      }
      arr
        .sort((a, b) => {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
        })
        .sort((a, b) => {
          return a.withDelivery < b.withDelivery
            ? 1
            : a.withDelivery > b.withDelivery
            ? -1
            : 0;
        });
      this.countries = arr;
      const storeDelivery = getDeliveryStore();
      storeDelivery.setActiveCountryDelivery(arr[0]);
    }
  };

  @action
  loadFAQ = async () => {
    if (this.faq) {
      return;
    }
    let result = await apiCall(FAQ_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.faq = arr;
    }
  };

  @action
  setEnableLoading(value) {
    this.enableLoading = value;
  }


  @action
  setShowProducts(value) {
    this.showProducts = value;
  }

  @action
  loadBanners = async () => {
    if (this.banners) {
      return;
    }

    let result = await apiCall(BANNERS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.banners = arr;
    }
  };

  @action
  loadCompanyDownloadCatalog = async () => {
    let url = COMPANY_DOWNLOAD_INSTRUCTION_URL + "?cattype=1";
    let result = await apiCall(url, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companyDownloadCatalog = arr;
    }
  };

  @action
  loadCompanyDownloadInstructions = async () => {
    let url = COMPANY_DOWNLOAD_INSTRUCTION_URL + "?cattype=2";
    let result = await apiCall(url, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companyDownloadInstruction = arr;
    }
  };
  @action
  loadPageContent = async (url, with_page_component=true) => {
    let url_page = "/v1/page" + url.slice(0, -1);
    let result = await apiCall(url_page, "GET", {}, false);
    if (!result.isError) {
      this.page_object = new PageModel(result.data);
      if(with_page_component === true){
        this.pageComponent = getTplNamePage(this.page_object.tpl);
      }
      this.setTitlePage(this.page_object.title);
    } else{
      this.pageComponent = "NotFoundPage";
    }

    return result
  }
  @action
  loadContentItem = async (url, dioptr = false, med = false, dioptr_dp = 64) => {
    console.log(url, dioptr)
    let result = await this.loadPageContent(url)
    if (result) {
      const catalogStore = getCatalogStore();
      if (med) {
        await catalogStore.loadMedProducts()
      }else{
        await catalogStore.loadProducts({ category: this.page_object?.id, dioptr: dioptr, dioptr_dp:dioptr_dp });
      }
    }
  };

  @action
  setTitlePage(value) {
    document.title = value;
  }

  @action
  loadCompanyEvents = async () => {
    let result = await apiCall(COMPANY_EVENTS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companyEvents = arr;
    }
  };

  @action
  loadCompanyRegions = async () => {
    if (this.companyRegions) {
      return;
    }
    let result = await apiCall(COMPANY_REGIONS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data) {
        arr.push(new GeneralModel(obj));
      }
      this.companyRegions = arr;
    }
  };

  @action
  loadCompanyRegionShops = async (regionId) => {
    if (this.companyRegionShops) {
      return;
    }
    let result = await apiCall(COMPANY_REGION_SHOPS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companyRegionShops = arr;
    }
  };

  @action
  loadCompanyPiterShops = async (regionId) => {
    if (this.companyPiterShops) {
      return;
    }

    let result = await apiCall(COMPANY_PITER_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data) {
        arr.push(new GeneralModel(obj));
      }
      this.companyPiterShops = arr;
    }
  };

  @action
  loadCompanyMoscowShops = async (regionId) => {
    if (this.companyMoscowShops) {
      return;
    }

    let result = await apiCall(COMPANY_MOSCOW_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      let index = 0;
      for (let key in result.data) {
        arr[index] = { key: key, data: [] };
        result.data[key].map((obj) => {
          arr[index].data.push(new GeneralModel(obj));
        });
        ++index;
      }
      this.companyMoscowShops = arr;
    }
  };

  @action
  loadCompanyOnlineShops = async () => {
    if (this.companyOnlineShops) {
      return;
    }
    let result = await apiCall(COMPANY_ONLINE_SHOPS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companyOnlineShops = arr;
    }
  };

  @action
  setActiveRegion(regionId) {
    this.activeRegion = regionId;
  }
  @action
  setActiveDioptCatalog(value) {
    this.activeDioptCatalog = value;
  }

  @action
  loadLetterings = async (d) => {
    let result = await apiCall(NADPIS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = new Map();
      for (let obj of result.data.results) {
        arr.set(obj.code, new LetteringsModel(obj));
      }
      this.letterings = arr;
    }
  };

  @action
  loadSocials = async () => {
    if (this.socials.length !== 0) return;
    let result = await apiCall(COMPANY_SOCIALS_URL, "GET", {}, false);
    if (!result.isError) {
      // filter active social and add address icon
      let socials = result.data.results.filter((social) => social.active);
      socials.forEach(
        (social) => (social.icon = `/images/svg/${social.title}.svg`)
      );
      this.socials = socials;
    }
  };

  @action
  loadPartners = async () => {
    if (this.companyPartners) return;
    let result = await apiCall(COMPANY_PARTNERS_URL, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.companyPartners = arr;
    }
  };

  @action
  getLettering(code) {
    const obj = this.getLetteringObject(code);
    if (!obj) {
      return "";
    }
    return obj.value;
  }

  getLetteringObject(code) {
    if (!this.letterings || !this.letterings.has(code)) {
      return null;
    }

    return this.letterings.get(code);
  }

  @action
  getRegionShop(region) {
    if (!this.companyRegionShops) {
      return [];
    }
    return this.companyRegionShops.filter((obj) => obj.region === region);
  }

  @action
  getPdfFile(id) {
    if (!this.pdf_files) {
      return null;
    }
    let result = this.pdf_files.filter((obj) => obj.id === id);
    if(result.length === 0)
      return null
    else
      return result[0]
  }

  @computed
  get mainBanners() {
    const codes = ["008", "007", "006", "004", "005", "009"];
    let result = [];
    let index = 0;
    if (this.banners) {
      result = this.banners.filter((obj) =>
        [1, 2, 3, 4, 5, 6].includes(obj.id)
      );
      for (let obj of result) {
        obj.lettering = this.getLetteringObject(codes[index]);
        ++index;
      }
    }
    return result;
  }


  @computed
  get headBannersKomp() {
    return this.getBannerWithLettering(18, "001");
  }

  @computed
  get headBannersGames() {
    return this.getBannerWithLettering(75, "001");
  }

  @computed
  get leftMainBanner() {
    return this.getBannerWithLettering(41, "002");
  }

  @computed
  get rightMainBanner() {
    return this.getBannerWithLettering(40, "003");
  }

  @action
  sendFeedbackMsg = async (data) => {
    const store = getAlertStore();

    let url = URL_SEND_FEEDBACK_MSG;
    this.errors = null;
    let result = await apiCall(url, "POST", data, false);
    if (result.isError) {
      this.errors = result.data;
      store.showResultErrors(result);
    } else {
      store.createAlert("Ваше сообщение будет обработано в ближайшее время!");
    }
  };

  @action
  sendDealerMsg = async (data) => {
    const store = getAlertStore();

    let url = URL_SEND_DEALER_MSG;
    this.errors = null;
    let result = await apiCall(url, "POST", data, false);
    if (result.isError) {
      this.errors = result.data;
      store.showResultErrors(result);
    } else {
      store.createAlert("Ваше сообщение будет обработано в ближайшее время!");
    }
  };

  @action
  loadNews = async (url = NEWS_URL) => {
    let result = await apiCall(url, "GET", {}, false);
    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new GeneralModel(obj));
      }
      this.news_pages = result.data.pages;
      this.news = arr;
    }
  };
}



const generalStore = new GeneralStore();

export default generalStore;

import { Link } from 'react-router-dom'
import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { rHTML } from "services/helpers";


@inject("general", "routing")
@observer
class FAQPage extends Component {
  componentDidMount() {
    this.props.general.loadFAQ();
    this.props.general.setTitlePage('Часто задаваемые вопросы');
  }

  render() {
    const {general} = this.props;

    return (
        <div className="container">

          <div className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="#">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</a>
              </li>
            </ol>
          </div>

          <h1>FAQ</h1>
          {general.faq && general.faq.map((item, index) => {
            return (
                <div className="article" key={index}>
                  <h3>{item.title}</h3>
                  {rHTML(item.body)}
                  <img src={"/media"+item.showpic} alt="faq"/>
                </div>
            )
          })
          }
        </div>
    );
  }
}

FAQPage.propTypes = {
  general: MPropTypes.observableObject,
};

export default FAQPage;

import React, { Component, setState } from "react";
import { Link } from "react-router-dom";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { AddressSuggestions } from "react-dadata";
import { withCookies, Cookies } from "react-cookie";
import "react-dadata/dist/react-dadata.css";
import { Loading, Select, SelectDeliveryPoints, YandexMap } from "components";
import { instanceOf } from "prop-types";
import { rpHTML } from "services/helpers";
import { URL_ORDERING_STEP_SECOND } from "api";

@inject("general", "storeDelivery", "basket")
@observer
class OrderingStepFirstPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: this.props.storeDelivery.activeCountryDelivery?.id || 1,
      // addressOutRussia: !isRussia ? (addressOutRussia || '') : ''
      addressOutRussia:
        !this.props.storeDelivery.activeCountryDelivery?.withDelivery &&
        this.props.storeDelivery.activeCountryDelivery?.methodDelivery
          ? this.props.storeDelivery.activeDeliveryPoint?.address || ""
          : "",
    };
  }

  componentDidMount() {
    const $ = window.$;
    // $('select[name="chooseCountry"]').styler({
    //   onSelectClosed: this.onChangeCountry.bind(),
    // });
    // $('select[name="selectDP"]')
    //   .styler({ onSelectClosed: this.onChangeDeliveryPoint.bind() })
    //   .trigger("refresh");
    const { general, storeDelivery } = this.props;
    general.setTitlePage("Оформление заказа");
    general.loadCountries();

    if (storeDelivery.activeDeliveryPoint)
      $("#input-delivery-point .jq-selectbox__select").css({
        border: "1px solid #01826a",
      });
    else
      $("#input-delivery-point .jq-selectbox__select").css({
        border: "1px solid #ff0000",
      });
  }

  componentDidUpdate(prevProps) {
    const $ = window.$;
    const { storeDelivery } = this.props;

    // $('select[name="chooseCountry"]').styler({
    //   onSelectClosed: this.onChangeCountry.bind(),
    // });
    // $('select[name="selectDP"]')
    //   .styler({ onSelectClosed: this.onChangeDeliveryPoint.bind() })
    //   .trigger("refresh");
    if (storeDelivery.activeDeliveryPoint)
      $("#input-delivery-point .jq-selectbox__select").css({
        border: "1px solid #01826a",
      });
    else
      $("#input-delivery-point .jq-selectbox__select").css({
        border: "1px solid #ff0000",
      });
  }

  // edit country
  onChangeCountry = (val) => {
    const { storeDelivery } = this.props;
    const $ = window.$;
    let numberCountry = val;
    console.log(val)
    storeDelivery.setActiveCountryDeliveryID(numberCountry);
    this.setState({ country: numberCountry });

    let activeDeliveryMethod = numberCountry > 1 ? storeDelivery.DPD : null;
    let activeDeliveryPoint =
      numberCountry > 1 ? { cost: "396 руб.", period: "10-28 дней" } : null;

    storeDelivery.setActiveDeliveryMethod(activeDeliveryMethod);
    storeDelivery.setActiveDeliveryPoint(activeDeliveryPoint, false);
    storeDelivery.setActiveDeliveryPointCost(activeDeliveryPoint);
  };

  // edit AddressSuggestions
  onChangeAddress = (data) => {
    const { storeDelivery } = this.props;
    storeDelivery.setActiveDeliveryMethod(null);
    storeDelivery.setActiveDeliveryPoint(null, false);
    storeDelivery.setDadataAddress(data);
    storeDelivery.getLocationAddress(data.value, true);
  };

  // edit address of countries
  onChangeAddressOutRussia = (e) => {
    this.setState({
      addressOutRussia: e.target.value.replace(/[а-яА-Я]/, ""),
    });
  };

  onSetActiveAddress = () => {
    // !isRussia
    if (
      !this.props.storeDelivery.activeCountryDelivery?.withDelivery &&
      this.props.storeDelivery.activeCountryDelivery?.methodDelivery
    ) {
      this.props.storeDelivery.setActiveDeliveryPoint(
        this.state.addressOutRussia,
        false,
        false
      );
      this.props.storeDelivery.setDadataAddress(this.state.addressOutRussia);
    }
  };

  // select payment method
  onChangeChoicePaymentMethod = (value) => {
    if (this.props.storeDelivery.activeCountryDelivery?.withDelivery) {
      this.props.storeDelivery.setActiveDeliveryPoint(null, false);
      this.props.storeDelivery.setValueChoicePaymentMethod(value);
    }
  };

  onChangeDeliveryPoint = (val) => {
    const { storeDelivery } = this.props;
    storeDelivery.setActiveDeliveryPoint(
      storeDelivery.tmpDeliveryPoints.filter(
        (obj) => obj._code == val
      )[0]
    );
  };

  render() {
    const { storeDelivery, basket, general } = this.props;
    console.log(storeDelivery.tmpDeliveryPoints, storeDelivery.activeDeliveryPoint)
    const showMap =
      storeDelivery.renderMap &&
      (storeDelivery.activeDeliveryMethod === storeDelivery.CDEK ||
        storeDelivery.activeDeliveryMethod === storeDelivery.CDEK_PRIM ||
        storeDelivery.activeDeliveryMethod === storeDelivery.BOXBERRY ||
        storeDelivery.activeDeliveryMethod === storeDelivery.POST ||
        storeDelivery.activeDeliveryMethod === storeDelivery.POST_FREE ||
        storeDelivery.activeDeliveryMethod === storeDelivery.DPD);

    const showTextAfterMap =
      (storeDelivery.activeDeliveryMethod >= 0 &&
        storeDelivery.activeDeliveryPoint) ||
      storeDelivery.activeDeliveryMethod === storeDelivery.CDEK_COURIER ||
      storeDelivery.activeDeliveryMethod === storeDelivery.POST_COURIER ||
      storeDelivery.activeDeliveryMethod === storeDelivery.BOXBERRY_COURIER;
    const isRussia =
      storeDelivery.activeCountryDelivery?.withDelivery ||
      !storeDelivery.activeCountryDelivery?.methodDelivery;
    let disabledButtonNext = storeDelivery.activeDeliveryMethod === null;
    if (
      (storeDelivery.activeDeliveryMethod === storeDelivery.CDEK ||
        storeDelivery.activeDeliveryMethod === storeDelivery.CDEK_PRIM ||
        storeDelivery.activeDeliveryMethod === storeDelivery.BOXBERRY ||
        storeDelivery.activeDeliveryMethod === storeDelivery.POST ||
        storeDelivery.activeDeliveryMethod === storeDelivery.POST_FREE ||
        storeDelivery.activeDeliveryMethod === storeDelivery.DPD) &&
      isRussia
    ) {
      disabledButtonNext = storeDelivery.activeDeliveryPoint === null;
    }

    const styleButtonNext = disabledButtonNext
      ? "disabled btn upper"
      : "btn btn-red upper";

    if (basket.quantity == 0) {
      return (
        <React.Fragment>
          <div className="container container_middle">
            <div className="breadcrumbs">
              <ol className="breadcrumbs_nav">
                <li className="breadcrumb-item">
                  <Link to="/">Главная</Link>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Оформление заказа</a>
                </li>
              </ol>
            </div>

            <div className="page_header">
              <h1>Корзина пуста</h1>
              <Link to={""} className="btn btn-grey upper">
                ПРОДОЛЖИТЬ ПОКУПКИ
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container container_middle">
          <div className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Оформление заказа</a>
              </li>
            </ol>
          </div>

          <div className="page_header">
            <h1>Оформление заказа</h1>
            <Link to="/cart" className="btn btn-grey upper">
              Вернуться в корзину
            </Link>
          </div>

          <h3 className="uppercase">Доставка</h3>

          <div className="row">
            <div className="grid-4 tb6 sm12">
              <div className="input-field">
                <label>Страна</label>
                {general.countries && (
                  <Select
                    id="choose_country"
                    name="chooseCountry"
                    className="selectbox"
                    options={general.countries}
                    value={this.state.country}
                    isValueFromParent={true}
                    onChange={this.onChangeCountry.bind()}
                  />
                )}
              </div>
            </div>
          </div>
          {storeDelivery.activeCountryDelivery?.withDelivery && (
            <div className="row row_fields our_country_box">
              <div className="input-field grid-10 sm8">
                <label>
                  Адрес (город, улица, дом):
                  {storeDelivery.addressLocation &&
                    storeDelivery.addressLocation.house == null && (
                      <span className="red"> Выберите дом!</span>
                    )}
                </label>
                <AddressSuggestions
                  token="a101cdd086f3070d1e38bff67fe794abf81ea22a"
                  inputProps={{ placeholder: "Начните вводить адрес" }}
                  onChange={this.onChangeAddress}
                  value={storeDelivery.dadataAddress}
                />
                {/*<div className="input-text">по данному адресу индекс не определен</div> */}
              </div>
            </div>
          )}
          {storeDelivery.activeCountryDelivery &&
            !storeDelivery.activeCountryDelivery?.withDelivery && (
              <div className="row_fields other_country_box">
                <div className="input-field">
                  <label>
                    Введите ваш полный адрес с индексом латинскими буквами:
                  </label>
                  <input
                    type="text"
                    className="input"
                    onChange={this.onChangeAddressOutRussia}
                    value={this.state.addressOutRussia}
                    placeholder="Например: flat 9, 120 Clarendon Road, Southsea, Hampshire, PO4 0SF, UK "
                    // pattern={'|^[A-Z0-9]+$|i'}
                  />
                </div>
              </div>
            )}
          {((storeDelivery.addressLocation &&
            storeDelivery.activeCountryDelivery?.withDelivery) ||
            !storeDelivery.activeCountryDelivery?.withDelivery) && (
            <div className="form_after_complete">
              <div className="forms_box">
                <h3>Выберите способ оплаты:</h3>
                <div className="choose_row">
                  <label className="choose_btn">
                    <input
                      type="radio"
                      name="pay_method"
                      checked={
                        storeDelivery.valueChoicePaymentMethod === storeDelivery.PREPAYMENT
                          ? "checked"
                          : ""
                      }
                      onChange={this.onChangeChoicePaymentMethod.bind(
                        null,
                        storeDelivery.PREPAYMENT
                      )}
                    />
                    <span>Предоплата</span>
                  </label>
                  {storeDelivery.activeCountryDelivery && (
                    <label
                      className="choose_btn"
                      style={{
                        opacity: !storeDelivery.activeCountryDelivery
                          ?.withDelivery
                          ? ".5"
                          : "1",
                      }}
                    >
                      {storeDelivery.activeCountryDelivery?.withDelivery && (
                        <input
                          type="radio"
                          name="pay_method"
                          checked={
                            storeDelivery.valueChoicePaymentMethod ===
                            storeDelivery.PAYMENT_UPON_RECEIPT
                              ? "checked"
                              : ""
                          }
                          onChange={this.onChangeChoicePaymentMethod.bind(
                            null,
                            storeDelivery.PAYMENT_UPON_RECEIPT
                          )}
                        />
                      )}
                      <span>Оплата при получении</span>
                    </label>
                  )}
                </div>
              </div>
              {(storeDelivery.deliveryPointCDEKCost ||
                storeDelivery.deliveryPointPostCost ||
                storeDelivery.deliveryPointBoxberryCost ||
                !isRussia) && (
                <div className="forms_box">
                  <h3>Выберите способ доставки:</h3>
                  <SelectDeliveryPoints showSelect={true} isRussia={isRussia} />
                </div>
              )}
              {showMap &&
                storeDelivery.tmpDeliveryPoints &&
                storeDelivery.tmpDeliveryPoints.length > 0 &&
                storeDelivery.activeCountryDelivery?.withDelivery && (
                  <div className="our_country_box">
                    <div id="input-delivery-point" className="input-field">
                      <Select
                        name="selectDP"
                        className="selectbox"
                        options={storeDelivery.tmpDeliveryPoints}
                        onChange={this.onChangeDeliveryPoint.bind()}
                        isValueFromParent={true}
                        value={
                          storeDelivery.activeDeliveryPoint
                            ? storeDelivery.activeDeliveryPoint.value
                            : -1
                        }
                        emptyChoiceText="Выберите пункт выдачи"
                      />
                    </div>
                  </div>
                )}
              {showMap && storeDelivery.activeCountryDelivery?.withDelivery && (
                <div className="our_country_box">
                  <div className="forms_section_map">
                    <YandexMap showButtonInBalloon={true} />
                  </div>
                </div>
              )}
              {showTextAfterMap &&
                rpHTML(general.getLettering("257"), "article")}

              <div className="forms_section_bottom">
                {!disabledButtonNext ? (
                  <Link
                    to={URL_ORDERING_STEP_SECOND}
                    className={styleButtonNext}
                    onClick={this.onSetActiveAddress}
                  >
                    Далее
                  </Link>
                ) : (
                  <span className={styleButtonNext}>Далее</span>
                )}
              </div>
            </div>
          )}
        </div>

        {(storeDelivery.renderMap = true)}
      </React.Fragment>
    );
  }
}
OrderingStepFirstPage.propTypes = {
  basket: MPropTypes.observableObject,
  general: MPropTypes.observableObject,
  storeDelivery: MPropTypes.observableObject,
  cookies: instanceOf(Cookies).isRequired,
};

export default withCookies(OrderingStepFirstPage);

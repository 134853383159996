import React from 'react'

import {
    URL_CATALOG_COMP,
    URL_ABOUT_COMP,
    ZAGLUSHKA_BANNER_18_PIC,
    ZAGLUSHKA_BANNER_47_PIC,
    ZAGLUSHKA_TABLET_SVG,
    ZAGLUSHKA_LAPTOP_SVG,
    ZAGLUSHKA_GAMEPAD_SVG,
} from "api";
import {Link} from "react-router-dom";
import { BaseHeadBanners } from "components"


class HeadBannersKomp extends BaseHeadBanners {

    render() {
        const style_bigbanner = { backgroundImage: 'url(' + ZAGLUSHKA_BANNER_47_PIC + ')'}
        const url_to = (this.props.buy)? URL_CATALOG_COMP: URL_ABOUT_COMP

        return (
            <React.Fragment>
                <div className="bigbanner" style={style_bigbanner}>
                    <div className="row">
                        <div className="grid-20 md3 sm12">
                            <Link to={URL_ABOUT_COMP}><img src={ZAGLUSHKA_BANNER_18_PIC} alt=""/></Link>
                        </div>
                        <div className="grid-80 md9 sm12">
                            <div className="bigbanner_title">Компьютерные очки SPG</div>
                            <p>Применение</p>
                            <p><img src={ZAGLUSHKA_TABLET_SVG} alt=""/>
                                <img src={ZAGLUSHKA_LAPTOP_SVG} alt=""/>
                                <img src={ZAGLUSHKA_GAMEPAD_SVG} alt=""/>
                            </p>
                            <Link to={url_to} className="btn btn-grey">{this.text_button}</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default HeadBannersKomp
import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import {
  RangesMedGlasses,
  TableTypeMedLinz,
  ChoiceOptionsMedLinz
} from 'components/index'
import {renderHTML, rHTML, rpHTML} from "../../../../services/helpers";
import RangesDioptrGlasses from "./RangesDioptrGlasses";


@inject('general', 'storeCatalog', 'routing')
@observer
class TypeMedLinz extends Component {

  render() {
    const {general} = this.props
    return (
        <React.Fragment>
            {this.props.med &&
            <RangesMedGlasses
                dioptr={this.props.dioptr}
                med={this.props.med}
                location={this.props.routing.location}
            />
            }
            {!this.props.med &&
            <RangesDioptrGlasses
                dioptr={this.props.dioptr}
                med={this.props.med}
                location={this.props.routing.location}
            />
            }
              {this.props.dioptr &&
                  <div className="table_row">
                      <div className="row">
                          {this.props.show_blue_linz &&
                              <React.Fragment>
                                  <div className="grid-5 sm12">
                                      {rpHTML(general.getLettering("225"))}
                                  </div>
                                  <div className="grid-7 sm12">
                                      <img src="https://www.alis96.ru/files/media/banners/banner71.jpg" alt=""
                                           className="alignright sm-alignnone"/>
                                      {rpHTML(general.getLettering("226"))}
                                  </div>
                              </React.Fragment>
                          }
                          {!this.props.show_blue_linz &&
                              <div className="grid-12 sm12">
                                      {rpHTML(general.getLettering("225"))}
                              </div>
                          }
                      </div>
                  </div>
              }
              {this.props.med &&
                  <div>
                      {rpHTML(general.getLettering("262"))}
                      <TableTypeMedLinz />
                      <ChoiceOptionsMedLinz />
                      <blockquote>
                          {rpHTML(general.getLettering("260"), )}
                      </blockquote>
                  </div>
              }
        </React.Fragment>

    )
  }
}

TypeMedLinz.propTypes = {
    general: MPropTypes.observableObject,
    routing: MPropTypes.observableObject,
    storeCatalog: MPropTypes.observableObject,

}

export default TypeMedLinz
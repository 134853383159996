import { makeObservable, computed, observable, action } from 'mobx'

import { GeneralModel, } from 'models'

import {
    apiCall,
} from 'api'

class AccountStore {

}

const accountStore = new AccountStore()
export default accountStore

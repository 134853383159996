import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { withCookies, Cookies } from 'react-cookie';
import { RowPropertyMedLinz } from 'components'
import { instanceOf } from "prop-types";
import {rHTML} from "../../../../services/helpers";


@inject('storeCatalog', "general")
@observer
class TableTypeMedLinz extends Component {
  images_linz = [
    'lens.svg', 'lens-mid.svg', 'lens-thin.svg'
  ]

  onSelectedMedTypeLinz = (typeLinz) => {
    const { cookies } = this.props;
    this.props.storeCatalog.setSelectedMedTypeLinz(typeLinz.id)
    cookies.set('select_type_linz_id', typeLinz.id, { path: '/' });
  }

  componentDidMount() {
    const { storeCatalog } = this.props
    storeCatalog.loadMedTypeLinz()
    storeCatalog.loadMedPropertiesLinz()
  }

  render() {
    const { storeCatalog, general } = this.props
    if (!storeCatalog.listMedTypeLinz || !storeCatalog.listMedPropertiesLinz) {
      return ''
    }
    return (
      <div className="optiktable_scroll">
        <table className="optiktable_choose">
          <thead>
            <tr>
              <th></th>
              {storeCatalog.listMedTypeLinz.map((obj, index) => {
                if ( storeCatalog.recommendedMedTypeLinz == obj) {
                  return (
                    <th key={index} className="td_select clickable">
                      <b>Рекомендуем </b>
                      <div className="question_icon">
                        <div className="tooltip tooltip-center">
                          {rHTML(general.getLettering("265"))}
                        </div>
                      </div>
                    </th>
                  )
                } else {
                  return (<th key={index}></th>)
                }
              }

              )}

            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="optiktable_choose_title"></td>
              {storeCatalog.listMedTypeLinz.map((obj, index) => {
                const className = (storeCatalog.selectedMedTypeLinz == obj) ? "optiktable_choose_head td_select clickable" : "optiktable_choose_head clickable";
                return (<td key={index} className={className} onClick={this.onSelectedMedTypeLinz.bind(null, obj)}>{obj.name}</td>)
              })
              }
            </tr>
            <tr>
              <td className="optiktable_choose_title"></td>

              {storeCatalog.listMedTypeLinz.map((obj, index) => {
                const className = (storeCatalog.selectedMedTypeLinz == obj) ? "optiktable_choose_imgs td_select clickable" : "optiktable_choose_imgs clickable";
                return (
                  <React.Fragment key={index}>
                    <td className={className} onClick={this.onSelectedMedTypeLinz.bind(null, obj)}>
                      <img src={"/images/svg/" + this.images_linz[index]} alt="" />
                      {obj.description && <p>{obj.description}</p>}
                    </td>
                  </React.Fragment>
                )
              })
              }
            </tr>
            <tr>
              <td className="optiktable_choose_title">Материал линз</td>
              {storeCatalog.listMedTypeLinz.map((obj, index) => {
                const className = (storeCatalog.selectedMedTypeLinz == obj) ? "td_select clickable" : "clickable";
                return (<td key={index} className={className} onClick={this.onSelectedMedTypeLinz.bind(null, obj)}>{obj.strMaterial}</td>)
              })
              }
            </tr>
            {storeCatalog.listMedPropertiesLinz.map((property, index) => {
              return (<RowPropertyMedLinz key={index} property={property} />)
            })}

            <tr>
              <td className="optiktable_choose_title"></td>
              {storeCatalog.listMedTypeLinz.map((obj, index) => {
                const className = (storeCatalog.selectedMedTypeLinz == obj) ? "optiktable_choose_price td_select clickable" : "optiktable_choose_price clickable";
                const increase = (obj.increase) ? obj.increase : "Базовая цена";
                return (<td key={index} className={className} onClick={this.onSelectedMedTypeLinz.bind(null, obj)}>{"+" + increase + " р."}</td>)
              })
              }

            </tr>
          </tbody>
        </table>

      </div>
    )
  }
}

TableTypeMedLinz.propTypes = {
  storeCatalog: MPropTypes.observableObject,
  cookies: instanceOf(Cookies).isRequired
}
export default withCookies(TableTypeMedLinz)
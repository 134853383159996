import { Accordion } from 'components'
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'



@inject("general")
@observer
class RulesPage extends Component {
  constructor() {
    super();
    this.state = {
      selectId: null
    }
  }
  componentDidMount() {
    const {general} = this.props
    this.props.general.setTitlePage('Правила продажи')
    this.props.general.loadCompanyRules()

  }

  toggle = (id) => {
    if (this.state.selectId == id) {
      this.setState({selectId:null});
      return;
    }
    this.setState({selectId:id});
  }

  render() {
    const { general } = this.props

    return(
    <div className="container">

      <div className="breadcrumbs">
        <ol className="breadcrumbs_nav">
          <li className="breadcrumb-item">
            <Link to="/">Главная</Link>
          </li>
          <li className="breadcrumb-item">
            <a href="#">Правила продажи</a>
          </li>
        </ol>
      </div>

      <h1>Правила продажи</h1>

      <div className="spoilerbox_row">
        {general?.companyRules && general.companyRules.map(accordion =>
            <Accordion
                key={accordion.id}
                id={accordion.id}
                selectId={this.state.selectId}
                toggle={this.toggle}
                title={accordion.title}
                content={accordion.content} />
        )}
      </div>

    </div>
    )
  }
}


RulesPage.propTypes = {
  general: MPropTypes.observableObject,
}

export default RulesPage

import { makeObservable, observable, action } from 'mobx'
import uuid from 'node-uuid'


/**
 * types: success, danger
 */
class AlertStore{
	constructor(){
		makeObservable(this)
	}

	@observable alerts = new Map()

	@action
	createAlert(text, type='success', isAutoClose=true, autoCloseTime=5000){
		let obj = {
			type: type,
			text: text,
			isAutoClose: isAutoClose,
		}

		const guid = uuid.v4()

		if (isAutoClose) {
			setTimeout(() => {
				this.alerts.delete(guid)
			}, autoCloseTime)
		}

		this.alerts.set(guid, obj)
	}

	@action
	showResultErrors = (result) => {
		if(result.data.errors){
            for(let text of result.data.errors){
                this.createAlert(text, 'danger')
            }
        }
	}

	deleteAlert(guid){
		this.alerts.delete(guid)
	}

	clearAllAlerts = () => {
		this.alerts = new Map()
	}
}

const alertStore = new AlertStore();
export default alertStore
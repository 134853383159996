import axios from "axios";
import axiosCookieJarSupport from "axios-cookiejar-support";
// import tough from "tough-cookie";

import Cookies from "universal-cookie";
import { getUserStore, getGeneralStore } from "stores";
import { logGroupMessage, logMessage } from "services/helpers";

import {
  URL_DEVELOPMENT,
  URL_PRODUCT,
  URL_CATALOG,
  URL_CATALOG_COMP,
  URL_CATALOG_DIOPTR,
  URL_CATALOG_GAMER,
  URL_CATALOG_DRIVER,
  URL_CATALOG_DRIVER_SUN,
  URL_CATALOG_DRIVER_NIGHT,
  URL_CATALOG_ACTIVE,
  URL_CATALOG_ACTIVE_SUN,
  URL_CATALOG_ACTIVE_SPG,
  URL_CATALOG_REHABIL,
  URL_CATALOG_REHABIL_SUN,
  URL_CATALOG_REHABIL_SUN_GRAD,
  URL_CATALOG_POLAR,
  URL_CATALOG_POLAR_BROWN,
  URL_CATALOG_POLAR_GREEN,
  URL_CATALOG_POLAR_GREY,
  URL_CATALOG_CLIPS,
  URL_CATALOG_PINHOLE,
  URL_CATALOG_ANTIFARI,
  URL_CATALOG_ACCESSORIES,
  URL_CATALOG_OPTIKA,
  URL_CATALOG_MED,
  URL_ORDERING_STEP_FIRST,
  URL_ORDERING_STEP_SECOND,
  URL_ORDERING_WHOLESALE,
  URL_DEALERS,
  URL_FAQ,
  URL_NEWS,
  URL_ORDER_IS_PROCESSED,
  URL_PARTNERS,
  URL_RULES,
  URL_ABOUT_COMP,
  URL_ABOUT_ACTIVE_SUN,
  URL_ABOUT_ACTIVE_SPG,
  URL_ABOUT_DRIVER_SUN,
  URL_ABOUT_DRIVER_NIGHT,
  URL_ABOUT_REHABIL,
  URL_ABOUT_POLAR,
  URL_ABOUT_PINHOLE,
  URL_ABOUT_ANTIFARI,
  URL_DOWNLOADS,
  URL_FEEDBACK,
  ZAGLUSHKA_LOGO_PIC,
  ZAGLUSHKA_DIOPTR_PIC,
  ZAGLUSHKA_POLAR_PIC,
  ZAGLUSHKA_SLIDER_IN_HOME,
  ZAGLUSHKA_SLIDER_IN_COMP_PAGE,
  ZAGLUSHKA_SLIDER_IN_ACTIVE_SUN_PAGE,
  ZAGLUSHKA_SLIDER_IN_ACTIVE_SPG_PAGE,
  ZAGLUSHKA_SLIDER_IN_DRIVER_SUN_PAGE,
  ZAGLUSHKA_SLIDER_IN_DRIVER_NIGHT_PAGE,
  ZAGLUSHKA_SLIDER_IN_REHABIL_PAGE,
  ZAGLUSHKA_SLIDER_IN_POLAR_PAGE,
  ZAGLUSHKA_SLIDER_IN_DEALERS_PAGE,
  ZAGLUSHKA_BANNER_17_PIC,
  ZAGLUSHKA_BANNER_18_PIC,
  ZAGLUSHKA_BANNER_20_PIC,
  ZAGLUSHKA_BANNER_43_PIC,
  ZAGLUSHKA_BANNER_47_PIC,
  ZAGLUSHKA_BANNER_50_PIC,
  ZAGLUSHKA_TABLET_SVG,
  ZAGLUSHKA_LAPTOP_SVG,
  ZAGLUSHKA_GAMEPAD_SVG,
  ZAGLUSHKA_SUN_SVG,
  ZAGLUSHKA_UV400_SVG,
} from "./constants/index";

// web urls

export const BANNERS_URL = "/v1/web/banners";
export const FAQ_URL = "/v1/faq";
export const MENU_TOP_URL = "/v1/web/menu/top";
export const MENU_FOOTER_URL = "/v1/web/menu/footer";
export const MENU_FOOTER2_URL = "/v1/web/menu/footer2";
export const NADPIS_URL = "/v1/web/letterings";
export const NEWS_URL = "/v1/web/news";
export const COMPANY_URL = "/v1/page/company";
export const COMPANY_EVENTS_URL = "/v1/company";
export const COMPANY_SETTINGS_URL = "/v1/company/settings";
export const COMPANY_REGIONS_URL = "/v1/company/regions";
export const COMPANY_SOCIALS_URL = "/v1/company/social";
export const COMPANY_PARTNERS_URL = "/v1/company/partners";
export const COMPANY_PDF_FILES_URL = "/v1/company/pdf-files";
export const COMPANY_RULES_URL = "/v1/company/rules";
export const COMPANY_REGION_SHOPS_URL = "/v1/company/regions/shops";
export const COMPANY_MOSCOW_URL = "/v1/company/moscow/shops";
export const COMPANY_PITER_URL = "/v1/company/regions/shops?region=2";
export const COMPANY_DOWNLOAD_CATALOG_URL = "/v1/company/download/catalog"; // where use?
export const COMPANY_DOWNLOAD_INSTRUCTION_URL =
  "/v1/company/download/instructions";

export const COMPANY_ONLINE_SHOPS_URL = "/v1/company/online/shops";

export const SHOP_PRODUCTS_URL = "/v1/shop/products";
export const SHOP_PRODUCTS_MED_URL = "/v1/shop/products/med";
export const SHOP_PAYMENT_METHODS_URL = "/v1/shop/payment-methods";
export const SHOP_MASTER_IS_WORKED = "/v1/shop/master-is_worked";

export const CHECK_TOKEN_URL = "/v1/auth/check_token";
export const PROFILE_CHANGE_EMAIL_URL = "/v1/profile/change_email";
export const PROFILE_SEND_SMS_CODE_URL = "/v1/profile/send_sms_code";
export const SMS_CHECK_CODE_URL = "/v1/sms/check_code";
export const SMS_SEND_URL = "/v1/sms/send";
export const PROFILE_CHANGE_PHONE_URL = "/v1/profile/change_phone";

export const CATALOG_MENU_URL = "/v1/web/catalog/menu";
export const URL_LIST_TYPE_MED_LINZ = "/v1/web/med-linz/types";
export const URL_LIST_PROPERTIES_MED_LINZ = "/v1/web/med-linz/properties";
export const URL_RECOMMENDED_MED_LINZ = "/v1/web/med-linz/recommended";

export const URl_LOAD_ITEMS_CART = "/v1/basket";
export const URL_ADD_TO_CART = "/v1/basket/add";
export const URL_DELETE_ITEM_IN_CART = "/v1/basket/delete";
export const URL_EDIT_QUANTITY_PRODUCTS = "/v1/basket/quantity";
export const URL_CART_INFO = "/v1/basket/info";
export const URl_CLEAR_CART = "/v1/basket/clear";
export const URL_CHOICE_EQUIPMENT = "/v1/basket/equipment";
export const URL_CREATE_ORDER = "/v1/create_order";
export const URL_CREATE_WHOLE_SALE_ORDER = "/v1/create_order/wholesale";

export const URL_ADDRESS_LOCATION = "/v1/geolocation/address";
export const URL_COUNTRIES = "/v1/delivery/countries";
export const URL_CDEK_DP_GEO = "/v1/delivery-points/cdek/geolocation";
export const URL_BOXBERRY_DP_GEO = "/v1/delivery-points/boxberry/geolocation";
export const URL_POST_DP_GEO = "/v1/delivery-points/post/geolocation";

export const URL_CDEK_DP_COST = "/v1/delivery-points/cdek/cost";
export const URL_CDEK_POST_COST = "/v1/delivery-points/post/cost";
export const URL_CDEK_BOXBERRY_COST = "/v1/delivery-points/boxberry/cost";

export const URL_SEND_DEALER_MSG = "/v1/company/dealer/message";
export const URL_SEND_FEEDBACK_MSG = "/v1/company/feedback/message";

const cookies = new Cookies();

axiosCookieJarSupport(axios);

// const cookieJar = new tough.CookieJar();

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function headers(set_cookie = false) {
  //   console.log('headers cookies', document.cookie)
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //'X-CSRF-Token': window.$('meta[name="csrf-token"]').attr('content')
  };
  //if (set_cookie) {
  //    headers['Authorization'] = "Bearer " + cookies.get('remember_user_token');
  //}
  return headers;
}

export function logResponseMessage(
  briefUrl,
  url,
  method,
  data,
  result,
  status = null
) {
  let head = `${briefUrl} ${method}`;
  if (status !== null) head += ` ${status}`;
}

/**
 * Generic api call to all endpoints.
 * Under any circumstance function should
 * return result object {isError, data}.
 *
 * @param {string}  url
 * @param {string}  method - GET, POST, PUT, PATCH, DELETE
 * @param {Object}  data
 * @param {boolean} isAuth - Does request need token.
 *
 * @returns {Object}  obj
 * @returns {boolean} obj.isError
 * @returns {Object}  obj.data - requested data from server
 *                               or errors object
 *
 *
 * data - may contain file.
 * If we need to upload file, data may contain File object in any field.
 * And data should have key: "fileFieldName"
 * Example:
 *    data.file = File object
 *    data.fileFieldName = 'file'
 *
 *
 * data errors example: possible keys {
 *   field1: ['error1'],
 *   field2: ['error2', 'error3'],
 *   errors: ['error4']}
 *
 */
export async function apiCall(url, method, data, isAuth) {
  const brierUrl = url;
  const general = getGeneralStore();
  general.setEnableLoading(true);

  let apiUrl =
    process.env.NODE_ENV === "development" ? URL_DEVELOPMENT : URL_PRODUCT;
  // let apiUrl = URL_PRODUCT;

  if (!url.startsWith("http")) {
    url = apiUrl + url;
  }

  isAuth = isAuth || false;

  let response;
  let result = { isError: false };

  let token = "";
  if (isAuth) {
    token = window.localStorage.getItem("token");
    if (token === null) {
      result.isError = true;
      result.data = { errors: ["The token is not provided."] };
      logGroupMessage(method, data, url, result);
      return result;
    }
  }

  try {
    let fetchObj = { method: method };
    fetchObj.headers = {};

    if (data.hasOwnProperty("fileFieldName")) {
      // data contains file for upload
      let fieldName = data.fileFieldName;
      let fdata = new FormData();

      fdata.append(fieldName, data[fieldName]);

      delete data[fieldName];
      delete data.fileFieldName;

      for (let key in data) {
        fdata.append(key, data[key]);
      }

      fetchObj.body = fdata;
    } else {
      if (method === "POST" || method === "PUT" || method === "PATCH") {
        fetchObj.body = JSON.stringify(data);
        fetchObj.headers = headers(true);
      }
    }

    fetchObj.headers.Authorization = "Token " + "shoppingcart";

    //fetchObj.credentials = 'include'
    //console.log('fetchObj', fetchObj)
    //response = await fetch(url, fetchObj)
    axios.defaults.withCredentials = true;
    switch (method) {
      case "GET":
        response = await axios.get(url, {
          withCredentials: true,
          headers: fetchObj.headers,
          timeout: 2000,
        });
        break;
      case "POST":
        response = await axios.post(url, fetchObj.body, {
          headers: fetchObj.headers,
          withCredentials: true,
          crossDomain: true,
        });
        break;
      case "DELETE":
        response = await axios.delete(url, { headers: fetchObj.headers });
        break;
    }
  } catch (e) {
    result.isError = true;
    result.data = { errors: ["Server error."] };
    logMessage(method, data, url, result);
    general.setEnableLoading(false);
    return result;
  }

  const status = response.status;

  if (status === 404) {
    result.isError = true;
    result.data = { errors: ["Resource not found."] };
    logMessage(method, data, url, result);
    general.setEnableLoading(false);
    return result;
  }

  if (status === 500) {
    result.isError = true;
    result.data = { errors: ["Internal server error."] };
    logMessage(method, data, url, result);
    general.setEnableLoading(false);
    return result;
  }

  if (status === 204) {
    result.data = {};
    logMessage(method, data, url, result);
    general.setEnableLoading(false);
    return result;
  }

  let obj = await response.data;
  if (status >= 200 && status <= 300) {
    if (response.data.status > 300) {
      result.isError = true;
      result.data = { errors: ["Internal server error."] };
      logMessage(method, data, url, result);
      general.setEnableLoading(false);
      return result;
    }
    result.data = obj;
    logResponseMessage(brierUrl, url, method, data, result, status);
    general.setEnableLoading(false);
    return result;
  }

  // In all other cases should be valid json object with errors.

  let erObj = {};

  if (obj.hasOwnProperty("error_code")) {
    if (obj.error_code.toLowerCase() === "bad token") {
      const store = getUserStore();
      store.logout();
    }
  }

  if (obj.hasOwnProperty("detail")) {
    erObj.errors = [obj.detail];
    delete obj.detail;
  } else if (obj.hasOwnProperty("non_field_errors")) {
    erObj.errors = [obj.non_field_errors];
    delete obj.non_field_errors;
  }

  // Other fields
  for (let key in obj) {
    erObj[key] = obj[key];
  }

  result.isError = true;
  result.data = erObj;
  logMessage(method, data, url, result);
  general.setEnableLoading(false);
  return result;
}

/**
 * fetch with progress via XMLHttpRequest
 * opts.method
 * opts.headers
 * opts.body
 * @returns {Promise}
 */
export function pfetch(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    let xhr = new XMLHttpRequest();
    xhr.open(opts.method || "get", url);
    for (let k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = (e) => res(e.target);
    xhr.onerror = rej;

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });
}

export function setSession(token) {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwt_token");
    if (token) {
      config.headers.common["Authorization"] = "Token " + token;
    }
    config.withCredentials = true;
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response?.status) {
        localStorage.removeItem("auth_user");
        localStorage.setItem("jwt_token", "");
        // history.push(`/signin`);
        //window.location.href = `${process.env.REACT_APP_LOGIN_URL}/?site=${encodeURIComponent(window.location.host)}&return=${encodeURIComponent(window.location.pathname)}`;
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  if (token) {
    localStorage.setItem("jwt_token", token);
    //axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    localStorage.removeItem("jwt_token");
    //delete axios.defaults.headers.common["Authorization"];
  }
}

export {
  URL_CATALOG,
  URL_CATALOG_COMP,
  URL_CATALOG_DIOPTR,
  URL_CATALOG_GAMER,
  URL_CATALOG_DRIVER,
  URL_CATALOG_DRIVER_SUN,
  URL_CATALOG_DRIVER_NIGHT,
  URL_CATALOG_ACTIVE,
  URL_CATALOG_ACTIVE_SUN,
  URL_CATALOG_ACTIVE_SPG,
  URL_CATALOG_REHABIL,
  URL_CATALOG_REHABIL_SUN,
  URL_CATALOG_REHABIL_SUN_GRAD,
  URL_CATALOG_POLAR,
  URL_CATALOG_POLAR_BROWN,
  URL_CATALOG_POLAR_GREEN,
  URL_CATALOG_POLAR_GREY,
  URL_CATALOG_CLIPS,
  URL_CATALOG_PINHOLE,
  URL_CATALOG_ANTIFARI,
  URL_CATALOG_ACCESSORIES,
  URL_CATALOG_MED,
  URL_CATALOG_OPTIKA,
  URL_ABOUT_COMP,
  URL_ABOUT_ACTIVE_SUN,
  URL_ABOUT_ACTIVE_SPG,
  URL_ABOUT_DRIVER_SUN,
  URL_ABOUT_DRIVER_NIGHT,
  URL_ABOUT_REHABIL,
  URL_ABOUT_POLAR,
  URL_ABOUT_PINHOLE,
  URL_ABOUT_ANTIFARI,
  URL_DOWNLOADS,
  URL_FEEDBACK,
  URL_PARTNERS,
  ZAGLUSHKA_LOGO_PIC,
  ZAGLUSHKA_DIOPTR_PIC,
  ZAGLUSHKA_POLAR_PIC,
  ZAGLUSHKA_SLIDER_IN_HOME,
  ZAGLUSHKA_SLIDER_IN_COMP_PAGE,
  ZAGLUSHKA_SLIDER_IN_ACTIVE_SUN_PAGE,
  ZAGLUSHKA_SLIDER_IN_ACTIVE_SPG_PAGE,
  ZAGLUSHKA_SLIDER_IN_DRIVER_SUN_PAGE,
  ZAGLUSHKA_SLIDER_IN_DRIVER_NIGHT_PAGE,
  ZAGLUSHKA_SLIDER_IN_REHABIL_PAGE,
  ZAGLUSHKA_SLIDER_IN_POLAR_PAGE,
  ZAGLUSHKA_SLIDER_IN_DEALERS_PAGE,
  ZAGLUSHKA_BANNER_17_PIC,
  ZAGLUSHKA_BANNER_18_PIC,
  ZAGLUSHKA_BANNER_20_PIC,
  ZAGLUSHKA_BANNER_43_PIC,
  ZAGLUSHKA_BANNER_47_PIC,
  ZAGLUSHKA_BANNER_50_PIC,
  ZAGLUSHKA_TABLET_SVG,
  ZAGLUSHKA_LAPTOP_SVG,
  ZAGLUSHKA_GAMEPAD_SVG,
  ZAGLUSHKA_SUN_SVG,
  ZAGLUSHKA_UV400_SVG,
  URL_ORDERING_STEP_FIRST,
  URL_ORDERING_STEP_SECOND,
  URL_ORDERING_WHOLESALE,
  URL_DEALERS,
  URL_FAQ,
  URL_NEWS,
  URL_RULES,
  URL_ORDER_IS_PROCESSED,
};

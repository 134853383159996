import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumbs }) => {

  if (breadcrumbs) {
    breadcrumbs = breadcrumbs
      .split("\r\n")
      .map((breadcrumb) => breadcrumb.split("::"));
    return breadcrumbs.map((breadcrumb, index) => (
        breadcrumb[1] &&
          <li className="breadcrumb-item" key={index}>
            <Link to={breadcrumb[1]}> {breadcrumb[0]} </Link>
          </li>

    ));
  }
  return null;
};

export default Breadcrumb;

import React from 'react'
import {rHTML} from "../../../services/helpers";


class ShopItemMenu extends React.Component {

    render() {
        const { obj } = this.props
        return (
            <div className="metro_line metro_line_small">
                {rHTML(obj.info)}
            </div>
        )
    }
}

export default ShopItemMenu
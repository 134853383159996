import React from 'react'
import { Link } from "react-router-dom";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'


@inject('general')
@observer
class DownloadsPage extends React.Component {

  componentDidMount() {
    const { general } = this.props
    /*general.loadCompanyDownloadCatalog(); */
    general.loadCompanyDownloadInstructions();
    general.setTitlePage("Инструкции по применению")
  }

  render() {
    const { general } = this.props
    return (
      <div className="container">

        <div className="breadcrumbs">
          <ol className="breadcrumbs_nav">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Загрузки</a>
            </li>
          </ol>
        </div>

        <h1>Инструкции по применению</h1>

        <div className="article">
          {/*
                        <h2>ЭЛЕКТРОННЫЕ КАТАЛОГИ</h2>
                        {general.companyDownloadCatalog && general.companyDownloadCatalog.map((obj, index)=>{
                            return(
                                <Link to={obj.infodic.link} className="metro_line metro_line_wicon" key={index} target="_blank">
                                    <img src="/images/pdf.svg" alt=""/>
                                    <span>
                                        <b>{obj.name}</b>
                                        скачать в формате PDF (~{obj.infodic.size})
                                    </span>
                                </Link>
                            )
                        })}
                    */}
          <h2>ИНСТРУКЦИИ</h2>
          {general.companyDownloadInstruction && general.companyDownloadInstruction.map((obj, index) => {
            return (
              <Link to={"/media" + obj.infodic.link} className="metro_line metro_line_wicon" key={index} target="_blank">
                <img src="/images/pdf.svg" alt="" />
                <span>
                  <b>{obj.name}</b>
                  скачать в формате PDF (~{obj.infodic.size})
                </span>
              </Link>
            )
          })}

        </div>

      </div>
    )
  }
}

DownloadsPage.propTypes = {
  general: MPropTypes.observableObject,
}

export default DownloadsPage

const URL_DEVELOPMENT = 'http://127.0.0.1:5660'
const URL_PRODUCT = 'https://api.alis96.ru'
const URL_CATALOG = '/produktsiya/'
const URL_CATALOG_COMP = '/catalogue_computer_SPG/'


const URL_ABOUT_COMP = '/about_computer_SPG/'
const URL_ABOUT_ACTIVE_SUN = '/about_active_SPG_sun/'
const URL_ABOUT_ACTIVE_SPG = '/about_active_SPG_night_bad_weather/'
const URL_ABOUT_DRIVER_SUN = '/about_driving_SPG_sun/'
const URL_ABOUT_DRIVER_NIGHT = '/about_driving_SPG_night_bad_weather/'
const URL_ABOUT_REHABIL = '/about_rehabilitation_SPG/'
const URL_ABOUT_POLAR = '/about_polarized_sunglasses/'
const URL_ABOUT_PINHOLE = '/about_pinhole/'
const URL_ABOUT_ANTIFARI = '/about_antifari_economy/'

const URL_CATALOG_DIOPTR = '/catalogue_computer_SPG/dioptr/'
const URL_CATALOG_GAMER = '/catalogue_computer_gaming_SPG/'
const URL_CATALOG_DRIVER = '/catalogue_driving_SPG/'
const URL_CATALOG_DRIVER_SUN = '/catalogue_driving_SPG_sun/'
const URL_CATALOG_DRIVER_NIGHT = '/catalogue_driving_SPG_night_bad_weather/'
const URL_CATALOG_ACTIVE_SPG = '/catalogue_active_SPG/'
const URL_CATALOG_ACTIVE_SUN = '/catalogue_active_SPG_sun/'
const URL_CATALOG_ACTIVE = '/catalogue_active_SPG_night_bad_weather/'
const URL_CATALOG_REHABIL = '/catalogue_rehabilitation_SPG/'
const URL_CATALOG_REHABIL_SUN = '/catalogue_rehabilitation_SPG_sun/'
const URL_CATALOG_REHABIL_SUN_GRAD = '/catalogue_rehabilitation_SPG_sun_gradient/'
const URL_CATALOG_POLAR = '/catalogue_polarized/'
const URL_CATALOG_POLAR_BROWN = '/catalogue_polarized_brown/'
const URL_CATALOG_POLAR_GREEN = '/catalogue_polarized_green/'
const URL_CATALOG_POLAR_GREY = '/catalogue_polarized_grey/'
const URL_CATALOG_CLIPS = '/clips/'
const URL_CATALOG_PINHOLE = '/pinhole/'
const URL_CATALOG_ANTIFARI = '/antifari_economy/'
const URL_CATALOG_ACCESSORIES = '/accessories/'
const URL_CATALOG_MED = '/catalogue_med/'
const URL_CATALOG_OPTIKA = '/optika/'
export const URL_ORDERING_STEP_FIRST = '/ordering/step/first'
export const URL_ORDERING_STEP_SECOND = '/ordering/step/second'
export const URL_ORDERING_WHOLESALE = '/ordering/wholesale'
export const URL_ORDERING_WHOLESALE_IS_PROCESSED = '/ordering/wholesale/success'
export const URL_ORDER_IS_PROCESSED = '/ordering/success'
export const URL_DEALERS = '/dealers/'
export const URL_FAQ = '/faq/'
export const URL_NEWS = '/news/'


const URL_DOWNLOADS = '/downloads/'
const URL_FEEDBACK = '/support/'
const URL_PARTNERS = '/partners/'
const URL_RULES = '/terms_and_conditions/'

const MIN_QNT_IN_BASKET_FOR_WHOLESALE = 12

// ZAGLUSHKI
const ZAGLUSHKA_LOGO_PIC = "/images/logo-banner.jpg"
const ZAGLUSHKA_DIOPTR_PIC = "/images/uploads/banner40.jpg"
const ZAGLUSHKA_POLAR_PIC = "/images/uploads/banner41.jpg"
const ZAGLUSHKA_BANNER_43_PIC = "/images/uploads/banner43.jpg"
const ZAGLUSHKA_BANNER_17_PIC = "/images/banner17.jpg"
const ZAGLUSHKA_BANNER_18_PIC = "/images/banner18.jpg"
const ZAGLUSHKA_BANNER_20_PIC = "/images/uploads/banner20.jpg"
const ZAGLUSHKA_BANNER_47_PIC = "/images/uploads/banner47.jpg"
const ZAGLUSHKA_BANNER_49_PIC = "/images/uploads/banner49.jpg"
const ZAGLUSHKA_BANNER_50_PIC = "/images/uploads/banner50.jpg"
const ZAGLUSHKA_TABLET_SVG = "/images/tablet.svg"
const ZAGLUSHKA_LAPTOP_SVG = "/images/laptop.svg"
const ZAGLUSHKA_GAMEPAD_SVG = "/images/gamepad.svg"
const ZAGLUSHKA_SUN_SVG = "/images/svg/icon5.svg"
const ZAGLUSHKA_UV400_SVG = "/images/svg/icon1.svg"

const ZAGLUSHKA_SLIDER_IN_HOME = [
  {
    title: "title",
    picUrl: "images/uploads/banner1.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "images/uploads/banner2.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "images/uploads/banner3.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "images/uploads/banner4.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "images/uploads/banner5.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "images/uploads/banner6.jpg",
    url: ""
  },
]

const ZAGLUSHKA_PAGE_ABOUT = {
  title: 'О компании',
}

const ZAGLUSHKA_SLIDER_IN_COMP_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner56.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "/images/uploads/banner57.jpg",
    url: ""
  },
]

const ZAGLUSHKA_SLIDER_IN_ACTIVE_SUN_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner67.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "/images/uploads/banner68.jpg",
    url: ""
  },
]

const ZAGLUSHKA_SLIDER_IN_ACTIVE_SPG_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner60.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "/images/uploads/banner61.jpg",
    url: ""
  },
]

const ZAGLUSHKA_SLIDER_IN_DRIVER_SUN_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner69.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "/images/uploads/banner70.jpg",
    url: ""
  },
]

const ZAGLUSHKA_SLIDER_IN_DRIVER_NIGHT_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner58.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "/images/uploads/banner59.jpg",
    url: ""
  },
]

const ZAGLUSHKA_SLIDER_IN_REHABIL_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner55.jpg",
    url: ""
  },
  {
    title: "title",
    picUrl: "/images/uploads/banner56.jpg",
    url: ""
  },
]

const ZAGLUSHKA_SLIDER_IN_POLAR_PAGE = [
  {
    title: "title",
    picUrl: "/images/uploads/banner76.jpg",
    url: ""
  },

]

const ZAGLUSHKA_SLIDER_IN_DEALERS_PAGE = [
  {
    title: "Дисплей №1",
    picUrl: "/media/dealers/dealerstand2.jpg",
    options: [
      'без замков',
      'вращающееся основание',
      'на 24 модели',
      'высота - 60 см',
      'ширина - 34 см'
    ]
  },
  {
    title: "Дисплей №6",
    picUrl: "/media/dealers/dealerstand1.jpg",
    options: [
      'без замков',
      'вращающееся основание',
      'на 22 модели',
      'высота - 72 см',
      'ширина - 32 см'
    ]
  },
  {
    title: "Дисплей №7",
    picUrl: "/media/dealers/dealerstand3.jpg",
    options: [
      'без замков',
      'вращающееся основание',
      'на 46 модели',
      'высота - 102 см',
      'ширина - 32 см'
    ]
  },
  {
    title: "Дисплей №9",
    picUrl: "/media/dealers/dealerstand7.jpg",
    options: [
      'без замков',
      'на 8 модели',
      'высота - 100 см',
      'ширина - 30 см'
    ]
  },
  {
    title: "Дисплей №8",
    picUrl: "/media/dealers/dealerstand4.jpg",
    options: [
      'без замков',
      'на 5 модели',
      'высота - 27 см',
      'ширина - 13 см',
      'глубина - 20 см'
    ]
  },
  {
    title: "Дисплеи №10 и №11",
    picUrl: "/media/dealers/dealerstand8.jpg",
    options: [
      'с образцами для примерки',
      'для блистерной упаковки',
      'односторонние на 11 моделей',
      'двусторонние на 22 модели',
      'вместимость – 44 и 88 очков',
      'высота - 140 см',
      'ширина - 75 см',
      'глубина - 50 см'
    ]
  },
]

export const STAR = "*";
export const ERR_EMPTY_FIRST_NAME = "Не указано имя получателя";
export const ERR_EMPTY_LAST_NAME = "Не указано фамилия получателя";
export const ERR_EMPTY_EMAIL = "Не указан email получателя";
export const ERR_EMAIL_NOT_VALID = "Не верно заполнено email получателя";
export const ERR_EMPTY_PHONE = "Не указан телефон получателя";
export const ERR_EMPTY_ADDRESS = "Не указан адресс получателя";
export const ERR_EMPTY_PAYMENT = "Не выбран способ оплаты";
export const ERR_EMPTY_TITLE = "Не указана тема";
export const ERR_EMPTY_MESSAGE = "Не указано сообщение";

export {
  URL_DEVELOPMENT,
  URL_PRODUCT,

  URL_CATALOG,
  URL_CATALOG_COMP,
  URL_CATALOG_DIOPTR,
  URL_CATALOG_GAMER,
  URL_CATALOG_DRIVER,
  URL_CATALOG_DRIVER_SUN,
  URL_CATALOG_DRIVER_NIGHT,
  URL_CATALOG_ACTIVE,
  URL_CATALOG_ACTIVE_SUN,
  URL_CATALOG_ACTIVE_SPG,
  URL_CATALOG_REHABIL,
  URL_CATALOG_REHABIL_SUN,
  URL_CATALOG_REHABIL_SUN_GRAD,
  URL_CATALOG_POLAR,
  URL_CATALOG_POLAR_BROWN,
  URL_CATALOG_POLAR_GREEN,
  URL_CATALOG_POLAR_GREY,
  URL_CATALOG_CLIPS,
  URL_CATALOG_PINHOLE,
  URL_CATALOG_ANTIFARI,
  URL_CATALOG_ACCESSORIES,
  URL_CATALOG_MED,
  URL_CATALOG_OPTIKA,
  URL_ABOUT_COMP,
  URL_ABOUT_ACTIVE_SUN,
  URL_ABOUT_ACTIVE_SPG,
  URL_ABOUT_DRIVER_SUN,
  URL_ABOUT_DRIVER_NIGHT,
  URL_ABOUT_REHABIL,
  URL_ABOUT_POLAR,
  URL_ABOUT_PINHOLE,
  URL_ABOUT_ANTIFARI,

  URL_DOWNLOADS,
  URL_FEEDBACK,
  URL_RULES,
  URL_PARTNERS,

  MIN_QNT_IN_BASKET_FOR_WHOLESALE,

  ZAGLUSHKA_LOGO_PIC,
  ZAGLUSHKA_DIOPTR_PIC,
  ZAGLUSHKA_POLAR_PIC,

  ZAGLUSHKA_SLIDER_IN_HOME,
  ZAGLUSHKA_SLIDER_IN_COMP_PAGE,
  ZAGLUSHKA_SLIDER_IN_ACTIVE_SUN_PAGE,
  ZAGLUSHKA_SLIDER_IN_ACTIVE_SPG_PAGE,
  ZAGLUSHKA_SLIDER_IN_DRIVER_SUN_PAGE,
  ZAGLUSHKA_SLIDER_IN_DRIVER_NIGHT_PAGE,
  ZAGLUSHKA_SLIDER_IN_REHABIL_PAGE,
  ZAGLUSHKA_SLIDER_IN_POLAR_PAGE,
  ZAGLUSHKA_SLIDER_IN_DEALERS_PAGE,

  ZAGLUSHKA_BANNER_17_PIC,
  ZAGLUSHKA_BANNER_18_PIC,
  ZAGLUSHKA_BANNER_20_PIC,
  ZAGLUSHKA_BANNER_43_PIC,
  ZAGLUSHKA_BANNER_47_PIC,
  ZAGLUSHKA_BANNER_49_PIC,
  ZAGLUSHKA_BANNER_50_PIC,
  ZAGLUSHKA_TABLET_SVG,
  ZAGLUSHKA_LAPTOP_SVG,
  ZAGLUSHKA_GAMEPAD_SVG,
  ZAGLUSHKA_SUN_SVG,
  ZAGLUSHKA_UV400_SVG,
}

import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { renderHTML } from 'services/helpers'
import {Link} from "react-router-dom";


@inject('general')
@observer
class OnlineShopsMenu extends React.Component {
    componentDidMount(){
        const {general} = this.props
        general.loadCompanyOnlineShops();
    }


    render() {
        const {general} = this.props
        return (
            <React.Fragment>
                { general.companyOnlineShops &&
                general.companyOnlineShops.map((obj, index) =>{

                    return (
                        <React.Fragment key={index}>
                            <div className="metro_line flexed">
                                <div className="metro_line_site">
                                    <a href={obj.goUrl}
                                          target="_blank">{obj.url}
                                    </a>
                                </div>
                                <div className="metro_line_text">{obj.info}</div>
                            </div>
                        </React.Fragment>
                    )
                })
                }
            </React.Fragment>
        )
    }
}

OnlineShopsMenu.propTypes = {
    general: MPropTypes.observableObject,
}
export default OnlineShopsMenu
import React from 'react'
import PropTypes from 'prop-types'


export default class Alert extends React.Component{
	static defaultProps = {
		type: 'success',
	}

	onClickDelete = () => {
		const { guid, onClickDeleteAlert } = this.props
		onClickDeleteAlert(guid)
	}

	get alertClassName() {
		const { type } = this.props

        if (type === 'danger'){
            return 'alert alert-danger'
        }

        return 'alert alert-success'
	}

    render(){
    	const { text } = this.props
        return (
            <div className={this.alertClassName}>
                <p>{text}</p>
                <img
                    alt=""
                    onClick={this.onClickDelete}
                    src="/images/spr/close.png" />
            </div>
        )
    }
}


Alert.propTypes = {
    guid: PropTypes.string,
    onClickDeleteAlert: PropTypes.func,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}
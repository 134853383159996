import React, {Component} from "react";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";


@inject('storeCatalog')
@observer
class RowPropertyMedLinz extends Component {
    onSelectedMedTypeLinz = (typeLinz) => {
        const { cookies } = this.props;
        this.props.storeCatalog.setSelectedMedTypeLinz(typeLinz.id)
        cookies.set('select_type_linz_id', typeLinz.id, { path: '/' });
    }

    render() {
        const { storeCatalog, property } = this.props
        return(
            <React.Fragment>
                <tr>
                    <td className="optiktable_choose_title">{property.name}</td>
                    {storeCatalog.listMedTypeLinz.map((obj, index)=>{
                        const className = (storeCatalog.selectedMedTypeLinz==obj)? "td_select clickable": "clickable";
                        const nameImage = (obj.properties.includes(property.id))? "yes":"nope"
                        return (
                            <React.Fragment key={index}>
                                <td  className={className} onClick={this.onSelectedMedTypeLinz.bind(null, obj)}>
                                    <img src={"/images/svg/"+nameImage+".svg"} alt="" className="icon"/>
                                </td>
                            </React.Fragment>
                        )})
                    }
                </tr>
            </React.Fragment>
        )
    }
}

RowPropertyMedLinz.propTypes = {
    storeCatalog: MPropTypes.observableObject,
    cookies: instanceOf(Cookies).isRequired
}

export default withCookies(RowPropertyMedLinz)
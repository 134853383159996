import Model from './private/Model'

export default class PageModel extends Model{

    get optionDioptrs(){
        const result = []

        this.med && this.diopMinus && this.diopMinus.map((obj) =>{
            result.push({text:obj, value:obj})
        })

        !this.med && this.linzstockminus && this.linzstockminus.map((obj) =>{
            result.push({text:obj.sph, value:obj.sph})
        })

        this.med && this.diopPlus && this.diopPlus.map((obj) =>{
            result.push({text:obj, value:obj})
        })

        !this.med && this.linzstockplus && this.linzstockplus.map((obj) =>{
            let sph = obj.sph
            if (obj.sph > 0){
                sph = "+" + sph
            }
            result.push({text:sph, value: obj.sph})
        })
        return result
    }

    get optionRangesCyl(){
        const result = []

        this.rangesCyl && this.rangesCyl.map((obj) =>{
            result.push({text:obj, value:obj})
        })
        return result
    }

    get optionRangesAxis(){
        const result = []

        this.rangesAxis && this.rangesAxis.map((obj) =>{
            result.push({text:obj, value:obj})
        })
        return result
    }
    get optionDP(){
        const result = []

        this.dp && this.dp.map((obj) =>{
            result.push({text:obj, value:obj})
        })
        return result
    }
}


import React from 'react'
import {Link} from "react-router-dom";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'


@inject('general', 'routing')
@observer
class PartnersPage extends React.Component {

  componentDidMount() {
    const {general, routing} = this.props
    general.loadPageContent(routing.location.pathname);
    general.loadPartners();
    general.setTitlePage("Партнеры")
  }

  render() {
    const {general} = this.props
    return (
        <div className="container">
            <div className="breadcrumbs">
                <ol className="breadcrumbs_nav">
                    <li className="breadcrumb-item">
                        <Link to="/">Главная</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="#">Партеры</Link>
                    </li>
                </ol>
            </div>

          <h1>{general.page_object?.h1}</h1>
          <div className="article">
            <div className="row image_gallery">
              {general.companyPartners && general.companyPartners.map((obj, index) => {
                  return (
                      <div className="grid-3 sm6" key={index}>
                        <a href={obj.link} className="image_gallery_item" target="_blank">
                          <img src={"/media" + obj.pic} alt=""/>
                        </a>
                      </div>)
                })
              }

            </div>
          </div>
        </div>)
  }
}


PartnersPage.propTypes = {
    general: MPropTypes.observableObject,
    routing: MPropTypes.observableObject,
}


export default PartnersPage
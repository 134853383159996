import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

@inject("general")
@observer
class FooterSocial extends React.Component {
  componentDidMount() {
    this.props.general.loadSocials();
  }

  render() {
    const { general } = this.props;
    return (
      <div className="footer_social">
        {general?.socials.map(({ id, link, icon, title }) => {
          return (
            <a key={id} href={link} target="_blank">
              <img src={icon} alt="" />
            </a>
          );
        })}
      </div>
    );
  }
}

FooterSocial.propTypes = {
  general: MPropTypes.observableObject,
};

export default FooterSocial;

import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { RightMenu, RightFilter } from 'components'
import { logGroupMessage } from "../../../services/helpers";
import $ from 'jquery';

@inject('general')
@observer
class RightFilterBox extends React.Component {

  render() {
    const { general } = this.props
    return (
      <React.Fragment>
        <div className="filterbox">
          <RightMenu />
          {general.page_object && general.page_object.spg && !general.activeDioptCatalog &&
            <RightFilter />
          }
        </div>
      </React.Fragment>

    )
  }
}

RightFilterBox.propTypes = {
  general: MPropTypes.observableObject,
}
export default RightFilterBox
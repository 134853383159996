import { inject, observer, PropTypes as MPropTypes } from "mobx-react";
import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { rpHTML } from "services/helpers";
import { URL_ORDERING_STEP_FIRST } from "../../api";
import { Input } from "../../components";
import {
  ERR_EMAIL_NOT_VALID, ERR_EMPTY_ADDRESS,
  ERR_EMPTY_EMAIL,
  ERR_EMPTY_FIRST_NAME,
  ERR_EMPTY_LAST_NAME, ERR_EMPTY_PAYMENT, ERR_EMPTY_PHONE,
  STAR
} from "../../api/constants";


let scrollAnimateToTopContacts = () => {
  window.$([document.documentElement, document.body]).animate(
    {
      scrollTop: window.$("#contactsWrapper").offset().top - 52,
    },
    1000
  );
  window.scrollTo(0, 0);
};

let scrollAnimateToTopPaymentMethod = () => {
  window.$([document.documentElement, document.body]).animate(
    {
      scrollTop: window.$("#payentMethodsWrapper").offset().top - 52,
    },
    1000
  );
  window.scrollTo(0, 0);
};

@inject("general", "storeDelivery", "basket",)
@observer
class OrderingStepSecondPage extends Component {
  constructor(props) {
    super(props);
    const {storeDelivery} = this.props
    this.state = {
      emptyFirstName: null,
      emptyLastName: null,
      emptyEmail: null,
      emptyPhone: null,
      emptyAddress: null,
      emailIsValid: null,
      errorMsgFirstName: STAR,
      errorMsgLastName: STAR,
      errorMsgEmail: STAR,
      errorMsgPhone: STAR,
      errorMsgAddress: STAR,
      errorMsgPayment: STAR,
      cdekAddress:
        this.props.storeDelivery?.activeDeliveryPoint?.location &&
        this.props.storeDelivery?.activeDeliveryPoint?.location?.postalCode +
          ", " +
          this.props.storeDelivery?.activeDeliveryPoint?.location?.addressFull,
      otherDeliveryAddress:
        (this.props.storeDelivery.activeDeliveryPoint?.postalCode
          ? `${this.props.storeDelivery.activeDeliveryPoint?.postalCode}, `
          : "") + this.props.storeDelivery.activeDeliveryPoint?.address
    };

    if (storeDelivery.activeCountryDelivery?.withDelivery &&
        storeDelivery.valueChoicePaymentMethod === storeDelivery.PAYMENT_UPON_RECEIPT){
      storeDelivery.setActivePaymentMethod(storeDelivery.PAYMENT_UPON_RECEIPT)
    }
  }

  componentDidMount() {
    const $ = window.$;
    $(".selectbox").styler();
  }

  checkedAddress(){
    const { storeDelivery } = this.props;
    return [
        storeDelivery.POST_COURIER,
        storeDelivery.CDEK_COURIER,
      storeDelivery.BOXBERRY_COURIER
    ].includes(storeDelivery.activeDeliveryMethod)
  }

  checkedErrorInContacts() {
    const { storeDelivery } = this.props;
    return {
      emptyFirstName: storeDelivery.recipientFirstName
        ? storeDelivery.recipientFirstName.length === 0
        : true,
      emptyLastName: storeDelivery.recipientLastName
        ? storeDelivery.recipientLastName.length === 0
        : true,
      emptyEmail: storeDelivery.recipientEmail
        ? storeDelivery.recipientEmail.length === 0
        : true,
      emailIsValid: storeDelivery.recipientEmail
        ? Boolean(
            storeDelivery.recipientEmail.match(
              /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
            )
          )
        : false,
      emptyPhone: storeDelivery.recipientPhone
        ? storeDelivery.recipientPhone.length < 11
        : true,
      emptyAddress: storeDelivery.recipientAddressDelivery
        ? (this.checkedAddress() && storeDelivery.recipientAddressDelivery.length === 0)
        : true,
    };
  }

  setErrorMsg() {
    const {
      emptyFirstName,
      emptyLastName,
      emptyEmail,
      emailIsValid,
      emptyPhone,
      emptyAddress,
    } = this.checkedErrorInContacts();
    const errorMsgFirstName =
      emptyFirstName == true ? ERR_EMPTY_FIRST_NAME : STAR;
    const errorMsgLastName = emptyLastName == true ? ERR_EMPTY_LAST_NAME : STAR;
    const errorMsgEmail =
      emptyEmail === true
        ? ERR_EMPTY_EMAIL
        : emailIsValid === false
        ? ERR_EMAIL_NOT_VALID
        : STAR;
    const errorMsgPhone = emptyPhone === true ? ERR_EMPTY_PHONE : STAR;
    const errorMsgAddress = emptyAddress === true ? ERR_EMPTY_ADDRESS : STAR;
    const errorMsgPayment = !this.props.storeDelivery.activePaymentMethod
      ? ERR_EMPTY_PAYMENT
      : STAR;

    this.setState({
      emptyFirstName: emptyFirstName,
      emptyLastName: emptyLastName,
      emptyEmail: emptyEmail,
      emailIsValid: emailIsValid,
      emptyPhone: emptyPhone,
      emptyAddress: emptyAddress,
      errorMsgFirstName: errorMsgFirstName,
      errorMsgLastName: errorMsgLastName,
      errorMsgEmail: errorMsgEmail,
      errorMsgPhone: errorMsgPhone,
      errorMsgAddress: errorMsgAddress,
      errorMsgPayment: errorMsgPayment,
    });
  }

  onChangeFirstName = (value) => {
    this.setState({ emptyFirstName: value.length === 0 });
    this.props.storeDelivery.setRecipientFirstName(value);
    this.setErrorMsg();
  };

  onChangeLastName = (value) => {
    this.setState({ emptyLastName: value.length === 0 });
    this.props.storeDelivery.setRecipientLastName(value);
    this.setErrorMsg();
  };

  onChangeEmail = (value) => {
    this.props.storeDelivery.setRecipientEmail(value);
    this.setState({
      emptyEmail: value.length === 0,
      emailIsValid: Boolean(
        value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ),
    });
    this.setErrorMsg();
  };

  onChangePhone = (value) => {
    this.props.storeDelivery.setRecipientPhone(value);
    this.setState({ emptyPhone: value.length < 11 });
    this.setErrorMsg();
  };

  onChangeTimeDelivery = (value) => {
    this.props.storeDelivery.setRecipientTimeDelivery(value);
  };
  onChangeAddressDelivery = (e) => {
    this.props.storeDelivery.setRecipientAddressDelivery(e.target.value);
    // this.props.storeDelivery.setActiveDeliveryPoint(e.target.value, false)
    this.setState({ emptyAddress: e.target.value.length === 0 });
    this.setErrorMsg();
  };

  onChangeComment = (e) => {
    this.props.storeDelivery.setRecipientComment(e.target.value);
  };

  onClickSubmit = (e) => {
    e.preventDefault();
    const { storeDelivery, basket } = this.props;
    const {
      emptyFirstName,
      emptyLastName,
      emptyEmail,
      emailIsValid,
      emptyPhone,
      emptyAddress,
    } = this.checkedErrorInContacts();
    const errorContacts =
        (emptyFirstName === null || emptyFirstName) ||
        (emptyLastName === null || emptyLastName) ||
        (emptyEmail === null || emptyEmail) ||
        (emptyPhone === null || emptyPhone) ||
        (emptyAddress === null || emptyAddress) ||
      !emailIsValid

    this.setErrorMsg();
    if (errorContacts) {
      e.preventDefault();
      scrollAnimateToTopContacts();
      return false;
    }
    if (!storeDelivery.activePaymentMethod) {
      e.preventDefault();
      scrollAnimateToTopPaymentMethod();
      return false;
    }
    basket.createOrder();
  };

  render() {
    const { storeDelivery, general } = this.props;
    if (
      storeDelivery.activeDeliveryPoint == null &&
      storeDelivery.activeDeliveryMethod == null
    ) {
      this.props.history.push(URL_ORDERING_STEP_FIRST);
      return null;
    }
    const strInfoBlocks = storeDelivery.getStrInfoBlocks(
      storeDelivery.activeDeliveryMethod
    );
    const deliveryCost = [
      storeDelivery.CDEK_PRIM,
      storeDelivery.CDEK_COURIER,
      storeDelivery.BOXBERRY_COURIER,
      storeDelivery.POST_COURIER,
      storeDelivery.DPD_COURIER
    ].indexOf(storeDelivery.activeDeliveryMethod) !== -1
        ? +this.props.storeDelivery.activeDeliveryPointCost?.costCourier
            ?.toString()
            ?.slice(0, -5)
        : +this.props.storeDelivery.activeDeliveryPointCost?.cost
            ?.toString()
            ?.slice(0, -5)

    return (
      <React.Fragment>
        <div className="container">
          <div className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Оформление заказа</a>
              </li>
            </ol>
          </div>

          <div className="page_header">
            <h1>Оформление заказа</h1>
          </div>

          <div className="forms_section">
            <div className="forms_section_entry">
              <div className="row">
                <div className="grid-5 tb12 forms_section_text">
                  <h3>
                    {
                      storeDelivery.strMethods[
                        storeDelivery.activeDeliveryMethod
                      ]
                    }{" "}
                  </h3>
                  {(!storeDelivery.activeDeliveryPoint && storeDelivery.recipientAddressDelivery) &&
                    <p>{storeDelivery.recipientAddressDelivery}</p>
                  }
                  {
                    storeDelivery.activeDeliveryPoint  &&
                    <p>
                      {this.state?.cdekAddress ||
                      this.state?.otherDeliveryAddress ||
                      this.props.storeDelivery?.dadataAddress?.value}
                    </p>
                  }
                  {storeDelivery.activeDeliveryPoint &&
                    rpHTML(storeDelivery.activeDeliveryPoint.workHours)}
                  <p>{storeDelivery.activeDeliveryPoint?.comment}</p>
                  <p>
                    <Link to={URL_ORDERING_STEP_FIRST} className="uppercase">
                      Выбрать другой способ доставки
                    </Link>
                  </p>
                </div>
                <div className="grid-2 tb12 forms_section_text">
                  <h3>Стоимость</h3>
                  {deliveryCost} руб
                </div>
                <div className="grid-5 tb12">
                  <h3>Дополнительная информация</h3>
                  {strInfoBlocks?.leftCol}
                </div>
              </div>
            </div>
          </div>

          <div id="contactsWrapper" className="forms_section">
            <div className="forms_section_entry">
              <h3 className="uppercase">Контактная информация</h3>

              <div className="feedback_form">
                <div className="input-field">
                  <label>
                    Имя{" "}
                    <span className="red">{this.state.errorMsgFirstName}</span>
                  </label>
                  <Input
                    name="firstName"
                    type="text"
                    className={
                      this.state.emptyFirstName ? "input error" : "input"
                    }
                    value={storeDelivery.recipientFirstName}
                    onChange={this.onChangeFirstName}
                  />
                </div>
                <div className="input-field">
                  <label>
                    Фамилия{" "}
                    <span className="red">{this.state.errorMsgLastName}</span>
                  </label>
                  <Input
                    name="lastName"
                    type="text"
                    className={
                      this.state.emptyLastName ? "input error" : "input"
                    }
                    value={storeDelivery.recipientLastName}
                    onChange={this.onChangeLastName}
                  />
                </div>
                <div className="input-field">
                  <label>
                    E-mail{" "}
                    <span className="red">{this.state.errorMsgEmail}</span>
                  </label>
                  <Input
                    name="email"
                    type="email"
                    className={
                      this.state.emailIsValid === false
                        ? "input error"
                        : "input"
                    }
                    value={storeDelivery.recipientEmail}
                    onChange={this.onChangeEmail}
                  />
                </div>
                <div className="input-field">
                  <label>
                    Телефон{" "}
                    <span className="red">{this.state.errorMsgPhone}</span>
                  </label>
                  <PhoneInput
                    name="phone"
                    country={"ru"}
                    placeholder="7 (902) 123-4567"
                    value={storeDelivery.recipientPhone}
                    onlyCountries={["ru", "by", "ua"]}
                    onChange={this.onChangePhone}
                  />
                </div>
                { this.checkedAddress() &&
                <div className="input-field">
                  <label>
                    Адрес доставки{" "}
                    <span className="red">{this.state.errorMsgAddress}</span>
                  </label>
                  <textarea
                    className={
                      this.state.emptyAddress ? "input error" : "input"
                    }
                    onKeyUp={this.onChangeAddressDelivery.bind(this)}
                    defaultValue={
                      storeDelivery.recipientAddressDelivery ||
                      storeDelivery.activeDeliveryPoint?.address
                    }
                  />
                </div>}
              </div>
            </div>
          </div>

          <div className="forms_section">
            <div className="forms_section_entry">
              <div className="basket_total_box basket_total_sum">
                Итого (с учётом доставки):{" "}
                <b>
                  {+this.props.basket.amount.split(" ").join("") + deliveryCost}{" "}
                  руб.
                </b>
              </div>
            </div>
          </div>

          <div id="payentMethodsWrapper" className="forms_section">
            <div className="forms_section_entry input-fields-payment">
              <h3 className="uppercase">Способ оплаты</h3>
              {
                // Показываем при условии, что Россия и способ оплаты - не Предоплата
                storeDelivery.activeCountryDelivery?.withDelivery &&
                  storeDelivery.valueChoicePaymentMethod !==
                    storeDelivery.PREPAYMENT && (
                    <div className="input-field">
                      <label className="check-field">
                        <input
                          type="radio"
                          name="payment_method"
                          defaultChecked="checked"
                          onClick={() => {
                            storeDelivery.setActivePaymentMethod(storeDelivery.PAYMENT_UPON_RECEIPT);
                          }}
                        />
                        Оплата при получении
                      </label>
                    </div>
                  )
              }
              {storeDelivery.activeCountryDelivery?.withDelivery &&
                storeDelivery.valueChoicePaymentMethod ===
                storeDelivery.PREPAYMENT && (
                    <React.Fragment>
                      <div className="input-field">
                        <label className="check-field">
                          <input
                            type="radio"
                            name="payment_method"
                            defaultChecked={
                              storeDelivery.activePaymentMethod === 2 ? "checked" : ""
                            }
                            onClick={() => {
                              storeDelivery.setActivePaymentMethod(2);
                            }}
                          />
                          {general.getLettering("360")}
                          <span className="check-field-img">
                            <img src="/images/svg/mir.svg" alt="" />
                            <img src="/images/svg/visa.svg" alt="" />
                            <img src="/images/svg/mastercard.svg" alt="" />
                          </span>
                        </label>
                      </div>

                      <div className="input-field">
                        <label className="check-field">
                          <input
                            type="radio"
                            name="payment_method"
                            defaultChecked={
                              storeDelivery.activePaymentMethod === 3 ? "checked" : ""
                            }
                            onClick={() => {
                              storeDelivery.setActivePaymentMethod(3);
                            }}
                          />
                          {general.getLettering("361")}
                          <span className="check-field-img">
                            <img src="/images/svg/sbp.svg" alt="" />
                            <img src="/images/svg/yoo.svg" alt="" />
                            <img src="/images/svg/sber.svg" alt="" />
                            <img src="/images/svg/alfabank.svg" alt="" />
                            <img src="/images/svg/tinkoff.svg" alt="" />
                          </span>
                        </label>
                      </div>
                    </React.Fragment>
                  )
              }

              {!storeDelivery.activeCountryDelivery?.withDelivery && (
                <div className="input-field">
                  <label className="check-field">
                    <input
                      type="radio"
                      name="payment_method"
                      defaultChecked={
                        storeDelivery.activePaymentMethod === 4 ? "checked" : ""
                      }
                      onClick={() => {
                        storeDelivery.setActivePaymentMethod(4);
                      }}
                    />
                    PayPal
                    <span className="check-field-img">
                      <img src="/images/svg/paypal.svg" alt="" />
                    </span>
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="forms_section">
            <div className="forms_section_entry">
              <h3 className="uppercase">Комментарий к заказу</h3>
              <div className="input-field">
                <textarea
                  className="input"
                  onKeyUp={this.onChangeComment.bind(this)}
                  defaultValue={storeDelivery.recipientComment}
                />
              </div>
            </div>
          </div>
          {/* {this.state.errorMsg &&
            <div className="forms_section">
              <div className="forms_section_entry">
                <div className="red text-right">{this.state.errorMsg}</div>
              </div>
            </div>
          } */}

          <div className="forms_section flexed">
            <p>
              Нажимая кнопку "ЗАКАЗАТЬ", вы соглашаетесь с{" "}
              <a href="#">Правилами продажи</a> и даете согласие на обработку
              персональных данных.
            </p>
            <Link
              to={""}
              className="btn btn-red"
              onClick={this.onClickSubmit.bind(this)}
            >
              ЗАКАЗАТЬ
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
OrderingStepSecondPage.propTypes = {
  basket: MPropTypes.observableObject,
  storeDelivery: MPropTypes.observableObject,
  cookies: instanceOf(Cookies).isRequired,
};

export default withCookies(OrderingStepSecondPage);

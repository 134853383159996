import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { default as MobileDetect } from 'mobile-detect'
import ProductsPreview from "../catalog/ProductsPreview";


@inject('paginator')
@observer
class Paginator extends React.Component {
  onClickNext = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0);
    this.props.paginator.setNextPage();


  }

  onClickPrev = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0);
    this.props.paginator.setPrevPage();
  }

  onClickPage = (value, e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    this.props.paginator.setPage(value);
  }

  onClickShowMore = (e) => {
    e.preventDefault()
    this.props.paginator.showMore();
  }

  renderShowMore() {
    if (!this.props.paginator.isShowMoreButton) {
      return null
    }

    return (
      <div className="pagination_more">
        <a onClick={this.onClickShowMore} href="">{'Показать еще'}</a>
      </div>
    )
  }

  renderPages() {
    const { paginator } = this.props

    const md = new MobileDetect(window.navigator.userAgent)

    if (md.mobile()) {
      return null
    }

    return (
      <div className="pagination">
        {paginator.isShowPrev &&
          <a onClick={this.onClickPrev} href="" className="pagprev" />}

        {paginator.links.map((obj, index) => {
          if (obj.isTrio) {
            return <span key={index} className="pagination_dots">{'...'}</span>
          }

          if (obj.isActive) {
            return <span key={index} className="pagination_current">{obj.value}</span>
          }

          return (
            <a
              key={index}
              onClick={this.onClickPage.bind(null, obj.value)}
              href="/">

              {obj.value}
            </a>
          )
        })}

        {paginator.isShowNext &&
          <a onClick={this.onClickNext} href="" className="pagnext" />}
      </div>
    )
  }

  render() {
    const { paginator } = this.props

    if (!paginator.isShowPaginator || !paginator.isReady) {
      return null
    }

    return (
      <div className="pagination">
        {this.renderPages()}
      </div>
    )
  }
}


Paginator.propTypes = {
  paginator: MPropTypes.observableObject,
}

export default Paginator
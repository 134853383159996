import React, {Component} from "react";
import { withCookies, Cookies } from 'react-cookie';
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import {Select} from "../../../index";
import {renderHTML, rHTML, rpHTML} from "../../../../services/helpers";
import {instanceOf} from "prop-types";


@inject('general', 'storeCatalog', 'routing')
@observer
class RangesMedGlasses extends Component {

    onChangeOptionDioptrsOS = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_os': val})
        storeCatalog.getRecommendedMedTypeLinz(storeCatalog.maxDiopInEye)
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    onChangeOptionDioptrsOD = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_od': val})
        storeCatalog.getRecommendedMedTypeLinz(storeCatalog.maxDiopInEye)
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    onChangeOptionDioptrsOSCyl = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_os_cyl':val})
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    onChangeOptionDioptrsODCyl = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_od_cyl':val})
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    onChangeOptionDioptrsOSAxis = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_os_axis':val})
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    onChangeOptionDioptrsODAxis = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_od_axis':val})
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    onChangeOptionDioptrsDP = (val) =>{
        const {storeCatalog} = this.props
        storeCatalog.setSelectedParamsMedGlasses({'dioptr_dp':val})
        storeCatalog.pages = null;
        storeCatalog.products = null;
    }

    componentDidUpdate(prevProps) {
        const $ = window.$
        const page = this.props.general.page_object

        $('select[name="dp"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsDP.bind()}
        );

        $('select[name="os"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsOS.bind()}
        );

        $('select[name="od"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsOD.bind()}
        );


        if (page && page.optionRangesAxis.length<=1)
            return

        $('select[name="os_cyl"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsOSCyl.bind()}
        );

        $('select[name="os_axis"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsOSAxis.bind()}
        );

        $('select[name="od_cyl"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsODCyl.bind()}
        );

        $('select[name="od_axis"]').styler(
            {onSelectClosed: this.onChangeOptionDioptrsODAxis.bind()}
        );

    }

    render() {
        const {storeCatalog, general} = this.props
        const page = general.page_object
        let optionDioptrs = []
        if (page){
            optionDioptrs = page.optionDioptrs
        }
        return(
            <React.Fragment>
                {this.props.med && !this.props.dioptr && rpHTML(this.props.general.getLettering("261"))}
                {!this.props.med && this.props.dioptr && rpHTML(this.props.general.getLettering("223"))}

                <div className="tables_dioptres">
                    <table className="table_dioptres">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Sph (дптр.)</th>
                            <th>Cyl (дптр.)</th>
                            <th>Axis, ось (град.)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="table_dioptres_title"><b>OS</b> (левый)</td>
                            <td>
                                {optionDioptrs.length>1 &&
                                <Select
                                    name="os"
                                    className="selectbox"
                                    options={optionDioptrs}
                                    onChange={this.onChangeOptionDioptrsOS.bind()}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_os}
                                    isOptionDisabled={(option) => option.disabled}
                                />}
                            </td>
                            <td>
                                {page && page.optionRangesCyl.length>1 &&
                                <Select
                                    name="os_cyl"
                                    className="selectbox"
                                    options={page.optionRangesCyl}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_os_cyl > 0? 16: storeCatalog.selectedParamsMedGlasses.dioptr_os_cyl}
                                    onChange={this.onChangeOptionDioptrsOSCyl.bind()}
                                    isOptionDisabled={(option) => option.disabled}
                                />}
                                {page && page.optionRangesCyl.length<=1 &&
                                    <input type="text" name="" className="disabled-input" disabled/>
                                }
                            </td>
                            <td>
                                {page && page.optionRangesAxis.length>1 &&
                                <Select
                                    name="os_axis"
                                    className="selectbox"
                                    options={page.optionRangesAxis}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_os_axis}
                                    onChange={this.onChangeOptionDioptrsOSAxis.bind()}
                                    isOptionDisabled={(option) => option.disabled}
                                />}
                                {page && page.optionRangesAxis.length<=1 &&
                                    <input type="text" name="" className="disabled-input" disabled/>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="table_dioptres_title"><b>OD</b> (правый)</td>
                            <td>
                                {page && page.optionDioptrs.length>1 &&
                                <Select
                                    name="od"
                                    className="selectbox"
                                    options={page.optionDioptrs}
                                    onChange={this.onChangeOptionDioptrsOD.bind()}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_od}
                                    isOptionDisabled={(option) => option.disabled}
                                />}
                                {page && page.optionDioptrs.length<=1 &&
                                    <input type="text" name="" className="disabled-input" disabled/>
                                }
                            </td>
                            <td>
                                {page && page.optionRangesCyl.length>1 &&
                                <Select
                                    name="od_cyl"
                                    className="selectbox"
                                    options={page.optionRangesCyl}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_od_cyl}
                                    onChange={this.onChangeOptionDioptrsODCyl.bind()}
                                    isOptionDisabled={(option) => option.disabled}
                                />}
                                {page && page.optionRangesCyl.length<=1 &&
                                    <input type="text" name="" className="disabled-input" disabled/>
                                }
                            </td>
                            <td>
                                {page && page.optionRangesAxis.length>1 &&
                                <Select
                                    name="od_axis"
                                    className="selectbox"
                                    options={page.optionRangesAxis}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_od_axis}
                                    onChange={this.onChangeOptionDioptrsODAxis.bind()}
                                    isOptionDisabled={(option) => option.disabled}
                                />}
                                {page && page.optionRangesAxis.length<=1 &&
                                    <input type="text" name="" className="disabled-input" disabled/>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <table className="table_dioptres">
                        <thead>
                        <tr>
                            <th>DP (мм)<br/> Межцентровое расстояние</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="table_dioptres_single">
                                {page && page.optionDP.length>0 &&
                                <Select
                                    name="dp"
                                    className="selectbox"
                                    options={page.optionDP}
                                    value={storeCatalog.selectedParamsMedGlasses.dioptr_dp}
                                    onChange={this.onChangeOptionDioptrsDP.bind()}
                                />}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </React.Fragment>
        )
    }
}

RangesMedGlasses.propTypes = {
    general: MPropTypes.observableObject,
    storeCatalog: MPropTypes.observableObject,
    routing: MPropTypes.observableObject,
    cookies: instanceOf(Cookies).isRequired

}

export default withCookies(RangesMedGlasses)
import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'

import { RightMenuItem } from 'components'
import {URL_CATALOG_COMP, URL_CATALOG_MED} from "api";


@inject('general', 'storeCatalog', 'routing')
@observer
class RightMenu extends React.Component {

  obClickDiopt = (e) => {
    const path_split = this.props.routing.location.pathname.split('/')

    if (path_split < 3) {
      return
    }

    if (e.target.checked === true) {
      console.log(path_split[1], typeof(path_split[1]))
      if (path_split[1] === ""){
        this.props.routing.history.push(URL_CATALOG_MED)
      }
      else{
        this.props.routing.history.push('/' + path_split[1] + '/dioptr/')
      }

    } else {
      if (path_split[1] === 'catalogue_med') {
        this.props.routing.history.push(URL_CATALOG_COMP)
      } else {
        this.props.routing.history.push('/' + path_split[1] + '/')
      }

    }
  }

  render() {
    const { storeCatalog, general } = this.props
    return (
      <React.Fragment>
        <div className="filterbox_title">Каталог</div>
        <div className="filterbox_wrap">
          <ul className="filterbox_nav">
            {storeCatalog.catalog_menu && storeCatalog.catalog_menu.map((obj) => {
              const key_ = 'rm' + obj.id.toString()
              return (<RightMenuItem key={key_} item={obj} index={obj.id} classBorder={obj.catclass} />)
            })
            }
          </ul>
        </div>
        {general.page_object && general.page_object.catdioptr &&
          <div className="filterbox_wrap">
            <div className="filterbox_line dark">
              <label>
                {general.activeDioptCatalog &&
                 <input type="checkbox" defaultChecked={true} onClick={this.obClickDiopt}/>
                }
                {!general.activeDioptCatalog &&
                <input type="checkbox" onClick={this.obClickDiopt}/>
                }
                Диоптрии
              </label>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

RightMenu.propTypes = {
  general: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
}
export default RightMenu
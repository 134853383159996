import React, { Component, setState } from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import {headerCatalogPage, rpHTML} from "../../services/helpers";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, Navigation, Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper';
import { Link } from "react-router-dom";

import 'swiper/swiper-bundle.css';
import 'swiper/swiper.scss';
import { URL_CATALOG } from "../../api";
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs, EffectCoverflow]);

@inject('basket', 'storeCatalog', 'routing')
@observer
class ProductPage extends Component {
  state = {
    activePhoto: 0,
    thumbsSwiper: null,
    setThumbsSwiper: null,
    slideCount: window.innerWidth > 1019 ? 4 : window.innerWidth > 748 ? 3 : window.innerWidth > 479 ? 2 : 1,
  }

  componentDidMount() {
    this.loadProduct()
    this.onClickTransitionProduct()
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.routing.location.pathname !== prevProps.location.pathname) {
      this.loadProduct()
      this.onClickTransitionProduct()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    const slideCount = newWidth > 1019 ? 4 : newWidth > 748 ? 3 : newWidth > 479 ? 2 : 1
    this.setState({ slideCount });
  };

  onAddToCart = (idProduct, e) => {
    e.preventDefault();
    this.props.basket.addToCart(idProduct, this.props.storeCatalog.activeColr.key);
  }

  onClickBackToCatalog = (e) => {
    this.props.routing.history.push(URL_CATALOG)
  }

  loadProduct() {
    const { storeCatalog } = this.props
    storeCatalog.loadProduct(this.props.routing.location.pathname)
  }

  renderTablesDioptres() {
    const { storeCatalog } = this.props
    if (storeCatalog.product.diop === false) {
      return ''
    }
    return (
      <React.Fragment>
        <div className="tables_dioptres_title">Ваши значения</div>

        <div className="tables_dioptres">
          <table className="table_dioptres">
            <thead>
              <tr>
                <th></th>
                <th>Sph (дптр.)</th>
                <th>Cyl (дптр.)</th>
                <th>Axis, ось (град.)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table_dioptres_title"><b>OS</b> (левый)</td>
                <td><input type="text" className="input" defaultValue={storeCatalog.selectedParamsMedGlasses.dioptr_os} disabled /></td>
                <td><input type="text" className="input" defaultValue={storeCatalog.product.med ? storeCatalog.selectedParamsMedGlasses.dioptr_os_cyl: ""} disabled /></td>
                <td><input type="text" className="input" defaultValue={storeCatalog.product.med ? storeCatalog.selectedParamsMedGlasses.dioptr_os_axis: ""} disabled /></td>
              </tr>
              <tr>
                <td className="table_dioptres_title"><b>OD</b> (правый)</td>
                <td><input type="text" className="input" defaultValue={storeCatalog.selectedParamsMedGlasses.dioptr_od} disabled /></td>
                <td><input type="text" className="input" defaultValue={storeCatalog.product.med ? storeCatalog.selectedParamsMedGlasses.dioptr_od_cyl: ""} disabled /></td>
                <td><input type="text" className="input" defaultValue={storeCatalog.product.med ? storeCatalog.selectedParamsMedGlasses.dioptr_od_axis: ""} disabled /></td>
              </tr>
            </tbody>
          </table>


          <table className="table_dioptres">
            <thead>
              <tr>
                <th>DP (мм)<br /> Межцентровое расстояние</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table_dioptres_single">
                  <input type="text" className="input" defaultValue={storeCatalog.selectedParamsMedGlasses.dioptr_dp.toString()} disabled />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </React.Fragment>
    )
  }

  renderProductPhotos() {
    const { storeCatalog } = this.props
    if (!storeCatalog.activeColr) {
      return
    }
    return (
      <React.Fragment>
        <div className="gallery_top">
          <Swiper
            spaceBetween={50}
            thumbs={{ swiper: this.state.thumbsSwiper }}
            pagination={{ clickable: true }}
          >
            {storeCatalog.activeColr.photos.map((obj, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <a href={obj.pic_url} >
                      <img
                        src={"/media/prodpics/" + obj[0] + "." + obj[1]}
                        alt="" />
                    </a>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </React.Fragment>
    )
  }

  renderProductSmallPhotos() {
    const { storeCatalog } = this.props
    if (!storeCatalog.activeColr) {
      return
    }
    return (
      <React.Fragment>
        <div className="gallery_thumbs">
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            onSwiper={(swiper) => {this.setState({ thumbsSwiper: swiper }) }}
            freeMode
            watchSlidesVisibility
            watchSlidesProgress
          >
            {storeCatalog.activeColr.photos.map((obj, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={"/media/prodpics/" + obj[0] + "_s." + obj[1]} alt="" />
                </SwiperSlide>

              )
            })}
          </Swiper>
        </div>
      </React.Fragment>
    )
  }

  onClickTransitionProduct = (e) => {
    const yOffset = -50;
    const element = document.getElementById("breadcrumbs")

    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, left: 0, behavior: "smooth" })
    }
  }

  onClickColr = (index, e) => {
    this.props.storeCatalog.setActiveColr(this.product.colorsdic[index])
    this.setState({ activeColr: this.product.colorsdic[index] })
  }

  renderColrs() {
    const { storeCatalog } = this.props
    if (!storeCatalog.activeColr || !storeCatalog.product) {
      return
    }
    return (
      <React.Fragment>
        <div className="product_module_content">
          {storeCatalog.product && storeCatalog.product.artikul && <p>артикул: <b>{storeCatalog.product.artikul}</b></p> }
          {storeCatalog.product && storeCatalog.product.catdisplay && storeCatalog.product.catdisplay !="---" && <p>категория: {storeCatalog.product.catdisplay}</p>}
          {storeCatalog.activeColr.name && <p>цвет: <b>{storeCatalog.activeColr.name}</b></p>}
          {storeCatalog.product.colorsdic.map((colr, index) => {
            const style1 = { backgroundColor: colr.css1 }
            const style2 = { backgroundColor: colr.css2 }
            return (
              <label className="choose_color" key={index} onClick={this.onClickColr.bind(null, index)}>
                <input
                  type="radio"
                  name={this.product.artikul}
                  defaultValue={index}
                  defaultChecked={index === 0}
                  onClick={this.onClickColr.bind(null, index)}
                />
                <span className="choose_color_col" style={style1}></span>
                <span className="choose_color_col" style={style2}></span>
                <span className="tooltip">{colr.name}</span>
              </label>
            )
          })}
        </div>
      </React.Fragment>
    )
  }

  renderMarkers() {
    return (
      <div className="productbox_markers">
        {this.product.novinka && <div className="productbox_new">new</div>}
        {this.product.hit && <div className="productbox_hit">Хит</div>}
      </div>
    )
  }

  renderUse() {
    const { storeCatalog } = this.props
    return (
      <React.Fragment>
        {storeCatalog.product.icons.length > 0 &&
          <div className="product_module_content flexed">
            <div className="product_module_title">ПРИМЕНЕНИЕ</div>
            {storeCatalog.product.icons.map((icon, index) => {
              return (
                <React.Fragment key={index}>
                  <img src={icon} alt="" />
                </React.Fragment>
              )
            })}
          </div>
          }
      </React.Fragment>
    )
  }


  render() {
    const { storeCatalog } = this.props
    this.product = storeCatalog.product
    if (!storeCatalog.product) {
      return ('')
    }

    if (!storeCatalog.product.colorsdic) {
      return ('')
    }
    let blueBlockOrPhotoChrome = storeCatalog.product.blueBlock || (storeCatalog.product.photochrome)
    let banerHeader = headerCatalogPage(storeCatalog.product.category)
    let productbox_className = storeCatalog.product.discount > 0 ? "productbox_discount_price": "productbox_price"
    return (
      <React.Fragment>
        <div className="container">
          {banerHeader}
          <div id="breadcrumbs" className="breadcrumbs">
            <ol className="breadcrumbs_nav">
              <li className="breadcrumb-item">
                <Link to="/">Главная</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/" + this.product.categoryUrl+ "/"}>{storeCatalog.product.name} </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={this.product.url}>{storeCatalog.product.artikul}</Link>
              </li>
            </ol>
          </div>
          {this.renderTablesDioptres()}


          <h1 className="small">{storeCatalog.product.name}</h1>

          <div className="row product_page">
            <div className="grid-8 tb12">
              <div className="gallery">

                {(storeCatalog.product.diop || storeCatalog.product.med) && <span className="banner_home_plus"><b>+/-</b></span>}
                {this.renderProductPhotos()}
                {this.renderProductSmallPhotos()}
              </div>
            </div>


            <div className="grid-4 tb12">
              <div className="product_module">
                {this.renderUse()}
                {this.renderColrs()}

                <div className="product_module_content flexed justify">
                  <div className={productbox_className}>{storeCatalog.product.discountedPrice} р.</div>
                  <Link to="#" className="btn btn-grey" onClick={this.onAddToCart.bind(null, storeCatalog.product.id)}>В корзину</Link>
                </div>
              </div>

              <Link to="#" className="btn btn-grey block" onClick={this.onClickBackToCatalog}>Вернуться в каталог</Link>
            </div>

          </div>


          <div className="product_module">
            <div className="product_module_content">
              <blockquote>
                {rpHTML(storeCatalog.product.description)}
              </blockquote>
              <div className="product_module_title">ПОДРОБНАЯ ИНФОРМАЦИЯ О МОДЕЛИ:</div>
                <ul className="featbox">
                  {storeCatalog.product.aksgabarit && storeCatalog.product.aksgabarit != "---" &&
                  <li>Габариты: {storeCatalog.product.aksgabarit}</li>}

                  {storeCatalog.product.aksmaterial && storeCatalog.product.aksmaterial != "---" &&
                  <li>Материал: {storeCatalog.product.aksmaterial}</li>}

                  {storeCatalog.product.formadisplay && storeCatalog.product.formadisplay != "---" &&
                  <li>Форма: {storeCatalog.product.formadisplay}</li>}
                  {storeCatalog.product.shirinadisplay && storeCatalog.product.shirinadisplay != "---" &&
                  <li>Ширина: {storeCatalog.product.shirinadisplay}</li>}
                  {storeCatalog.product.konstrdisplay && storeCatalog.product.konstrdisplay != "---" &&
                  <li>Тип оправы: {storeCatalog.product.konstrdisplay}</li>}
                  {storeCatalog.product.geometrydisplay && storeCatalog.product.geometrydisplay != "---" &&
                  <li>Геометрия: {storeCatalog.product.geometrydisplay}</li>}
                  {storeCatalog.product.nanosnikidisplay && storeCatalog.product.nanosnikidisplay != "---" &&
                  <li>Наносники: {storeCatalog.product.nanosnikidisplay}</li>}
                  {storeCatalog.product.material1 && storeCatalog.product.material1 != "---" && <li>Материал
                    оправы: {storeCatalog.product.material1} {storeCatalog.product.material2 && "+" + storeCatalog.product.material2}</li>}
                  {storeCatalog.product.materiallinz && storeCatalog.product.materiallinz != "---" && storeCatalog.product.materiallinz != "Отсутствует" &&
                  <li>Материал линз: {storeCatalog.product.materiallinz}</li>}
                  {storeCatalog.product.weight && <li>Вес: {storeCatalog.product.weight} гр.</li>}
                  {(storeCatalog.product.gshirina > 0 || storeCatalog.product.gvisota > 0 || storeCatalog.product.width > 0) &&
                  <li>Габариты, мм:</li>}
                </ul>
              {(storeCatalog.product.gshirina > 0 || storeCatalog.product.gvisota > 0 || storeCatalog.product.width > 0) &&
                <div className="glass_map_row">
                  <div className="glass_map">
                    <img src="/images/svg/front.svg" alt=""/>
                    <div className="glass_map_text txt1">{storeCatalog.product.gshirina}</div>
                    <div className="glass_map_text txt2">{storeCatalog.product.gshirinap}</div>
                    <div className="glass_map_text txt3">{storeCatalog.product.gvisota}</div>
                    <div className="glass_map_text txt4">{storeCatalog.product.width}</div>
                  </div>
                  {storeCatalog.product.gdlina &&
                    <div className="glass_map">
                      <img src="/images/svg/temple.svg" alt=""/>
                      <div className="glass_map_text txt4">{storeCatalog.product.gdlina}</div>
                    </div>
                  }
                </div>
              }
              { (storeCatalog.product.material1 || storeCatalog.product.material2) &&
                <blockquote>
                  {storeCatalog.product.material1 &&
                  <p><b>{storeCatalog.product.material1}:</b> {storeCatalog.product.materialDescription1}</p>
                  }
                  {storeCatalog.product.material2 &&
                  <p><b>{storeCatalog.product.material2}:</b> {storeCatalog.product.materialDescription2}</p>
                  }
                </blockquote>
              }
              { storeCatalog.product.med && storeCatalog.product.medtypelinz &&
              <blockquote>
                <p><b>Тип линз:</b> {storeCatalog.product.medtypelinz} {blueBlockOrPhotoChrome}</p>
              </blockquote>
              }
            </div>
          </div>

          {storeCatalog.product.similar.length > 0 &&
            <div className="related">
              <div className="related_title">Похожие модели</div>
              <div className="related_slider">
                <OwlCarousel
                  className={"swiper-container"}
                  navClass={["swiper-button-prev", "swiper-button-next"]}
                  dotsClass={"swiper-pagination swiper-pagination-bullets"}
                  dotClass={"swiper-pagination-bullet product-slider__bullet"}
                  navText={['', '']}
                  items={this.state.slideCount}
                  dots={true}
                  margin={10}
                >
                  {storeCatalog.product.similar.map((obj, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="related_grid">
                          <Link className="related_img" to={obj.url}>
                            <img src={"/media/prodpics/" + obj.mediumpic} alt="" />
                          </Link>
                          <div className="related_entry">
                            <p>Артикул: <b>{obj.artikul}</b></p>
                            <p><b>{obj.Price.replace(".00","")} р.</b></p>
                            <Link to={obj.url} className="btn btn-dark">
                              Перейти
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </OwlCarousel>
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

ProductPage.propTypes = {
  basket: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject,
}

export default ProductPage

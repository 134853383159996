import Model from './private/Model'

import { getDeliveryStore } from 'stores'

export default class BoxberryModel extends Model {

  get latitude() {
    return this.GPS.split(',')[0]
  }
  get longitude() {
    return this.GPS.split(',')[1]
  }

  get namePoint() {
    return this.Name
  }

  get _code() {
    return this.Code
  }

  get address() {
    return this.Address
  }

  get workHours() {
    return this.WorkShedule
  }

  get deliveryPeriod() {
    return null
  }

  get company() {
    return "Boxberry"
  }
  get comment() {
    return this.TripDescription
  }

  get value() {
    return this._code
  }

  get text() {
    return "Boxberry: " + this.address
  }

  get iconImageHref() {
    return '/images/yandex-box.svg'
  }

  get iconImageSize() {
    return [40, 40]
  }

  get deliveryPointInfo() {
    const deliveryStore = getDeliveryStore()
    const cost = deliveryStore.deliveryPointBoxberryCost?.pickup?.cost?.toString()?.slice(0, -1);
    const deliveryMethodName = deliveryStore.getStrMethod(deliveryStore.BOXBERRY);
    const deliveryMethodPeriod = deliveryStore.deliveryPointBoxberryCost?.pickup?.period
    return `
      ${deliveryMethodName} - ${cost}, ${deliveryMethodPeriod}
    `
  }

  get active() {
    const deliveryStore = getDeliveryStore()
    return deliveryStore.activeDeliveryPoint == this
  }

  get code() {
    return this.Code
  }

}
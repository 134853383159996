import Model from './private/Model'

export default class CountryModel extends Model{

    get value(){
        return this.id
    }
    get text(){
        return this.name
    }
}
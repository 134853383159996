import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
/**
 * Simple select.
 * Default value can be passed via props: "value"
 * isValueFromParent controls component either from parent
 * or internally.
 */

// display: block;
// box - sizing: border - box;
// height: 38px;
// border: 1px solid #ccc;
// border - radius: 4px;
// width: 100 %;
// font - size: 16px;
// padding: 0 10px;
// outline: none;
// color: #636363;
// font - size: 13px!important;
// font - family: "Fedra Sans Book 3"!important;
// background - color: white;

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false,
      value: this.props.value ||
        (this.props.options &&
          this.props.options.length > 0 &&
          this.props.options[0].value),
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState(state => ({isToggleOn: false}));
    }
  }

  handleClick(val, e) {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn,
      value: val.value
    }));
    if (this.props.onChange) {
      this.props.onChange(val.value);
    }
  }

  toggle(e) {
    const $ = window.$
    $('.select .opened').removeClass('opened');
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  close(e) {
    console.log(e.target)
    this.setState(state => ({
      isToggleOn: false
    }));
  }


  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  onMouseOut = (e) =>{
    this.setState({ isToggleOn: false });
  }
  onMouseOver = (e) =>{
    this.setState({ isToggleOn: true });
  }

  get hasError() {
    const { errors, name } = this.props;
    if (errors && errors.hasOwnProperty(name)) {
      return true;
    }
    return false;
  }

  renderErrors() {
    const { errors, name } = this.props;
    if (errors && errors.hasOwnProperty(name)) {
      return (
        <React.Fragment>
          {errors[name].map((message, index) => {
            return (
              <div key={index} className="error_text">
                {message}
              </div>
            );
          })}
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    let {options } = this.props;

    let value = options.find(item => item.value === this.state.value || item.value === this.props.value );
    let className = "custom_select"
    if (this.state.isToggleOn)
      className += " opened";
    if (this.hasError) {
      className += " error";
    }

    return (
      <div className="select" ref={this.setWrapperRef}>
        <div
            className={className}
        >
          <div className="custom_select-select" onClick={this.toggle}>{value?.text}</div>
          <div className="custom_select-dropdown">
            {options &&
            options.map((obj, index) => {
              return (
                  <div
                      key={index}
                      value={obj.value}
                      className="custom_select-item"
                      onClick={this.handleClick.bind(this, obj)}
                  >{obj.text}</div>
              );
            })}
          </div>
          {this.renderErrors()}
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  hasWrapper: PropTypes.bool,
  isValueFromParent: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      text: PropTypes.any,
    })
  ).isRequired,
  value: PropTypes.any,
  wrapperClassName: PropTypes.string,
};

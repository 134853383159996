import CostModel from "./private/CostModel";

export default class CdekCostModel extends CostModel{
    get cost(){
        return this.getCost('totalSum')
    }

    get costCourier(){
        return this.getCost('totalSum', true)
    }

    get intPeriod(){
        return this.periodMax
    }

    get period(){
        return this.getPeriod(this.periodMax)
    }
}
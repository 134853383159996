import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'


@inject('general', 'storeCatalog', 'routing')
@observer
class RightFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }
  clickShowFilter = e =>{
    e.preventDefault()
    window.$('.filterbox_hide').slideToggle(200);
    return false;
  }

  clickClearFilterParams = e => {
    window.$('.filterbox_line input').prop('checked', false)
    this.setState({})
    this.props.general.loadContentItem(this.props.routing.location.pathname);
  }

  clickAllColors = e => {
    let $ = window.$
    let filterColorsBox = $('.filterbox_colors_inner').outerHeight();
    let t = $('.allcolors')

    e.preventDefault()
    if ( !t.hasClass('opened') ) {
      $('.filterbox_colors').css('height', filterColorsBox);
      t.addClass('opened');
    } else {
      $('.filterbox_colors').removeAttr('style');
      t.removeClass('opened');
    }
    return false;
  }


  onChangeFilter(fieldName, value, e) {
    const { storeCatalog, general } = this.props
    let new_values = []

    if (this.state.hasOwnProperty(fieldName)) {
      new_values = this.state[fieldName]
    }
    if (e.target.checked) {
      if(!new_values.includes(value))
        {new_values.push(value)}

    } else {
      new_values.splice(new_values.indexOf(value), 1)
    }

    let params = this.state
    params['category'] = general.page_object.id

    if (new_values.length > 0) {
      params[fieldName] = new_values
    } else {
      delete params[[fieldName]]
    }
    this.setState(params)
    storeCatalog.loadProducts(params)
  }

  onChangeBoolFilter(fieldName, e) {
    const { storeCatalog, general } = this.props
    let params = this.state
    params['category'] = general.page_object.id
    if (e.target.checked) {
      params[[fieldName]] = 1
    } else {
      delete params[[fieldName]]
    }
    this.setState(params)
    storeCatalog.loadProducts(params)
  }

  renderFilterTypes(title, arr, field_name, popup=false, color_icon=false) {
    if (arr.length == 0) {
      return ""
    }
    return (
      <React.Fragment>
        <div className="filterbox_title">{title}</div>
        <div className="filterbox_wrap">
          {arr && arr.map((obj, index) => {
            return (
              <div className="filterbox_line " rel={obj[0]} key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={this.onChangeFilter.bind(this, field_name, obj[0])}
                  />
                  {obj[1]}
                </label>
                {popup &&
                  <div className="question_icon">
                    <div className="tooltip">{obj[2]}</div>
                  </div>
                }
                {color_icon && <div className="color_icon" style={{backgroundColor: obj[2]}}></div>}
              </div>
            )
          })
          }
        </div>
      </React.Fragment>
    )
  }

  render() {
    const page_object = this.props.general.page_object
    const style_backgroundBlack = { backgroundColor: "#000" }
    if (!page_object || !page_object.catalog) {
      return ""
    }
    return (
      <React.Fragment>
        <a href="#" className="btn show_filterbox" onClick={this.clickShowFilter.bind(this)}>Фильтр</a>
        <div className="filterbox_hide">
          {this.renderFilterTypes("Форма оправы", page_object.catalog.ftypes, 'forma')}
          {this.renderFilterTypes("Ширина", page_object.catalog.stypes, 'shirina')}
          {this.renderFilterTypes("Геометрия", page_object.catalog.gtypes, 'geometry')}
          {this.renderFilterTypes("Конструкция", page_object.catalog.ktypes, 'konstr')}
          {this.renderFilterTypes("Наносники", page_object.catalog.ntypes, 'nanosniki')}
          {this.renderFilterTypes("Материал оправы",
            page_object.catalog.matdic.metal.concat(page_object.catalog.matdic.plastic), 'material', true)}
          <div className="filterbox_colors">
            <div className="filterbox_colors_inner">
              {this.renderFilterTypes("Цвет оправы",
                page_object.catalog.coldic.main.concat(page_object.catalog.coldic.dop),
                  'color', false, true)}
            </div>
          </div>
          <a href="#" className="allcolors" onClick={this.clickAllColors.bind(this)}>Все цвета</a>
          {this.renderFilterTypes("Категория очков", page_object.catalog.ctypes, 'cat')}
          <div className="filterbox_title">Дополнительные</div>
          <div className="filterbox_line dark">
            <label>
              <input
                type="checkbox"
                onChange={this.onChangeBoolFilter.bind(this, 'novinka')}
              />
              Новые модели
            </label>


          </div>
          <div className="filterbox_line dark">
            <label>
              <input
                type="checkbox"
                onChange={this.onChangeBoolFilter.bind(this, 'discount')}
              />
              Скидка
            </label>


          </div>
          <div className="filterbox_line dark">
            <label>
              <input
                type="checkbox"
                onChange={this.onChangeBoolFilter.bind(this, 'hit')}
              />
              Хит продаж
            </label>


          </div>
          <button className="btn btn-dark" onClick={this.clickClearFilterParams.bind(this)}>Сбросить все фильтры</button>
        </div>
      </React.Fragment>

    )
  }
}

RightFilter.propTypes = {
  routing: MPropTypes.observableObject,
  general: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject
}
export default RightFilter
import React from 'react'

import {
  URL_ABOUT_COMP,
  URL_ABOUT_ACTIVE_SUN,
  URL_ABOUT_ACTIVE_SPG,
  URL_ABOUT_REHABIL,
  URL_ABOUT_POLAR,
  URL_ABOUT_PINHOLE,
  URL_ABOUT_ANTIFARI,

  URL_CATALOG_OPTIKA,
  URL_CATALOG_COMP,
  URL_CATALOG_GAMER,
  URL_CATALOG_DRIVER_SUN,
  URL_CATALOG_DRIVER_NIGHT,
  URL_CATALOG_ACTIVE,
  URL_CATALOG_ACTIVE_SUN,
  URL_CATALOG_REHABIL_SUN,
  URL_CATALOG_REHABIL_SUN_GRAD,
  URL_CATALOG_POLAR_BROWN,
  URL_CATALOG_POLAR_GREEN,
  URL_CATALOG_POLAR_GREY,
  URL_CATALOG_CLIPS,
  URL_CATALOG_PINHOLE,
  URL_CATALOG_ANTIFARI,
  URL_CATALOG_ACCESSORIES,
  URL_ABOUT_DRIVER_NIGHT,
  URL_ABOUT_DRIVER_SUN,
  URL_CATALOG_MED,
} from "api";

import {
  HeadBannersKomp,
  HeadBannersDrivers,
  HeadBannersDriversSun,
  HeadBannersGames,
  HeadBannersActive,
  HeadBannersActiveSun,
  HeadBannersRehabSun,
  HeadBannersPolar,
  HeadBannersClips,
  HeadBannersPinHole,
  HeadBannersAntifari,
  HeadBannersAccessories,
  HeadBannersMedGlasses
} from 'components'

const MobileDetect = require('mobile-detect')


export function headerCatalogPage(url, buy_text = false, med_or_diopt = false) {
  let banerHeader
  if(med_or_diopt){
    return <HeadBannersMedGlasses buy={buy_text} />
  }
  switch (url) {
    case URL_CATALOG_MED:
      banerHeader = <HeadBannersMedGlasses buy={buy_text} />
      break;
    case URL_ABOUT_COMP:
    case URL_CATALOG_COMP:
    case 6:
      banerHeader = <HeadBannersKomp buy={buy_text} />
      break;
    case URL_CATALOG_GAMER:
    case 48:
      banerHeader = <HeadBannersGames buy={buy_text} />
      break;
    case URL_ABOUT_DRIVER_SUN:
    case URL_CATALOG_DRIVER_SUN:
    case 25:
      banerHeader = <HeadBannersDriversSun buy={buy_text} />
      break;
    case URL_ABOUT_DRIVER_NIGHT:
    case URL_CATALOG_DRIVER_NIGHT:
    case 24:
      banerHeader = <HeadBannersDrivers buy={buy_text} />
      break;
    case URL_CATALOG_ACTIVE:
    case URL_ABOUT_ACTIVE_SPG:
    case 26:
      banerHeader = <HeadBannersActive buy={buy_text} />
      break;
    case URL_ABOUT_ACTIVE_SUN:
    case URL_CATALOG_ACTIVE_SUN:
    case 27:
      banerHeader = <HeadBannersActiveSun buy={buy_text} />
      break;
    case URL_ABOUT_REHABIL:
    case URL_CATALOG_REHABIL_SUN:
    case 30:
      banerHeader = <HeadBannersRehabSun buy={buy_text} />
      break;
    case URL_CATALOG_REHABIL_SUN_GRAD:
    case 31:
      banerHeader = <HeadBannersRehabSun buy={buy_text} />
      break;
    case URL_ABOUT_POLAR:
    case URL_CATALOG_POLAR_BROWN:
    case 50:
      banerHeader = <HeadBannersPolar buy={buy_text} />
      break;
    case URL_CATALOG_POLAR_GREEN:
    case 52:
      banerHeader = <HeadBannersPolar buy={buy_text} />
      break;
    case URL_CATALOG_POLAR_GREY:
    case 51:
      banerHeader = <HeadBannersPolar buy={buy_text} />
      break;
    case URL_CATALOG_CLIPS:
    case 11:
      banerHeader = <HeadBannersClips buy={buy_text} />
      break;
    case URL_CATALOG_PINHOLE:
    case URL_ABOUT_PINHOLE:
    case 12:
      banerHeader = <HeadBannersPinHole buy={buy_text} />
      break;
    case URL_CATALOG_ANTIFARI:
    case URL_ABOUT_ANTIFARI:
    case 13:
      banerHeader = <HeadBannersAntifari buy={buy_text} />
      break;
    case URL_CATALOG_ACCESSORIES:
    case 14:
      banerHeader = <HeadBannersAccessories buy={buy_text} />
      break;
    default:
      banerHeader = <HeadBannersKomp buy={buy_text} />
      break;
  }
  return banerHeader
}

export function getErrors(errors, name) {
  if (errors === null) {
    return []
  }

  if (name === null) {
    name = 'errors'
  }

  let errors_list = []
  if (errors !== null) {
    for (let label in errors) {
      if (label === name) {
        for (let error of errors[label]) {
          errors_list.push(error)
        }
      }
    }
  }
  return errors_list
}

// if error return {isSuccess: false, errorCode: error_code}
// if success return {isSuccess: true}
// errorCodes - 1: password length < 8, 2: passwords do not match
export function checkPasswords(password1, password2) {
  if (password1.length < 8) {
    return { isSuccess: false, errorCode: 1 }
  }

  if (password1 !== password2) {
    return { isSuccess: false, errorCode: 2 }
  }

  return { isSuccess: true }
}

export function getInitials(s) {
  let listWords = s.split(' ')
  if (listWords.length > 1) {
    return (
      listWords[0].slice(0, 1).toUpperCase() +
      listWords[1].slice(0, 1).toUpperCase()
    )
  }

  if (s.length > 1) {
    return s.slice(0, 2).toUpperCase()
  }

  return s.toUpperCase()
}

export function isHex(s) {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(s)
}

/**
 * intFields - is array
 * The main purpose is for "select" fields,
 * where in most cases values are integers FK
 */
export function formToObject(form, intFields) {
  let res = {}
  for (let el of form.elements) {
    if (el.type === 'submit') {
      continue
    }
    let value = el.value
    if (typeof value === 'string') {
      value = value.trim()
    }

    if (el.getAttribute('valuetype') === 'bool') {
      if (value === 'false') {
        value = false
      } else {
        value = true
      }
    }

    if (el.getAttribute('type') === 'hidden' && value.length === 0) {
      value = null
    }

    if (el.getAttribute('valuetype') === 'list') {
      if (!value) {
        value = null
      } else {
        value = value.split(',')
        if (el.getAttribute('listtype') === 'int') {
          value = value.map(obj => parseInt(obj, 10))
        }
      }
    }

    res[el.name] = value
  }

  if (intFields) {
    for (let k in res) {
      if (intFields.includes(k)) {
        if (res[k] === '') {
          res[k] = null
        } else {
          res[k] = parseInt(res[k], 10)
        }
      }
    }
  }

  return res
}

export function constructUrlWithParams(baseUrl, params) {
  let url = baseUrl
  let i = 0
  for (let key in params) {
    if (i === 0) {
      url += `?${key}=${params[key]}`
    } else {
      url += `&${key}=${params[key]}`
    }
    i++
  }
  return url
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

export function toCamelCase(str) {
  return str
    .replace(/(_|-)([a-z])/g, str => str[1].toUpperCase())
}

export function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.mobile()
}

export function validateEmail(email) {
  var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  if (!regex.test(email)) {
    return false
  }
  return true
}

export function logMessage(...args) {
  if (process.env.NODE_ENV === 'development') {
    for (let message of args) {
      console.log(message)
    }
  }
}

export function logGroupMessage(groupName, ...args) {
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(groupName)
    for (let message of args) {
    }
    console.groupEnd()
  }
}

/**
 * div wrapper
 */
export function rHTML(html, className) {
  if (className) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}

/**
 * p wrapper
 */
export function rpHTML(html, className) {
  if (className) {
    return (
      <p
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }
  return <p dangerouslySetInnerHTML={{ __html: html }} />
}

export function renderHTML(html) {
  return <q dangerouslySetInnerHTML={{ __html: html }} />
}

export function spanHTML(html) {
  return <pan dangerouslySetInnerHTML={{ __html: html }} />
}

export function getWindowWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

export function getWindowHeight() {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  )
}


export function getTplNamePage(tpl) {
  const regex = /site\/(\w*).html/gm;

  let m;
  let result = ''
  while ((m = regex.exec(tpl)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    if (m.length > 0) {
      result = m[1]
      result = toCamelCase(result)
      result = result[0].toUpperCase() + result.slice(1) + 'Page'
    }
  }
  return result
}

export function ucFirst(str) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
}

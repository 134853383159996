import React, { Component } from 'react'
import {Link} from "react-router-dom";
import {inject, PropTypes as MPropTypes} from 'mobx-react'
import {rHTML} from "../../services/helpers";

@inject("general")
class OrderWholesaleIsProcessedPage extends Component {
    render(){
        const { general } = this.props;
        return (
            <div className="container">

                <div className="breadcrumbs">
                    <ol className="breadcrumbs_nav">
                        <li className="breadcrumb-item">
                            <Link to="/">Главная</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="#">Служба поддержки</a>
                        </li>
                    </ol>
                </div>
                <h1>Оформление заказа</h1>
                <div className="article">
                    {rHTML(general.getLettering("252"))}
                </div>
            </div>
        )
    }
}

OrderWholesaleIsProcessedPage.propTypes = {
    general: MPropTypes.observableObject,
}

export default OrderWholesaleIsProcessedPage
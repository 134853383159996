import React from 'react'
import { Link } from "react-router-dom";
import {rHTML} from 'services/helpers.js'

const PdfLink = ({ pdf_file }) => {
    return (
        <Link to={"/media" + pdf_file?.showurl} className="metro_line metro_line_wicon" target="_blank">
            <img src="/images/pdf.svg" alt="" />
            <span>
                  <b>{pdf_file?.anchor}</b> {rHTML(pdf_file?.text)} ({pdf_file?.size} Кб)
                </span>
        </Link>
    )
}

export default PdfLink;
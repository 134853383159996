import React, { Suspense, Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

@inject("general", "routing")
@observer
class MenuItemPage extends Component {
  componentDidMount() {
    this.getItem(this.props.routing.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.routing.location.pathname !== prevProps.location.pathname) {
      const url = this.props.routing.location.pathname;
      this.getItem(url);
    }
  }

  getItem(url) {
    const { general } = this.props;
    general.loadContentItem(url);
  }

  render() {
    const { general } = this.props;
    let OtherComponent = null;
    console.log(general.pageComponent)
    if (general.pageComponent) {
      try {
        OtherComponent = React.lazy(() => import("./" + general.pageComponent));
      }
      catch (err){
        OtherComponent = null
      }
    }

    return (
      <React.Fragment>
        {OtherComponent && (
          <Suspense fallback={<div>Загрузка...</div>}>
            <section>
              <OtherComponent {...this.props} />
            </section>
          </Suspense>
        )}
      </React.Fragment>
    );
  }
}
MenuItemPage.propTypes = {
  general: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
};

export default MenuItemPage;

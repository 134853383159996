import React, { Component } from 'react'
import {Link} from "react-router-dom";
import {rHTML} from "../../services/helpers";
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'


@inject('basket')
@observer
class OrderIsProcessedPage extends Component {

    render(){
        const { basket } = this.props

        return (
            <div className="container">

                <div className="breadcrumbs">
                    <ol className="breadcrumbs_nav">
                        <li className="breadcrumb-item">
                            <Link to="/">Главная</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="#">Служба поддержки</a>
                        </li>
                    </ol>
                </div>
                <h1>Оформление заказа</h1>
                <div className="article">
                    {rHTML(localStorage.getItem("messageAfterCreatedOrder"))}
                </div>
            </div>
        )
    }
}

OrderIsProcessedPage.propTypes = {
    basket: MPropTypes.observableObject,
}

export default OrderIsProcessedPage
import React from 'react'


class BaseHeadBanners extends React.Component {

    constructor(props) {
        super(props);
        this.text_button = (props.buy) ? "Купить онлайн": "Подробнее о продукции"
    }

}

export default BaseHeadBanners
import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { SelectDeliveryPointItem } from 'components'

const SelectDeliveryPoints = inject('storeDelivery', 'basket')(
  observer(({ storeDelivery, showSelect, basket, isRussia = false }) => {
    // const fittingSurcharge = general.getCompanySetting("130").value /* Надбавка за примерку */
    const isDisabled = (+basket.amount.split(' ').join('') < 4000);
    const deliveryPoint = storeDelivery.activeDeliveryPoint
    // console.log('Надбавка за примерку:', fittingSurcharge);
    return (
      <React.Fragment>
        {
          !isRussia ?
            // мелкий пакет
            <div className="our_country_box">
              <div className="forms_section">
                <div className="forms_section_img"><img src="/images/pochta.png" alt="" /></div>
                <div className="forms_section_entry">
                  <SelectDeliveryPointItem
                    showSelect={showSelect}
                    deliveryPointCost={deliveryPoint}
                    deliveryMethod={storeDelivery.DPD}
                    cost={deliveryPoint?.cost}
                  />
                </div>
              </div>
            </div>
            :
            <React.Fragment>
              {/* сдек */}
              {storeDelivery.deliveryPointCDEKCost && (storeDelivery.deliveryPointCDEKCost.pickup || storeDelivery.deliveryPointCDEKCost.courier) &&
                <div className="our_country_box">
                  <div className="forms_section">
                    <div className="forms_section_img"><img src="/images/cdek.png" alt="" /></div>
                    <div className="forms_section_entry">
                      {storeDelivery.deliveryPointCDEKCost.pickup &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointCDEKCost.pickup}
                          deliveryMethod={storeDelivery.CDEK}
                          cost={storeDelivery.deliveryPointCDEKCost.pickup.cost}
                        />
                      }

                      {storeDelivery.valueChoicePaymentMethod == storeDelivery.PAYMENT_UPON_RECEIPT && storeDelivery.deliveryPointCDEKCost.pickup &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointCDEKCost.pickup}
                          deliveryMethod={storeDelivery.CDEK_PRIM}
                          cost={storeDelivery.deliveryPointCDEKCost.pickup.costCourier}
                        />
                      }
                      {storeDelivery.deliveryPointCDEKCost.courier &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointCDEKCost.courier}
                          deliveryMethod={storeDelivery.CDEK_COURIER}
                          cost={storeDelivery.deliveryPointCDEKCost.courier.costCourier}
                        />
                      }
                    </div>
                  </div>
                </div>
              }

              {/* Почта России */}
              {storeDelivery.deliveryPointPostCost && (storeDelivery.deliveryPointPostCost.pickup || storeDelivery.deliveryPointPostCost.courier) &&
                <div className="our_country_box">
                  <div className="forms_section">
                    <div className="forms_section_img"><img src="/images/pochta.png" alt="" /></div>
                    <div className="forms_section_entry">
                      {storeDelivery.valueChoicePaymentMethod == storeDelivery.PREPAYMENT && storeDelivery.deliveryPointPostCost.pickup &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={{ period: '2 дня', cost: '0 руб.' }}
                          deliveryMethod={storeDelivery.POST_FREE}
                          cost={"0 руб."}
                          isDisabled={isDisabled}
                        />
                      }
                      {storeDelivery.deliveryPointPostCost.pickup &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointPostCost.pickup}
                          deliveryMethod={storeDelivery.POST}
                          cost={storeDelivery.deliveryPointPostCost.pickup.cost}
                        />
                      }
                      {storeDelivery.deliveryPointPostCost.courier &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointPostCost.courier}
                          deliveryMethod={storeDelivery.POST_COURIER}
                          cost={storeDelivery.deliveryPointPostCost.courier.costCourier}
                        />
                      }
                    </div>
                  </div>
                </div>
              }

              {/* BoxBerry */}
              {storeDelivery.deliveryPointBoxberryCost && storeDelivery.deliveryPointBoxberryCost.pickup
                && (storeDelivery.deliveryPointBoxberryCost.pickup.price > 0 || storeDelivery.deliveryPointBoxberryCost.pickup.priceBase > 0) &&
                <div className="our_country_box">
                  <div className="forms_section">
                    <div className="forms_section_img"><img src="/images/boxberry.png" alt="" /></div>
                    <div className="forms_section_entry">
                      {storeDelivery.deliveryPointBoxberryCost.pickup.price > 0 &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointBoxberryCost.pickup}
                          deliveryMethod={storeDelivery.BOXBERRY}
                          cost={storeDelivery.deliveryPointBoxberryCost.pickup.cost}
                        />
                      }
                      {storeDelivery.deliveryPointBoxberryCost.pickup.priceBase > 0 &&
                        <SelectDeliveryPointItem
                          showSelect={showSelect}
                          deliveryPointCost={storeDelivery.deliveryPointBoxberryCost.pickup}
                          deliveryMethod={storeDelivery.BOXBERRY_COURIER}
                          cost={storeDelivery.deliveryPointBoxberryCost.pickup.costCourier}
                        />
                      }
                    </div>
                  </div>
                </div>
              }
            </React.Fragment>
        }
      </React.Fragment>
    )

  }))
SelectDeliveryPoints.propTypes = {
  storeDelivery: MPropTypes.observableObject,
  // general: MPropTypes.observableObject,
}

export default SelectDeliveryPoints
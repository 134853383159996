import React from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { ProductPreview } from 'components'
import { rpHTML, rHTML } from 'services/helpers.js'

@inject('general', 'storeCatalog')
@observer
class ProductsPreview extends React.Component {

  clickPageLink = (link, e) => {
    e.preventDefault()
    window.scrollTo(0, 0);
    this.props.storeCatalog.loadProducts({}, link)
  }

  sortingProducts = (isSortAscending) => {
    const { storeCatalog } = this.props
    if ((storeCatalog.isSortAscending === false && isSortAscending === false) ||
      (storeCatalog.isSortAscending && isSortAscending)) {
      storeCatalog.sortingProducts(null)
    }
    else storeCatalog.sortingProducts(isSortAscending)
  }

  render() {
    const { general, storeCatalog } = this.props
    const mainClassName = (this.props.med == true) ? "md9 tb12" : "grid-80 md9 tb12"
    return (
      <React.Fragment>
        <div className={mainClassName}>
          {this.props.med != true &&
            (general.page_object?.cath1 ? rHTML(general.page_object.cath1) :
              <div className={`pagetitle ${general.page_object?.catclass}`}>
                {general.page_object && rpHTML(general.page_object.h1)}
              </div>
            )}

          <div className="breadcrumbs tb-visible">
            <div className="sorting">
              <span className="sorting_title">Сортировка по цене:</span> {' '}
              <a
                className={storeCatalog.isSortAscending === false ? 'active' : ''}
                href="#"
                onClick={this.sortingProducts.bind(null, false)}
              >по убыванию</a> {" | "}
              <a className={storeCatalog.isSortAscending ? 'active' : ''}
                href="#"
                onClick={this.sortingProducts.bind(null, true)}
              >по возрастанию</a>
            </div>
          </div>

          <div className="row productbox_row">
            {storeCatalog.products &&
              storeCatalog.products.map((obj, index) => {
                return (<ProductPreview key={index} product={obj} />)
              })}
          </div>
          <div className="pagination">
            {storeCatalog.pages &&
              storeCatalog.pages.map((obj, index) => {
                if (!obj.current)
                  return (<Link to={obj.link} key={index} onClick={this.clickPageLink.bind(this, obj.link)}>{obj.key}</Link>)
                else
                  return (<span className="pagination_current" key={index}>{obj.key}</span>)
              })}
          </div>
        </div>

      </React.Fragment >
    )
  }
}

ProductsPreview.propTypes = {
  general: MPropTypes.observableObject,
  storeCatalog: MPropTypes.observableObject
}
export default ProductsPreview
import React from 'react'
import { BaseHeadBanners } from "components"
import { Link } from "react-router-dom";
import { URL_ABOUT_ANTIFARI, URL_CATALOG_ANTIFARI } from 'api';

class HeadBannersAntifari extends BaseHeadBanners {

  render() {
    const style_bigbanner = { backgroundImage: 'url(/images/uploads/banner46.jpg)' }
    const url_to = (this.props.buy) ? URL_CATALOG_ANTIFARI : URL_ABOUT_ANTIFARI

    return (
      <React.Fragment>
        <div className="bigbanner color-black" style={style_bigbanner}>
          <div className="row">
            <div className="grid-12">
              <div className="bigbanner_title color-orange bold-italic">Антифары</div>
              <p className="small"><i>Очки эконом-класса для вождения в ночное время</i></p>
              <Link to={url_to} className="btn btn-grey">{this.text_button}</Link>
            </div>
          </div>
        </div>
      </React.Fragment>

    )
  }
}

export default HeadBannersAntifari
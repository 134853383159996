import {makeObservable, computed, observable, action} from 'mobx'

class PaymentStore {
    constructor(){
        makeObservable(this)
    }

    @observable enableLoading = false


}


const paymentStore = new PaymentStore()

export default paymentStore
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Loading } from 'components'


/**
 * For login, signup, etc urls.
 * If user already auth, these urls doesn't make sense
 */
@inject('user')
@observer
class AuthAlready extends Component{
    componentDidMount() {
        this.props.user.checkToken()
    }

    render(){
        const { user } = this.props
        
        if (user.isAuthenticated === null){
            return <Loading />
        }

        if (user.isAuthenticated){
            return <Redirect to="/account/home" />
        }

        return {...this.props.children}
    }
}


AuthAlready.propTypes = {
    children: PropTypes.node,
    user: MPropTypes.observableObject,
}

export default AuthAlready
import Model from './private/Model'
import { getDeliveryStore } from "../stores";

export default class PostModel extends Model {

  get namePoint() {
    return this.addressSource
  }

  get _code() {
    return this.postalCode
  }

  get address() {
    return this.settlement + ', ' + this.addressSource
  }

  get workHours() {
    const strDays = {
      0: 'Пн.',
      1: 'Вт.',
      2: 'Ср.',
      3: 'Чт.',
      4: 'Пт.',
      5: 'Сб.',
      6: 'Вс.',
    }
    let result = ''
    this.workingHours && this.workingHours.forEach((element, index) => {
      if (element.hasOwnProperty('begin-worktime')) {
        result += strDays[index] + ': ' + element["begin-worktime"].slice(0, 5) + ' - ' + element["end-worktime"].slice(0, 5) + '<br/>'
      } else {
        result += strDays[index] + ': выходной <br/>'
      }
    })

    return result
  }

  get company() {
    return "Почта России"
  }
  get comment() {
    return ''
  }

  get iconImageHref() {
    return '/images/yandex-post.svg'
  }

  get iconImageSize() {
    return [40, 40]
  }

  get deliveryPointInfo() {
    const deliveryStore = getDeliveryStore()
    let cost;
    let deliveryMethodName;
    if (deliveryStore.activeDeliveryMethod == deliveryStore.POST_FREE) { // если бесплатная доставка
      cost = "0 руб.";
      deliveryMethodName = deliveryStore.getStrMethod(deliveryStore.POST_FREE)
    }
    else if (deliveryStore.activeDeliveryMethod == deliveryStore.POST || deliveryStore.activeDeliveryMethod === null) {
      cost = deliveryStore.deliveryPointPostCost?.pickup?.cost;
      deliveryMethodName = deliveryStore.getStrMethod(deliveryStore.POST)
    }
    cost = cost?.toString()?.slice(0, -1)
    const deliveryMethodPeriod = deliveryStore.deliveryPointPostCost?.pickup?.period
    return `
      ${deliveryMethodName} - ${cost}, ${deliveryMethodPeriod}
    `
  }

  get active() {
    const deliveryStore = getDeliveryStore()
    return deliveryStore.activeDeliveryPoint == this
  }

  get value() {
    return this._code
  }

  get text() {
    return "Почта России: " + this.code + ", " + this.address
  }

  get code() {
    return this.postalCode
  }
}
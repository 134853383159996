import { computed, observable, action } from 'mobx'


/**
 * page and perPage should be set before setting items.
 */
class PaginatorStore{
	@observable items = null
    @observable page = null
    @observable perPage = null
    
    // Show paginator with small delay
    // Overwise it is showed on page earlier than content
    @observable isReady = false

    // For showMore functionality
    @observable start = null
    @observable end = null
    @observable isShowMoreOn = false
    @observable manualPaginatedItems = []

    @action
    clear(){
        this.items = null
        this.page = null
        this.perPage = null
        this.isReady = false
        this.start = null
        this.end = null
        this.isShowMoreOn = false
        this.manualPaginatedItems = []
    }

    @computed
    get isShowPaginator(){
        if (this.numPages < 2){
            return false
        }
        return true
    }

    /**
     * Show pages, only when showMore is not activated
     */ 
    @computed
    get isShowPages(){
        return !this.isShowMoreOn
    }

    @computed
    get numPages(){
        if (!this.items){
            return 0
        }
        return Math.ceil(this.items.length / this.perPage)
    }

    /**
     * Do not show button, when there are no more items
     * to display
     */
    @computed
    get isShowMoreButton(){
        if (this.end === this.items.length - 1){
            return false
        }
        return true
    }

    @computed
    get isShowPrev(){
        if (this.page === 1){
            return false
        }
        return true
    }

    @computed
    get isShowNext(){
        if (!this.items){
            return false
        }
        if (this.page === this.numPages){
            return false
        }
        return true
    }

    @computed
    get paginatedItems(){
        if (!this.items){
            return []    
        }
        const start = (this.page - 1) * this.perPage
        const end = start + this.perPage

        if (!this.isReady){
            setTimeout(() => {this.isReady = true}, 500)    
        }
        return [...this.items.slice(start, end)]
    }

    /**
     * If less than 11 items, show pages without splitter "..."
     */
    @computed
    get links(){
        if (!this.items){
            return []
        }

        let arr = []
        
        // Show all
        if (this.numPages < 11){
            for (let x=1; x <= this.numPages; x++){
                const obj = {
                    value: x,
                    isTrio: false,
                    isActive: x === this.page
                }
                arr.push(obj)
            }
            return arr
        }

        // 1 Splitter
        if (this.page <= 5 || this.page >= this.numPages - 4){
            for (let x = 1; x <= 6; x++){
                arr.push({value: x, isTrio: false, isActive: x === this.page})                
            }
            
            arr.push({value: '...', isTrio: true})

            for (let x = this.numPages - 5; x <= this.numPages; x++){
                arr.push({value: x, isTrio: false, isActive: x === this.page})                
            }
            return arr
        }

        // 2 Splitters
        arr.push({value: 1, isTrio: false, isActive: 1 === this.page})
        arr.push({value: 2, isTrio: false, isActive: 2 === this.page})
        arr.push({value: '...', isTrio: true})

        arr.push({value: this.page - 2, isTrio: false, isActive: false})
        arr.push({value: this.page - 1, isTrio: false, isActive: false})
        arr.push({value: this.page, isTrio: false, isActive: true})
        arr.push({value: this.page + 1, isTrio: false, isActive: false})
        arr.push({value: this.page + 2, isTrio: false, isActive: false})
        
        arr.push({value: '...', isTrio: true})
        arr.push({value: this.numPages - 1, isTrio: false, isActive: this.numPages - 1 === this.page})
        arr.push({value: this.numPages, isTrio: false, isActive: this.numPages === this.page})
        return arr
    }

    /**
     * Init paginator
     * Clear pervious paginator state.
     * Set page and perPage before settings items.
     */
    @action
    initPaginator(items, page, perPage){
        this.clear()
        this.page = page
        this.perPage = perPage
        this.items = items
    }

    @action
    setPage(value){
        window.scrollTo(0, 0)
        this.page = value
    }

    @action
    setPerPage(value){
        this.perPage = value
    }

    @action
    setNextPage(){
        if (this.page < this.numPages){
            this.page += 1
            window.scrollTo(0, 0)
        }
    }

    @action
    setPrevPage(){
        if (this.page > 1){
            this.page -= 1
            window.scrollTo(0, 0)
        }
    }

    /**
     * Show more link/button
     */
    @action
    showMore(){
        if (this.start === null){
            this.start = (this.page - 1) * this.perPage
            this.end = this.start + this.perPage * 2
        } else {
            this.end += this.perPage
        }

        if (this.end > this.items.length - 1){
            this.end = this.items.length - 1
        }
        
        this.manualPaginatedItems = [...this.items.slice(this.start, this.end)]
        this.isShowMoreOn = true
    }
}

const paginatorStore = new PaginatorStore();
export default paginatorStore
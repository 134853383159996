import React from "react";
import { Link } from "react-router-dom";

import { rHTML } from "services/helpers";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";

@inject("routing")
@observer
class RightMenuItem extends React.Component {
  renderSubItems(item) {
    let style = { display: "none" };
    if (item.active === true) {
      style = { display: "block" };
    }

    let subitems = item.subitems.map((obj) => {
      const key_tm = "ti" + obj.id.toString();
      let li_class = "";
      let image_glasses = "";

      if (obj.catclass.includes("night")) image_glasses = "glass-yellow";
      else if (obj.catclass.includes("sun")) image_glasses = "glass-brown";
      else if (obj.catclass.includes("brown")) image_glasses = "glass-brown2";
      else if (obj.catclass.includes("gl_grey")) image_glasses = "glass-grey";
      else if (obj.catclass.includes("gl_green")) image_glasses = "glass-green";
      else if (obj.catclass.includes("grad")) image_glasses = "glass-grad";

      if (obj.active === true) {
        li_class = "active";
        item.active = true
      }

      return (
        <li key={key_tm} className={li_class}>
          <Link to={obj.href}>
            <span>{obj.name}</span>
            <img src={"/images/svg/" + image_glasses + ".svg"} alt="" />
          </Link>
        </li>
      );
    });
    if (subitems.length > 0) {
      const key_ul = "ul_" + item.id.toString();
      subitems = (
        <ul key={key_ul} style={style}>
          {subitems}
        </ul>
      );
    }
    return subitems;
  }

  render() {
    const { item, index, classBorder } = this.props;
    let li_class = "";
    let link_class = "";
    let href = item.href


    if (item.active === true) {
      li_class += "active";
      link_class += "opened";
    }
    if (item.subitems.length > 0){
      href = item.subitems[0].href
      link_class += " parent";
    }

    link_class += ` ${classBorder}`;
    return (
      <li key={index} className={li_class}>
        <Link to={href} className={link_class}>
          {rHTML(item.catname)}
        </Link>
        {this.renderSubItems(item)}
      </li>
    );
  }
}

RightMenuItem.propTypes = {
  routing: MPropTypes.observableObject,
};
export default RightMenuItem;

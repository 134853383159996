import { makeObservable, computed, observable, action } from "mobx";
import { ProductModel } from "models";

import {
  apiCall,
  URl_LOAD_ITEMS_CART,
  URL_ADD_TO_CART,
  URL_DELETE_ITEM_IN_CART,
  URL_EDIT_QUANTITY_PRODUCTS,
  URL_CART_INFO,
  URl_CLEAR_CART,
  URL_CHOICE_EQUIPMENT,
  URL_CREATE_ORDER, URL_CREATE_WHOLE_SALE_ORDER, URL_ORDER_IS_PROCESSED,
} from "api";

import { getDeliveryStore } from "./index";
import {Redirect, useHistory} from "react-router-dom";
import {URL_ORDERING_WHOLESALE_IS_PROCESSED} from "../api/constants";


class BasketStore {
  constructor() {
    makeObservable(this);
  }

  @observable products = null;
  @observable clickDeleteItem = false;
  @observable quantity = 0;
  @observable amount = 0;
  @observable messageAfterCreatedOrder = "";

  get _cookies() {
    const cookies_set = document.cookie.split(";");
    let result = {};
    for (let item of cookies_set) {
      const tmp_str = item.split("=");
      if (tmp_str && tmp_str.length === 2) {
        result[tmp_str[0].trim()] = tmp_str[1].trim();
      }
    }
    return result;
  }

  alignmentListProducts(products) {

    let result = [];
    let arr_kes = [];
    let hash = 0
    for (let product of products) {
      // result.push({ product: product, qnt: product.colorsdic[0].num, colr: product.colorsdic[0] });
      for (let colr of product.colorsdic) {
        hash = product.artikul + colr.id1 + colr.id2 + colr.dioptr + colr.dioptrDp + colr.dioptrOd + colr.dioptrOs + colr.dioptrOdAxis + colr.dioptrOsAxis + colr.dioptrOsCyl + colr.dioptrOdCyl + product.photochromeColor + product.BlueBlock
        console.log(hash)
        if (colr.num > 0 && !arr_kes.includes(hash)) {
          result.push({ product: product, qnt: colr.num, colr: colr });
          arr_kes.push(hash);
        }
      }
    }
    console.log(result)
    return result;
  }

  @action
  setMessageAfterOrder(value: ""){
    this.messageAfterCreatedOrder = value
  }


  @action
  editCountProduct = async (idProduct, colrKey, quantity) => {
    let data = {
      productId: idProduct,
      key: colrKey,
      quantity: quantity,
    };
    let result = await apiCall(URL_EDIT_QUANTITY_PRODUCTS, "POST", data, false);
    if (!result.isError) {
      await this.load();
    }
  };

  @action
  addToCart = async (idProduct, activeColrKey, quantity = 1) => {
    let data = this._cookies
    data[activeColrKey] = quantity;
    data["productid"] = idProduct;
    let result = await apiCall(URL_ADD_TO_CART, "POST", data, false);

    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.products) {
        arr.push(new ProductModel(obj));
      }
      this.products = this.alignmentListProducts(arr);
      await this.load();
    }
  };

  @action
  setStateClickDeleteItem(state: false){
    this.clickDeleteItem = state
  }

  @action
  deleteItemInCart = async (idProduct, colrKey) => {
    let data = {
      productid: idProduct,
      key: colrKey,
    };
    this.clickDeleteItem = true
    let result = await apiCall(URL_DELETE_ITEM_IN_CART, "POST", data, false);
    if (!result.isError) {
      await this.load();
    }
  };

  @action
  choiceEquipmentItemInCart = async (idProduct, colrKey, ktype) => {
    let data = {
      productid: idProduct,
      key: colrKey,
      ktype: ktype,
    };

    let result = await apiCall(URL_CHOICE_EQUIPMENT, "POST", data, false);
    if (!result.isError) {
      await this.load();
    }
  };

  @action
  load = async () => {
    let result = await apiCall(URl_LOAD_ITEMS_CART, "GET", {}, false);

    if (!result.isError) {
      let arr = [];
      for (let obj of result.data.results) {
        arr.push(new ProductModel(obj));
      }
      this.products = this.alignmentListProducts(arr);
      await this.getInfo();
    }
  };

  // for load()
  @action
  getInfo = async () => {
    let result = await apiCall(URL_CART_INFO, "GET", {}, false);

    if (!result.isError) {
      this.quantity = result.data.quantity;
      this.amount = result.data.amount;
    }
  };

  @action
  clear = async () => {
    let result = await apiCall(URl_CLEAR_CART, "GET", {}, false);

    if (!result.isError) {
      console.log('clear basket', result)
    }
  };

  @action
  createOrder = async () => {
    const deliveryStore = getDeliveryStore();
    const deliveryType = deliveryStore.deliveryType()
    const address_delivery =  deliveryType === "carry" ? deliveryStore.activeDeliveryPoint.address : deliveryStore.recipientAddressDelivery

    localStorage.setItem("messageAfterCreatedOrder", "")
    const data = {
      country: "RU",
      deliveryType: deliveryType,
      companyCode: deliveryStore.activeDeliveryMethod,
      paymentMethod: deliveryStore.activePaymentMethod,
      firstName: deliveryStore.recipientFirstName,
      middleName: "",
      lastName: deliveryStore.recipientLastName,
      email: deliveryStore.recipientEmail,
      phone: deliveryStore.recipientPhone,
      organizationName: deliveryStore.recipientOrganizationName,
      comment: deliveryStore.recipientComment
        ? deliveryStore.recipientComment
        : "",
      price: [
        deliveryStore.CDEK_PRIM,
        deliveryStore.CDEK_COURIER,
        deliveryStore.BOXBERRY_COURIER,
        deliveryStore.POST_COURIER,
        deliveryStore.DPD_COURIER,
      ].indexOf(deliveryStore.activeDeliveryMethod) !== -1
          ? +deliveryStore.activeDeliveryPointCost?.costCourier?.toString()?.slice(0, -5)
          : +deliveryStore.activeDeliveryPointCost?.cost?.toString()?.slice(0, -5),

      address: address_delivery,
      lon: deliveryStore.activeLongitude,
      lat: deliveryStore.activeLatitude,
      region: deliveryStore.addressLocation.region,
      area: deliveryStore.addressLocation.area
        ? deliveryStore.addressLocation.area
        : deliveryStore.addressLocation.city,
      city: deliveryStore.addressLocation.city,
      street: deliveryStore.addressLocation.street,
      house: deliveryStore.addressLocation.house,
      postIndex: deliveryStore.addressLocation.postalCode,
      schedule: deliveryStore.activeDeliveryPoint?.workHours,
      date: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10),
      b_time: "8",
      e_time: "18",
      estimatedTime: deliveryStore.activeDeliveryPointCost.intPeriod,
    };

    let result = await apiCall(URL_CREATE_ORDER, "POST", data, false);

    if (!result.isError) {
      deliveryStore.setOrderIsCreated(true)

      this.setMessageAfterOrder(result.data.message)

      if(deliveryStore.valueChoicePaymentMethod === deliveryStore.PAYMENT_UPON_RECEIPT){
        localStorage.setItem("messageAfterCreatedOrder", result.data.message)
        window.location.href = URL_ORDER_IS_PROCESSED
        return
      }

      window.location.href = result.data.url
    }
    deliveryStore.setOrderIsCreated(false)
  };

  @action
  createWholeSaleOrder = async () => {
    const deliveryStore = getDeliveryStore();
    const data = {
      firstName: deliveryStore.recipientFirstName,
      lastName: deliveryStore.recipientLastName,
      email: deliveryStore.recipientEmail,
      phone: deliveryStore.recipientPhone,
      company: deliveryStore.recipientOrganizationName,
      comment: deliveryStore.recipientComment
          ? deliveryStore.recipientComment
          : "",
    };

    let result = await apiCall(URL_CREATE_WHOLE_SALE_ORDER, "POST", data, false);

    if (!result.isError) {
      window.location.href = URL_ORDERING_WHOLESALE_IS_PROCESSED
    }
  };

}
const basketStore = new BasketStore();
export default basketStore;

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MPropTypes, observer } from 'mobx-react'

import {
  Header,
  Footer
} from 'components'


@observer
class BaseLayout extends Component {
  render() {
    const { user } = this.props

    return (
      <React.Fragment>
        <Header />
        <div className="wrapper">
          {this.props.children}
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}


BaseLayout.propTypes = {
  children: PropTypes.node,
}
export default BaseLayout
import React from 'react'

export default class Loading extends React.Component {
  render() {
    return (
      <div className="center-me">
        <img className="loading__img" src="/images/loading.gif" alt="" />
      </div>
    )
  }
}


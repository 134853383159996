import React from 'react'
import { BaseHeadBanners } from "components"
import {Link} from "react-router-dom";
import {
    URL_ABOUT_PINHOLE, URL_CATALOG_PINHOLE
} from "api";

class HeadBannersPinHole extends BaseHeadBanners {

    render() {
        const style_bigbanner = { backgroundImage: 'url(/images/uploads/banner45.jpg)'}
        const url_to = (this.props.buy)? URL_CATALOG_PINHOLE: URL_ABOUT_PINHOLE

        return (
            <React.Fragment>
                <div className="bigbanner color-black" style={style_bigbanner}>
                    <div className="row">
                        <div className="grid-12">
                            <div className="bigbanner_title">Перфорационные очки-тренажеры</div>
                            <p className="small"><i>Очки для профилактики близорукости и дальнозоркости</i></p>
                            <Link to={url_to} className="btn btn-grey">{this.text_button}</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default HeadBannersPinHole
import React from "react";
import { BaseHeadBanners } from "components";

class HeadBannersAccessories extends BaseHeadBanners {
  render() {
    const style_bigbanner = {
      backgroundImage: "url(/images/uploads/banner63.png)",
    };

    return (
      <React.Fragment>
        <div className="bigbanner" style={style_bigbanner}>
          <div className="row">
            <div className="grid-20 md3 sm12">
              <a href="#">
                <img src="/images/uploads/banner17.jpg" alt="" />
              </a>
            </div>
            <div className="grid-80 md9 sm12">
              <div className="bigbanner_title color-black">
                Аксессуары SPG
                <br /> для очков
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeadBannersAccessories;

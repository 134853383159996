import React, { Component } from 'react'
import { PropTypes as MPropTypes, inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

@observer
class NotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
            return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок

    }

    render() {
        return (
            <div className="container">
                <div className="error404">
                    <div className="error404_title">Ошибка <b>404</b></div>
                    <div className="error404_text">
                        <p>Страницы, которую Вы искали, не существует.</p>
                        <p>Проверьте правильность адреса, либо перейдите на <Link to="/">Главную страницу</Link>.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}


export default NotFoundPage
import React from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import TopMenuItem from "./TopMenuItem";

@inject("storeMenu")
@observer
class FooterMenu extends React.Component {
  onClickSupport = (e) => {
    e.preventDefault();
  };

  render() {
    const { storeMenu } = this.props;
    return (
      <div className="footer_nav">
        <div className="container navmenu">
          <ul className="navmenu_nav">
            {storeMenu.menuTop &&
              storeMenu.menuTop.map((obj) => {
                const key_tm = "ti" + obj.id.toString();
                return <TopMenuItem key={key_tm} item={obj} index={obj.id} />;
              })}
          </ul>
          {/*<a className="footer_lang__link" href="https://spglasses.com">*/}
          {/*  ENG*/}
          {/*</a>*/}
        </div>
      </div>
    );
  }
}

FooterMenu.propTypes = {
  storeMenu: MPropTypes.observableObject,
  modal: MPropTypes.observableObject,
  t: MPropTypes.observableObject,
};

export default FooterMenu;

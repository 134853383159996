import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import { headerCatalogPage, rHTML, rpHTML } from "services/helpers";

import { ZAGLUSHKA_SLIDER_IN_COMP_PAGE, URL_CATALOG_PINHOLE } from "api";
import { Breadcrumb } from "components";
import PdfLink from "../../components/site/PdfLink";

@inject("general")
@observer
class PrPerfPage extends Component {
  render() {
    const { general } = this.props;

    let article_text = "";

    let banerHeader = headerCatalogPage(this.props.location.pathname, true);

    if (general) {
      article_text = general.getLettering("180") + general.getLettering("181");
    }

    return (
      <React.Fragment>
        {
          <div className="container">
            {banerHeader}
            <div className="breadcrumbs">
              <ol className="breadcrumbs_nav">
                <Breadcrumb breadcrumbs={general.page_object?.krohi} />
              </ol>
            </div>
            <div className="article last">
              <div className="article_head">
                <h1>{general.page_object?.h1}</h1>
              </div>
              {rHTML(article_text, "textcol-2 sm-textcol-1")}
            </div>

            <div className="landing_icons">
              {rHTML(general.getLettering("182"), "landing_icons_title")}
              <div className="landing_icons_text">
                <div className="row">
                  <div className="grid-4 sm12 text-center sm-hidden">
                    <img
                      src="/images/icons/polar_med.svg"
                      alt=""
                      className="max_img"
                    />
                  </div>
                  <div className="grid-8 sm12">
                    {rpHTML(general.getLettering("183"), "")}
                    {rpHTML(general.getLettering("184"), "")}
                    {rpHTML(general.getLettering("185"), "")}
                    {rpHTML(general.getLettering("186"), "")}
                    {rpHTML(general.getLettering("187"), "")}
                  </div>
                </div>
              </div>
              <div className="hr" />
              <div className="landing_icons_article">
                {rpHTML(general.getLettering("188"), "")}
                <div className="text-center">
                  <Link to={URL_CATALOG_PINHOLE} className="btn btn-grey">
                    ПЕРЕЙТИ В КАТАЛОГ
                  </Link>
                </div>
              </div>
            </div>

            <div className="landing_grey">
              {rHTML(general.getLettering("190"), "landing_grey_title")}
              {rpHTML(general.getLettering("191"), "")}
            </div>

            <div className="landing_slider">
              <img src="/images/uploads/banner53.jpg" alt="" />
            </div>

            <div className="article">
              {rHTML(general.getLettering("193"), "check_text check_text-grey")}
              <PdfLink pdf_file={general?.getPdfFile(10)}/>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}
PrPerfPage.propTypes = {
  general: MPropTypes.observableObject,
};

export default PrPerfPage;

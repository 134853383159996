import React from 'react'
import { Link } from "react-router-dom";
import { rpHTML } from 'services/helpers.js'

const SpgBannerItem = ({ obj }) => {
  return (
    <div className="grid-3 tb6 sm12">
      <div className="spg_banner">
        <Link to={obj.link} className="spg_banner_img" style={obj.styleBackground}>
          <span className="spg_banner_icon">
            <img src={obj.urlSpgBannerIcon} alt="" />
          </span>
        </Link>
        <div className="spg_banner_entry">
          <div className="spg_banner_title">
            <Link to={obj.link}>{obj.title}</Link>
          </div>
          {rpHTML(obj.description)}
        </div>
      </div>
    </div>
  )
}

export default SpgBannerItem;
import CostModel from "./private/CostModel";
import {getGeneralStore} from "stores";

export default class PostCostModel extends CostModel{
    get cost(){
        const totalRate = this.totalRate / 100
        return (this.totalRate)? Math.ceil(totalRate) + ' руб.': ''
    }

    get costCourier(){
        const general = getGeneralStore()
        const totalRate = this.totalRate / 100
        let allowance = general.getCompanySetting("130").value
        return (this.totalRate)? Math.ceil(Number(totalRate) + Number(allowance)) + ' руб.': ''
    }

    get intPeriod(){
        return 0
    }

    get period(){
        return (this.deliveryTime['max-days']) ? this.deliveryTime['max-days'] + this.strDaysInField(this.deliveryTime['max-days']) : ''
    }

}
import { makeObservable, observable, action } from 'mobx'
import { apiCall } from 'api'
import { getAlertStore } from 'stores'
import { logMessage } from 'services/helpers'

import { UserModel } from 'models'

import {
    CHECK_TOKEN_URL,
    PROFILE_CHANGE_EMAIL_URL,
    PROFILE_SEND_SMS_CODE_URL,
    SMS_CHECK_CODE_URL,
    SMS_SEND_URL,
    PROFILE_CHANGE_PHONE_URL
} from 'api'

class UserStore {
    constructor(){
        makeObservable(this)
    }

    @observable errors = null
    @observable token = null

    @action
    clear() {
        this.errors = null
    }

    @action
    clearErrors() {
        this.errors = null
    }

    /**
     * Check token
     */
    @action
    checkToken = async () => {
        logMessage('Checking user token')

        let token = window.localStorage.getItem('token')
        if (token === null) {
            this.token = null
            this.isAuthenticated = false
            logMessage(this)
            return
        }

        const form = { token: token }

        let result = await apiCall(CHECK_TOKEN_URL, 'POST', form, false)
        if (result.isError) {
            this.token = null
            this.isAuthenticated = false
            return
        }

        if (!result.data.is_valid) {
            this.token = null
            this.isAuthenticated = false
            return
        }

        this.token = token
        this.isAuthenticated = true
    }

    @action
    sendSMSCode = async data => {
        const store = getAlertStore()

        let url = PROFILE_SEND_SMS_CODE_URL
        this.isSendSMSCodeInProgress = true
        this.errors = null
        let result = await apiCall(url, 'POST', data, true)
        if (result.isError) {
            this.errors = result.data
            store.showResultErrors(result)
        } else {
            this.changePhoneSMSGuid = result.data['sms_guid']
            store.createAlert('Код отправлен на новый номер телефона')
        }
        this.isSendSMSCodeInProgress = false
    }

    @action
    changeEmail = async (data, onSuccess) => {
        const store = getAlertStore()

        let url = PROFILE_CHANGE_EMAIL_URL
        this.isChangeEmailInProgress = true
        this.errors = null
        let result = await apiCall(url, 'POST', data, true)
        if (result.isError) {
            this.errors = result.data
            store.showResultErrors(result)
        } else {
            this.profile = new UserModel(result.data)
            onSuccess && onSuccess()
            store.createAlert('E-mail успешно изменен')
        }
        this.isChangeEmailInProgress = false
    }

    @action
    changePhone = async (data, onSuccess) => {
        const store = getAlertStore()
        data['sms_guid'] = this.changePhoneSMSGuid

        let url = PROFILE_CHANGE_PHONE_URL
        this.isChangePhoneInProgress = true
        this.errors = null
        let result = await apiCall(url, 'POST', data, true)
        if (result.isError) {
            this.errors = result.data
            store.showResultErrors(result)
        } else {
            this.profile = new UserModel(result.data)
            onSuccess && onSuccess()
            store.createAlert('Телефон успешно изменен')
        }
        this.isChangePhoneInProgress = false
    }

    @action
    updateLastAccess = async () => {
        let url = '/v1/profile/update_last_access'
        await apiCall(url, 'POST', {}, true)
    }

    @action
    setErrors(errors) {
        this.errors = errors
    }
}

const userStore = new UserStore()
export default userStore

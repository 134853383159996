import { toCamelCase } from "services/helpers";
import moment from "moment";

export default class Model {
  constructor(obj) {
    for (let key in obj) {
      this.set(toCamelCase(key), obj[key]);
    }
  }
  set(key, value) {
    if (value === "0001-01-01T00:00:00Z") {
      value = null;
    }

    if (this.isDate(value)) {
      value = moment.utc(value, "YYYY-MM-DDTHH:mm:ss");
    }
    this[key] = value;
  }

  isDate(value) {
    var ptn = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    return ptn.test(value);
  }

  /**
   * Returns name of Russian month, by number
   * field - is own property of Model
   */
  rusMonth(field) {
    const m = {
      1: "января",
      2: "февраля",
      3: "марта",
      4: "апреля",
      5: "мая",
      6: "июня",
      7: "июля",
      8: "августа",
      9: "сентября",
      10: "октября",
      11: "ноября",
      12: "декабря",
    };

    const dt = this[field];
    const key = dt.month() + 1;
    return m[key];
  }

  /**
   * Returns date in format "1 января"
   */
  rusDayMonthStr(field) {
    const dt = this[field];
    if (!dt) {
      return "";
    }

    const day = parseInt(dt.format("DD"), 10);
    const month = this.rusMonth(field);
    return `${day} ${month}`;
  }

  /**
   * Returns date in format "01 января 2000"
   */
  rusDateStr(field) {
    const dt = this[field];
    if (!dt) {
      return "";
    }

    const day = dt.format("DD");
    const month = this.rusMonth(field);
    const year = dt.format("YYYY");
    return `${day} ${month} ${year}`;
  }

  /**
   * Returns datetime in format "01 января 2000 HH:MM:SS"
   */
  rusDateTimeStr(field) {
    const dt = this[field];
    if (!dt) {
      return "";
    }

    const day = dt.format("DD");
    const month = this.rusMonth(field);
    const year = dt.format("YYYY");
    const time = dt.format("HH:mm:ss");

    return `${day} ${month} ${year} ${time}`;
  }
}

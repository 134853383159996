import Model from './private/Model'
import { getDeliveryStore } from "../stores";

export default class CDEKModel extends Model {

  get namePoint() {
    return this.name
  }

  get latitude() {
    return this.location.latitude
  }

  get longitude() {
    return this.location.longitude
  }

  get _code() {
    return this.code
  }

  get address() {
    return this.location.addressFull
  }

  get workHours() {
    return this.workTime
  }

  get deliveryPeriod() {
    //this.DeliveryPeriod
  }

  get company() {
    return "CDEK"
  }

  get comment() {
    return this.addressComment ? this.addressComment : this.note
  }

  get workHours() {
    return this.workTime
  }

  get iconImageHref() {
    return '/images/yandex-cdek.svg'
  }

  get iconImageSize() {
    return [40, 40]
  }

  get deliveryPointInfo() {
    const deliveryStore = getDeliveryStore()
    let cost;
    let deliveryMethodName;
    if (deliveryStore.activeDeliveryMethod == deliveryStore.CDEK || deliveryStore.activeDeliveryMethod === null) { // если обычная оплата
      cost = deliveryStore.deliveryPointCDEKCost?.pickup?.cost;
      deliveryMethodName = deliveryStore.getStrMethod(deliveryStore.CDEK)
    }
    else if (deliveryStore.activeDeliveryMethod == deliveryStore.CDEK_PRIM) { // если с примеркой
      cost = deliveryStore.deliveryPointCDEKCost?.pickup?.costCourier;
      deliveryMethodName = deliveryStore.getStrMethod(deliveryStore.CDEK_PRIM)
    }
    cost = cost?.toString()?.slice(0, -1)
    const deliveryMethodPeriod = deliveryStore.deliveryPointCDEKCost?.pickup?.period
    return `
      ${deliveryMethodName} - ${cost}, ${deliveryMethodPeriod}
    `
  }

  get active() {
    const deliveryStore = getDeliveryStore()
    return deliveryStore.activeDeliveryPoint == this
  }

  get value() {
    return this._code
  }

  get text() {
    return "CDEK : " + this.location.postalCode + ", " + this.address
  }

}
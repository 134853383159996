import React, { Component } from "react";
import { PropTypes as MPropTypes, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { ZAGLUSHKA_SLIDER_IN_DEALERS_PAGE } from "../../api";
import { Input } from "../../components";
import PhoneInput from "react-phone-input-2";
import {rHTML} from "../../services/helpers";
import {ERR_EMPTY_MESSAGE, ERR_EMPTY_TITLE} from "../../api/constants";
import PdfLink from "../../components/site/PdfLink";

const STAR = "*";
const ERR_EMPTY_IP = "Вы не указали название организации";
const ERR_EMPTY_FIRST_NAME = "Вы не указали имя";
const ERR_EMPTY_EMAIL = "Вы не указали email";
const ERR_EMAIL_NOT_VALID = "Не верно заполнено email";
const ERR_EMPTY_PHONE = "Вы не указали телефон";
const ERR_EMPTY_THEME = "Вы не указали тема";
const ERR_EMPTY_MSG = "Вы не ввели сообщение";

let scrollAnimateToTopContacts = () => {
  window.$([document.documentElement, document.body]).animate(
    {
      scrollTop: window.$("#feedback_form").offset().top - 52,
    },
    1000
  );
  window.scrollTo(0, 0);
};

@inject("general", "routing")
@observer
class DealersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: null,
      firstName: null,
      email: null,
      phone: null,
      title: null,
      message: null,

      isErrIp: null,
      isErrFirstName: null,
      isErrEmail: null,
      emailIsValid: null,
      isErrPhone: null,
      isErrTheme: null,
      isErrMsg: null,

      errorMsgIp: STAR,
      errorMsgFirstName: STAR,
      errorMsgEmail: STAR,
      errorMsgPhone: STAR,
      errorMsgTheme: STAR,
      errorMsgBody: STAR,
    };
  }

  componentDidMount() {
    const { general } = this.props;
    general.loadPageContent(this.props.routing.location.pathname);

    const $ = window.$;
    $("input").styler();
    $(".selectbox").styler();
    $(".tabs_container").tabs({
      show: { effect: "fade", duration: 400 },
    });
  }

  setErrorMsg() {
    const {
        company,
        firstName,
        email,
        phone,
        title,
        message,
    } = this.state;

    const tmp_emailIsValid = Boolean(email !== null && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    const errorMsgFirstName =
        Boolean(firstName === null || firstName?.length === 0) ? ERR_EMPTY_FIRST_NAME : STAR;
    const errorMsgTitle = Boolean(title === null || title?.length === 0) ? ERR_EMPTY_TITLE : STAR;
    const errorMsgMessage = Boolean(message === null || message?.length === 0) ? ERR_EMPTY_MESSAGE : STAR;
    const errorPhone = Boolean(phone === null || phone?.length === 0) ? ERR_EMPTY_PHONE : STAR;
    const errorCompany = Boolean(company === null || company?.length === 0) ? ERR_EMPTY_IP : STAR;
    const errorMsgEmail =
        Boolean(email === null || email?.length === 0)
            ? ERR_EMPTY_EMAIL
            : tmp_emailIsValid
            ? STAR
            : ERR_EMAIL_NOT_VALID;

    this.setState({
      isErrIp: errorCompany !== STAR,
      isErrFirstName: errorMsgFirstName !== STAR,
      isErrEmail: errorMsgEmail !== STAR,
      emailIsValid: tmp_emailIsValid,
      isErrPhone: errorPhone !== STAR,
      isErrTheme: errorMsgTitle !== STAR,
      isErrMsg: errorMsgMessage !== STAR,

      errorMsgIp: errorCompany,
      errorMsgFirstName: errorMsgFirstName,
      errorMsgEmail: errorMsgEmail,
      errorMsgPhone: errorPhone,
      errorMsgTheme: errorMsgTitle,
      errorMsgBody: errorMsgMessage,
    });
  }

  onChangeIp = (value) => {
    this.setState({
      company: value,
      isErrIp: value.length === 0,
      errorMsgIp: value.length === 0 ? ERR_EMPTY_IP : STAR,
    });
  };

  onChangeFirstName = (value) => {
    this.setState({
      firstName: value,
      isErrFirstName: value.length == 0,
      errorMsgFirstName: value.length == 0 ? ERR_EMPTY_FIRST_NAME : STAR,
    });
  };
  onChangeEmail = (value) => {
    const emailIsValid = Boolean(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    const errorMsgEmail =
        Boolean(value === null || value?.length === 0)
            ? ERR_EMPTY_EMAIL
            : emailIsValid
            ? STAR
            : ERR_EMAIL_NOT_VALID;
    this.setState({
      email: value,
      isErrEmail: Boolean(value.length === 0),
      emailIsValid: emailIsValid,
      errorMsgEmail: errorMsgEmail,
    });
  };

  onChangeTheme = (value) => {
    this.setState({
      title: value,
      isErrTheme: value.length === 0,
      errorMsgTheme: value.length === 0 ? ERR_EMPTY_THEME : STAR,
    });
  };

  onChangePhone = (value) => {
    this.setState({
      phone: value,
      isErrPhone: value.length < 10,
      errorMsgPhone: value.length < 10 ? ERR_EMPTY_PHONE : STAR,
    });
  };

  onChangeBodyMsg = (e) => {
    this.setState({
      message: e.target.value,
      isErrMsg: e.target.value.length === 0,
      errorMsgBody: e.target.value.length === 0 ? ERR_EMPTY_MSG : STAR,
    });
  };

  onChangeFile = (value) => {
    this.setState({
      fileFieldName: value
    })
  }

  onClickSubmit = (e) => {
    e.preventDefault();
    const {
      isErrIp,
      isErrFirstName,
      isErrEmail,
      isErrTheme,
      isErrMsg,
      emailIsValid,
      isErrPhone
    } = this.state;

    const errorContacts =
        (isErrIp === null || isErrIp) ||
        (isErrFirstName === null || isErrFirstName) ||
        (isErrEmail === null || isErrEmail) ||
        (isErrPhone === null ||isErrPhone) ||
        (isErrTheme === null ||isErrTheme) ||
        (isErrMsg === null ||isErrMsg) ||
        !emailIsValid;

    this.setErrorMsg();
    if (errorContacts) {
      scrollAnimateToTopContacts();
      return false;
    }

    const files = window.$("input.file").styler()
    this.props.general.sendDealerMsg(
        {
          company: this.state.company,
          first_name: this.state.firstName,
          email: this.state.email,
          phone: this.state.phone,
          title: this.state.title,
          message: this.state.message,
          file: files[0].files.length > 0 ? files[0].files[0]: null,
          fileFieldName: this.state.fileFieldName

        }
    );
    return false;
  };

  render() {
    const { general } = this.props;

    return (
      <div className="container">
        <div className="breadcrumbs">
          <ol className="breadcrumbs_nav">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Дилерам</Link>
            </li>
          </ol>
        </div>

        {/* <h1>{general.page_object?.h1}</h1> */}
        <div className="page_header">
          <h1>Дилерам</h1>
        </div>

        <div className="article">
          {general.page_object && rHTML(general.page_object.content)}

          <div className="article_slider">
            <p>
              Для повышения продаж рекомендуем воспользоваться нашим
              демонстрационным оборудованием:
            </p>
            <div className="swiper-container" id="dealers_slider">
              <OwlCarousel
                className={"swiper-container"}
                navClass={["swiper-button-prev", "swiper-button-next"]}
                dotsClass={"swiper-pagination swiper-pagination-bullets"}
                dotClass={"swiper-pagination-bullet"}
                navText={["", ""]}
                items={4}
                dots={true}
                nav={true}
                autoplay={true}
                loop={true}
              >
                {ZAGLUSHKA_SLIDER_IN_DEALERS_PAGE.map((obj, index) => {
                  let style_background = {
                    backgroundImage: "url(" + obj.picUrl + ")",
                  };
                  return (
                    <div key={index} className="swiper-slide">
                      <div className="dealers_slider_img">
                        <img src={obj.picUrl} alt="" />
                      </div>
                      <span>{obj.title}</span>
                      <ul>
                        {obj.options.map((option, option_index) => {
                          return <li key={option_index}>{option}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
          <PdfLink pdf_file={general?.getPdfFile(12)}/>
          <hr />

          <p>
            Мы будем рады ответить на все вопросы по телефону +7 (495) 663-96-05
            или через форму обратной связи:
          </p>
          <h3>КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>
          <div id="feedback_form" className="feedback_form">
            <form action="#" method="POST">
              <div className="input-field">
                <label>
                  Наименование организации, ИП:{" "}
                  <span className="red">{this.state.errorMsgIp}</span>
                </label>
                <Input
                  type="text"
                  name="company"
                  className={this.state.isErrIp ? "input error" : "input"}
                  onChange={this.onChangeIp}
                />
              </div>
              <div className="input-field">
                <label>
                  Имя:{" "}
                  <span className="red">{this.state.errorMsgFirstName}</span>
                </label>
                <Input
                  type="text"
                  name="firstName"
                  className={this.state.isErrFirstName ? "input error" : "input"}
                  onChange={this.onChangeFirstName}
                />
              </div>
              <div className="input-field">
                <label>
                  E-mail:{" "}
                  <span className="red">{this.state.errorMsgEmail}</span>
                </label>
                <Input
                  type="text"
                  name="email"
                  className={this.state.isErrEmail || (this.state.emailIsValid !== null && !this.state.emailIsValid) ? "input error" : "input"}
                  onChange={this.onChangeEmail}
                />
              </div>
              <div className="input-field">
                <label>
                  Телефон:{" "}
                  <span className="red">{this.state.errorMsgPhone}</span>
                </label>
                <PhoneInput
                  name="phone"
                  className={this.state.isErrPhone ? "input error" : "input"}
                  country={"ru"}
                  onlyCountries={["ru", "by", "ua"]}
                  onChange={this.onChangePhone}
                />
              </div>
              <div className="input-field">
                <label>
                  Тема сообщения:{" "}
                  <span className="red">{this.state.errorMsgTheme}</span>
                </label>
                <Input
                  name="title"
                  type="text"
                  className={this.state.isErrTheme ? "input error" : "input"}
                  onChange={this.onChangeTheme}
                />
              </div>
              <div className="input-field">
                <label>
                  Сообщение{" "}
                  <span className="red">{this.state.errorMsgBody}</span>
                </label>
                <textarea
                    name="message"
                    className={this.state.isErrMsg ? "input error" : "input"}
                  onKeyUp={this.onChangeBodyMsg.bind(this)}
                />
              </div>

              <div className="input-field">
                <Input id="uploadFile" type="file" className="file" multiple name="file" onChange={this.onChangeFile}/>
              </div>
              <input
                type="submit"
                className="btn btn-red upper"
                value="Отправить сообщение"
                onClick={this.onClickSubmit.bind(this)}
              />
            </form>
          </div>
          <p>
            Мы обрабатываем запросы в течение одного рабочего дня, однако в
            некоторых случаях может потребоваться больше времени.
          </p>
          <p>
            Нажимая на кнопку «ОТПРАВИТЬ СООБЩЕНИЕ», вы даете согласие на
            обработку своих персональных данных.
          </p>
          <p className="light">
            <span className="red">*</span> — поля, обязательные для заполнения
          </p>
        </div>
      </div>
    );
  }
}
DealersPage.propTypes = {
  general: MPropTypes.observableObject,
  routing: MPropTypes.observableObject,
};

export default DealersPage;
